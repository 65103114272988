function GepatСOsnDiagnoz(){
    return (
        <p id="MsoBodyTextIndent" className="green">
       Хронический вирусный гепатит С, активная стадия, крупноузловой цирроз печени.
Осложнения основного заболевания:
Полиорганная недостаточность: печеночная (энцефалопатия (стадия IV, кома), отек головного мозга), почечная (гепаторенальный синдром), дыхательная (гепатопульмональный синдром), 
сердечно-сосудистая.
        </p>
    )
}
export default GepatСOsnDiagnoz