function PancreatitVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="green"> При жизни имелся хронический панкреатит (фиброз поджелудочной железы с атрофией ацинарной ткани),
        что подтверждается макро- и микро- скопическими изменениями поджелудочной железы.
        Панкреатит — это воспалительное заболевание поджелудочной железы, характеризующееся поражением её экзокринной ткани, развитием некротических и дистрофических изменений, 
        отёка и воспалительной инфильтрации, что приводит к нарушению её функций и, в тяжёлых случаях, к системным осложнениям.
Различают две основные формы:
1.	Острый панкреатит — внезапно развивающееся воспаление, сопровождающееся активацией ферментов поджелудочной железы внутри органа, что вызывает аутолиз (самопереваривание), 
некроз тканей и развитие местных или системных осложнений (перитонит, полиорганная недостаточность).
2.	Хронический панкреатит — длительно текущее воспалительное заболевание с прогрессирующим фиброзом, атрофией ацинарной ткани и нарушением ферментативной и гормональной функций 
железы. Причинами панкреатита могут быть:
-	Злоупотребление алкоголем.
-	Желчнокаменная болезнь.
-	Инфекционные заболевания.
-	Токсическое воздействие (медикаменты, токсины).
-	Аутоиммунные процессы.
-	Травмы поджелудочной железы.
Основные клинические проявления включают сильную опоясывающую боль в верхней части живота, тошноту, рвоту, повышение уровня ферментов (амилазы, липазы) и, в тяжёлых случаях, 
признаки системного воспалительного ответа.
        Указанное заболевание в причинной связи с наступлением смерти - не состоит.</p>
    )
}
export default PancreatitVyvody