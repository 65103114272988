function InfarctMiocOsnVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="green"> 
        При жизни имелась ишемическая болезнь сердца (ИБС), острый трансмуральный инфаркт миокарда передней стенки левого желудочка и межжелудочковой перегородки, давностью 7–10 
        суток, атеросклеротическое поражение коронарных артерий (стеноз 70–90%), тромбоз передней межжелудочковой ветви левой коронарной артерии, на что указывают макро- и микро- 
        скопические изменения сердца. 
        Смерть наступила вследствие острго трансмурального инфаркта миокарда передней стенки левого желудочка и межжелудочковой перегородки, осложнившегося острой сердечной 
        недостаточностью, что подтверждается макро- и микро- скопическим изменениями как в самом сердце (увеличение размеров, дряблость миокарда, расширение полостей, 
        зернистая и вакуольная дистрофия кардиомиоцитов, очаговые некрозы миокарда, кровоизлияния под эпикардом), так и в других органах с признаками 
        общего венозного полнокровия, жидкого состояния крови в полостях сердца и крупных сосудах, гипоксии, отека тканей и геморрагий: легкие (увеличение массы, обильное 
        серозно-геморрагическое пропитывание ткани, уплотнение паренхимы, застой крови в капиллярах, заполнение альвеол розоватым транссудатом (кардиогенный отек легких), мелкие 
        кровоизлияния под плеврой и в межальвеолярных перегородках), 
        головной мозг (отек мозга, увеличение объема, уплощение извилин и сужение борозд, мелкие точечные кровоизлияния в белом веществе и мозжечке, дистрофические изменения 
        нейронов (гиперхромия ядер, вакуолизация цитоплазмы)), печень (увеличение размеров, «мускатный» рисунок на разрезе (сочетание темных застойных и светлых дистрофически 
        измененных участков), центролобулярный застой крови, зернистая и вакуольная дистрофия гепатоцитов), почки (увеличение размеров, дряблая консистенция, застой крови в 
        корковом и мозговом веществе, острая вакуольная дистрофия эпителия канальцев, отек интерстициальной ткани, мелкие кровоизлияния), селезенка(увеличение размеров, дряблость 
        ткани, полнокровие синусов красной пульпы, микрогеморрагии в пульпе). 
Таким образом, между имевшейся ишемической болезнью сердца (ИБС) (в виде острго трансмурального инфаркта миокарда передней стенки левого желудочка и межжелудочковой перегородки, 
давностью 7–10 суток, атеросклеротического поражения коронарных артерий (стеноз 70–90%), тромбоза передней межжелудочковой ветви левой коронарной артерии) и наступлением смерти 
имеется прямая причинная связь.
        Инфаркт миокарда — это острое состояние, характеризующееся развитием очагового некроза сердечной мышцы, вызванного недостаточным 
        кровоснабжением (ишемией) вследствие окклюзии коронарной артерии тромбом, спазмом, атеросклеротической бляшкой или их комбинацией.
Это состояние сопровождается необратимым повреждением кардиомиоцитов и нарушением их функциональной активности, что клинически проявляется болью в грудной клетке, признаками 
сердечной недостаточности или кардиогенного шока.
</p>
    )
}
export default InfarctMiocOsnVyvody