function saharDiab2OsnVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="green">
            При жизни имелся сахарный диабет 2 типа, на что указывают макро- и микро- скопические 
        изменения внутренних органов (сердца, почек, печени, поджелудочной железы, выраженное поражение крупных сосудов (макроангиопатия)), выраженные трофические язвы 
        нижних конечностей, повышенное содержание липидов и гликированного гемоглобина в крови. 
        Смерть последовала от сахарного диабета 2 типа, осложнившегося диабетической (гиперосмолярной и кетоацидотической) комой с развитием полиорганной недостаточности, что 
        подтверждается выраженной сухостью кожи и слизистых оболочек, снижением её тургора, запавшими глазами (обезвоживание), макро- и микро- скопическим изменениями во 
        внутренних органах:
        головной мозг (отек, набухание, сглаженность извилин, сужение борозд; полнокровие сосудов, мелкие кровоизлияния; размытость серого и белого вещества (симптомы гипоксии); 
        микроскопически: периваскулярный и перицеллюлярный отек, очаговая дегенерация нейронов, плазморрагия в стенках сосудов);
легкие (выраженный отек и полнокровие сосудов малого круга кровообращения, пена в просвете бронхов, наличие очаговыз ателектазов; микроскопически: альвеолярный отек, гиперемия 
сосудов, плазморрагия); cердце (дряблое, полнокровное, признаки миокардиодистрофии, гипертрофия левого желудочка, очаговые участки жировой дистрофии; микроскопически: 
миокардиодистрофия, фиброз, очаги ишемического повреждения); почки (уменьшены, плотные, капсула снимается с трудом; поверхность мелкозернистая, корковый слой истончен, отложение 
фибрина на поверхности; микроскопически: диффузный гломерулосклероз, гиалиноз артериол, тубулярный некроз); печень (увеличена, дряблая, полнокровная; диффузная жировая дистрофия; 
    микроскопически: жировая дистрофия гепатоцитов, полнокровие), а также данными биохимического анализа крови и мочи:   
    Кровь: глюкоза 20,9 ммоль/л, кетоновые тела (при кетоацидотической коме): β-гидроксибутират 3,5 ммоль/л., осмолярность плазмы: 380 мОсм/кг (при гиперосмолярной коме),
pH крови: 7,0 (метаболический ацидоз), бикарбонаты: 12 ммоль/л, лактат 2,9 ммоль/л, гиперкалиемия 6,5 ммоль/л, гипонатриемия.
Моча: глюкозурия, кетонурия (при кетоацидозе), снижение удельного веса.
Таким образом, между имевшимся сахарным диабетом 2 типа и наступлением смерти имеется прямая причинная связь.
        Сахарный диабет 2 типа — это хроническое метаболическое заболевание, характеризующееся сочетанием инсулинорезистентности (сниженной чувствительности тканей к инсулину) и 
        относительного дефицита инсулина, что приводит к нарушению углеводного обмена и стойкой гипергликемии. Чаще развивается у взрослых, особенно при наличии факторов риска, 
        таких как ожирение, малоподвижный образ жизни, генетическая предрасположенность и метаболический синдром. Заболевание может долгое время протекать бессимптомно, но при 
        прогрессировании приводит к осложнениям, включая поражение сосудов (микро- и макроангиопатии), нервной системы (нейропатия), почек (нефропатия), глаз (ретинопатия) и 
        других органов. Диагноз подтверждается на основании повышенного уровня глюкозы в крови, гликированного гемоглобина и других тестов. Лечение включает изменение образа 
        жизни, контроль массы тела, применение гипогликемических препаратов и, в некоторых случаях, инсулинотерапию.
        </p>
    )
}
export default saharDiab2OsnVyvody