function saharDiab1OsnVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="green">
             При жизни имелся сахарный диабет 1 типа, на что указывают макро- и микро- скопические изменения внутренних органов (сердца, почек, поджелудочной железы, выраженное 
             поражение мелких сосудов (микроангиопатия)), повышенный уровень гликированного гемоглобина, наличие антител к бета-клеткам поджелудочной железы. 
        Смерть последовала от сахарного диабета 1 типа, осложнившегося диабетической нефропатией и последующей хронической почечной недостаточностью, что подтверждается макро- и микро- 
        скопическим изменениями как в самих почках (уменьшение массы и размеров; мелкозернистая, бугристая поверхность капсулы, с участками фиброзных рубцов, капсула почек 
        утолщена, снимается с трудом, в некоторых местах срощена с корковым веществом, в некоторых местах - на ней имеется налет фибрина; корковый слой истончен, с очагами 
        склероза, атрофии и фиброза, мозговой слой плохо 
            дифференцирован, пирамиды уплотнены, четкая демаркация между корковым и мозговым веществом отсутствует; деформация и утолщение стенок лоханок и чашечек; 
            при микроскопии — утолщение базальной мембраны клубочков, диффузный и узелковый гломерулосклероз, гиалиноз артериол, атрофия капилляров и исчезновение клубочков с 
            замещением соединительной тканью, тубулоинтерстициальный фиброз; дистрофия эпителия, десквамация клеток, облитерация просвета, образование цилиндров в просвете, 
            разрывы базальной мембраны в проксимальных канальцах; некроз эпителия и развитие тубулоинтерстициального фиброза в дистальных канальцах; полнокровие и капиллярный стаз, 
            капиллярные микротромбозы в клубочках; отек, инфильтрация лейкоцитами и очаги кровоизлияний в интерстиции; фибриноидный некроз стенок сосудов, выраженное венозное 
            полнокровие, стаз эритроцитов в капиллярах), так и в других органах:
        сердце (кардиомегалия, дилатация полостей сердца, гипертрофия левого желудочка, вакуольная и жировая дистрофия миокарда, интерстициальный фиброз, венозное полнокровие, 
        очаги микроинфарктов); легких (отек, венозное полнокровие, участки ателектазов, микротромбозы, гиалиновые мембраны в альвеолах); головном мозге (отек, мелкие кровоизлияния, 
        гипоксически-дистрофические изменения нейронов); печени (жировая дистрофия, венозное полнокровие, центролобулярный фиброз); а также данными биохимического анализа крови: 
        повышение уровня креатинина (1560 мкмоль/л при норме 44–106 мкмоль/л), повышение уровня мочевины (70 ммоль/л при норме 2,5–8,3 ммоль/л), гиперкалиемия (повышение калия в 
        крови) (9,0 ммоль/л при норме 3,5–5,0 ммоль/л), метаболический ацидоз (снижение pH крови) (7,0 при норме 7,35–7,45), повышение остаточного азота и мочевой кислоты 
        (остаточный азот - 140 ммоль/л при норме 14,3–28,6 ммоль/л, мочевая кислота - 1000 мкмоль/л при норме 150–420 мкмоль/л).
Таким образом, между имевшимся сахарным диабетом 1 типа и наступлением смерти имеется прямая причинная связь.
Сахарный диабет 1 типа — это хроническое аутоиммунное заболевание, характеризующееся разрушением бета-клеток поджелудочной железы, вырабатывающих инсулин, что приводит к 
        абсолютному дефициту инсулина, нарушению углеводного обмена и стойкой гипергликемии. Заболевание возникает чаще в молодом возрасте, имеет быстрый дебют и сопровождается 
        такими симптомами, как жажда (полиурия), учащённое мочеиспускание (полидипсия), потеря массы тела, слабость и склонность к кетоацидозу. Диагноз устанавливается на 
        основании уровня глюкозы в крови, наличия аутоантител (к антигенам бета-клеток) и показателей гликированного гемоглобина. Лечение включает пожизненную инсулинотерапию, 
        контроль уровня глюкозы и соблюдение диетических рекомендаций.
        </p>
    )
}
export default saharDiab1OsnVyvody