function PnevmoniaOchagVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="green">
            При жизни имелась очаговая пневмония (двусторонняя, нижнедолевая, серозно-гнойная, стадия уплотнения), на что указывают макро- и микро- скопические изменения в легких.
            Очаговая пневмония — это воспалительное заболевание легочной ткани, характеризующееся развитием ограниченных участков воспаления (очагов) в альвеолах и прилегающих 
            бронхиолах. Возникает вследствие инфицирования бактериями, вирусами, грибами или в результате аспирации, сопровождается инфильтрацией тканей нейтрофилами, отеком и 
            экссудацией. Клинически проявляется кашлем, лихорадкой, одышкой и локальными симптомами воспаления в легких (хрипы, ослабленное дыхание). На рентгенограмме 
            определяется ограниченное затемнение легочной ткани. Указанное заболевание в причинной связи с наступлением смерти - не состоит.
        </p>
    )
}
export default PnevmoniaOchagVyvody