function DifteriaVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="green"> При жизни имелась дифтерия зева, токсическая форма, I степени тяжести (фибринозно-некротический фарингит, реактивная 
        гиперплазия шейных лимфатических узлов), что подтверждается макро- и микро- скопическими изменениями в этих органах (глотка и шейные лимфатические узлы), выделением 
        Corynebacterium diphtheriae (палочки Лефлера) при бактериологическом исследовании, установлением наличия дифтерийного токсина в крови методом иммуноферментного анализа.
        Дифтерия — это острое инфекционное заболевание, вызываемое токсигенными штаммами Corynebacterium diphtheriae (палочка Лефлера), характеризующееся развитием фибринозного 
        воспаления на слизистых оболочках верхних дыхательных путей, реже кожи и других органов, а также системным токсическим поражением сердечно-сосудистой, нервной и 
        выделительной систем в результате действия дифтерийного экзотоксина. Заболевание передается преимущественно воздушно-капельным путем, иногда через предметы обихода или 
        контакт с инфицированными поверхностями. Инкубационный период составляет 2–7 дней. Основными проявлениями являются фибринозные пленки на пораженных участках, выраженная 
        интоксикация, возможное развитие асфиксии, миокардита, нефропатии и полинейропатии.
В зависимости от клинического течения и распространенности процесса (морфологических изменений органов и тканей), дифтерия подразделяется на следующие степени тяжести:
1. Лёгкая степень (локализованный токсический процесс);
2. Средняя степень (токсическое воздействие на организм начинает проявляться в виде изменений в других органах);
3. Тяжёлая степень (токсический шок и генерализованное поражение);
4. Клинически неконтролируемая степень (летальный исход, генерализованный токсикоз).     
        Указанное заболевание в прямой причинной связи с наступлением смерти - не состоит.
        </p>
    )
}
export default DifteriaVyvody