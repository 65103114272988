function VichOsnVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="green">
           При жизни имелась ВИЧ-инфекция IV клинической стадии (СПИД), на что указывают наличие вторичных заболеваний: генерализованный кандидоз (поражение (белесоватые налеты и 
           язвы) пищевода, желудка, кишечника, легких, выделения Candida spp. в посевах биоптата из этих органов), пневмоцистная пневмония (очаговые уплотнения, пневмоцистные 
           мембранозные структуры в альвеолах, обнаружение ДНК Pneumocystis jirovecii методом ПЦР), туберкулез легких и внутригрудных лимфатических узлов (казеозные гранулемы с 
           выраженным некрозом, обнаружение микобактерий туберкулеза при бактериоскопическом исследовании), цитомегаловирусный энцефалит (диффузный отек головного мозга, 
           некротические очаги в подкорковых структурах и сосудистые воспалительные инфильтраты, обнаружение ДНК CMV в мозговой ткани методом ПЦР); наличие антител к вирусу 
           иммунодефицита человека (ВИЧ) в крови (положительный иммунный блот).
        Смерть последовала от ВИЧ-инфекции IV клинической стадии (СПИД) с развитием вторичных заболеваний (генерализованного кандидоза, пневмоцистной пневмонии, туберкулеза легких 
        и внутригрудных лимфатических узлов, цитомегаловирусного энцефалита), прогрессирование которых привело к полиорганной недостаточности (острая дыхательная недостаточность, 
        отек головного мозга, поражение печени и почек), что подтверждается макро и микро -скопическим изменениями в этих органах.
Таким образом, между имевшейся ВИЧ-инфекции IV клинической стадии (СПИД) и наступлением смерти имеется прямая причинная связь.
        Вирус иммунодефицита человека (ВИЧ) — это хроническое вирусное заболевание, вызываемое вирусом иммунодефицита человека (HIV-1 или HIV-2), характеризующееся 
        прогрессирующим поражением иммунной системы за счёт разрушения CD4+ T-лимфоцитов, что приводит к угнетению клеточного иммунитета, развитию вторичных инфекций, опухолей и 
        других осложнений. Основными путями передачи ВИЧ являются половой, парентеральный (через кровь) и вертикальный (от матери к ребёнку). Без лечения заболевание прогрессирует 
        от бессимптомного носительства до стадии синдрома приобретённого иммунодефицита (СПИД), который является конечной стадией инфекции. Современная антиретровирусная терапия 
        (АРТ) позволяет существенно замедлить прогрессирование ВИЧ, улучшить качество и продолжительность жизни пациентов.
        </p>
    )
}
export default VichOsnVyvody