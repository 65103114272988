function HoblDiagnoz(){
    return (
        <p id="MsoBodyTextIndent" className="green">
        Хроническая обструктивная болезнь легких (ХОБЛ): хронический обструктивный бронхит (хронический воспалительный инфильтрат в стенках бронхов (лимфоциты, макрофаги, 
        плазматические клетки), гиперплазия бокаловидных клеток и слизистых желез, утолщение базальной мембраны, метаплазия и десквамация эпителия в бронхах и бронхиолах, 
        угольно-пылевые отложения в макрофагах); эмфизема легких (увеличение объема легких, повышенная воздушность легочной ткани, наличие булл, деструкция межальвеолярных 
        перегородок); легочная гипертензия (гипертрофия правого желудочка (толщина стенки более 5 мм), признаки хронического венозного застоя ("мускатная печень"), утолщение 
        медии и интимы, гипертрофия гладкомышечного слоя легочных артерий).
        </p>
    )
}
export default HoblDiagnoz