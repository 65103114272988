function PeritonitBrushina(){
    return (
        <p id="MsoBodyTextIndent" className="green">
        При остром перитоните в брюшной полости наличие мутного или гнойного экссудата (в зависимости от стадии процесса - обычно сначала эксудат серозный, потом становится 
        фибринозно-гнойным). Отложение фибринозного налета на серозной оболочке органов и стенках брюшной полости. Фибриновые массы могут отграничивать пораженную область, 
        разрушение спаек ведет к распространенному (разлитому) перитониту. Гиперемия и отечность брюшины, ее тусклый, иногда желтоватый оттенок. Вздутие петель кишечника из-за 
        пареза. Гиперемия и отечность стенки кишечника, иногда участки ишемии или некроза. Уплощение складок слизистой оболочки кишечника из-за отека. Тусклый и матовый вид 
        серозных оболочек органов живота (в норме они должны быть гладкими и блестящими), возможно наличие гнойных отложений или кровоизлияний в них. Мезентериальные лимфатические 
        узлы увеличены, гиперемированы, часто с участками гнойного воспаления. Также возможно обнаружить источник перитонита (перфорация полого органа (например, желудка или 
        кишечника); ретрофлексия (перекрут) кишечника, аппендикса или желчного пузыря; гангренозные изменения (например, аппендикс, желчный пузырь)).
</p>
    )
}
export default PeritonitBrushina