function PnevmoniaKrupLab(){
    return (
        <p id="MsoBodyTextIndent" className="green">
            Для крупозной пневмонии характерно: при <u>гистологическом исследовании:</u> изменения в легочной ткани проходят через несколько стадий, каждая из которых 
            характеризуется специфической микроскопической картиной:
1. Стадия прилива (начальная стадия, 1–2 день):
-	Альвеолы: сохранены, но полнокровные капилляры в стенках альвеол свидетельствуют о гиперемии; в просвете альвеол выявляется серозный экссудат с единичными нейтрофилами и 
эритроцитами; возможно наличие десквамированных альвеолоцитов.
-	Межальвеолярные перегородки: утолщены за счет отека и полнокровия капилляров; преобладают признаки сосудистой гиперемии.
-	Сосуды: полнокровные, местами с явлениями стаза.
-	Интерстициальная ткань: незначительный отек, возможен периваскулярный инфильтрат из лимфоцитов.
Клиническое значение: начальная стадия воспаления, доминирует сосудистая реакция.
2. Стадия красного опеченения (2–4 день):
-	Альвеолы: практически полностью заполнены фибринозно-гнойным экссудатом; в составе экссудата: фибрин, нейтрофилы, эритроциты, слущенные эпителиальные клетки; частичная 
обтурация альвеолярных просветов.
-	Межальвеолярные перегородки: выраженная полнокровность; признаки активного воспаления: инфильтрация нейтрофилами и макрофагами.
-	Сосуды: плотно переполнены кровью (гиперемия); возможны признаки микроэмболии.
-	Интерстициальная ткань: выраженный отек и воспалительный инфильтрат, состоящий из нейтрофилов, лимфоцитов и макрофагов.
Клиническое значение: активная фаза воспаления, начало уплотнения легочной ткани.
3. Стадия серого опеченения (4–6 день):
-	Альвеолы: полностью заполнены фибринозно-гнойным экссудатом; эритроциты практически отсутствуют, доминируют нейтрофилы и фибрин; структура альвеол сохраняется, но просветы 
полностью обтурированы экссудатом.
-	Межальвеолярные перегородки: утолщены, инфильтрированы лимфоцитами и макрофагами; сосуды частично коллабированы.
-	Интерстициальная ткань: умеренное воспаление, сохраняется отек; преобладают фиброзные изменения в периваскулярной области.
Клиническое значение: максимальная стадия уплотнения легочной ткани, снижение газообмена.
4. Стадия разрешения (7–10 день и более):
-	Альвеолы: просветы частично очищены от экссудата за счет фагоцитоза макрофагами; остаточный экссудат содержит деградировавшие нейтрофилы, макрофаги и фибрин; возможно 
появление участков воздушности.
-	Межальвеолярные перегородки: частичное восстановление толщины и структуры; признаки регенерации: активные альвеолоциты II типа (регенерация эпителия).
-	Интерстициальная ткань: уменьшение отека, формирование фиброзных изменений в некоторых участках; остаточные воспалительные инфильтраты.
Клиническое значение: завершающий этап, возможны последствия в виде остаточных фиброзных изменений. 
Каждая стадия очаговой пневмонии характеризуется специфической морфологической картиной, что позволяет определить этап воспалительного процесса.
Дополнительные изменения в легких при осложнениях (абсцессы, гангрена): некроз легочной ткани; очаги нагноения с большим количеством нейтрофилов и бактерий; васкулит или тромбоз 
сосудов. <u>Бактериологическое исследование:</u> посев содержимого из пораженной легочной ткани или плевральной полости на питательные среды - определение возбудителя (чаще всего 
Streptococcus pneumoniae). <u>Биохимическое исследование:</u> изучение биохимического состава экссудата (содержание белка, фибрина, воспалительных клеток, признаки воспалительного 
процесса (активность ферментов, цитокинов)). <u>Иммунологическое исследование:</u> определение наличия антител к предполагаемому возбудителю.
        </p>
    )
}
export default PnevmoniaKrupLab