function DifteriaLungs(){
    return (
        <p id="MsoBodyTextIndent" className="green"> При дифтерии характерно наличие плотных серовато-белых фибринозных налетов на слизистой оболочке миндалин, зева, глотки и 
        трахеи. Эти налеты плотно прикреплены к подлежащим тканям и при попытке удаления оставляют кровоточащую поверхность; отек и гиперемия слизистой оболочки горла и верхних 
        дыхательных путей, резкий приторно-сладкий запах от них. Значительное увеличение и уплотнение регионарных лимфатических узлов (подчелюстных, шейных); при разрезе лимфатические 
        узлы могут быть сочными, с 
        серозным или серозно-гнойным содержимым. Распространение фибринозного налета вниз по дыхательным путям, что может привести к обструкции просвета гортани, трахеи, бронхов 
        и асфиксии. Возможно наличие слизисто-гнойного или фибринозного экссудата в просвете бронхов.
        Возможные макроскопические изменения в лёгких обусловлены как самим инфекционным процессом (дифтерийным поражением верхних дыхательных путей), так и последствиями 
        асфиксии (недостаточности кислорода):
1. Отёк лёгких:
лёгкие будут отёчными, увеличенными в объёме, с отёчными междольковыми тканями. Могут быть видны участки, где капилляры заполнены жидкостью, создавая общее ощущение «мокрых» 
лёгких. Поверхность лёгких будет блестящей, на разрезе выделяется избыточное количество пенистого секрета, что свидетельствует о дыхательной недостаточности и нарушении газообмена.
2. Гиперемия и застой в лёгких:
лёгочные ткани будут гиперемированы, то есть насыщены кровью, что обусловлено венозным застоем, возникающим вследствие затруднённого дыхания (асфиксия). Это может приводить к 
изменению цвета лёгочной ткани в сторону более тёмного (синеватого) оттенка. При разрезе лёгкие могут быть темного цвета (вплоть до синих или пурпурных оттенков), особенно в 
нижних отделах, где более выражен застой крови.
3. Обструкция дыхательных путей: из-за фибринозно-некротических изменений в глотке, трахее и бронхах, вероятно, наличие фибринозных и некротических пленок, которые могут привести 
к обструкции дыхательных путей. Лёгкие могут быть наполнены пенистым экссудатом, что указывает на нарушение функции дыхательных путей, вызванное повреждением слизистых оболочек и 
нарушением проходимости бронхов.
4. Вторичные изменения (из-за токсического миокардита): токсическое воздействие дифтерийного токсина может вызывать повреждения сердечной мышцы, что повлияет на функцию сердца и, 
в свою очередь, на кровообращение в лёгких. Это может приводить к застою крови в лёгких, усугубляя отёк и ухудшая насыщение крови кислородом.
5. Признаки вторичных воспалений (пневмония): в случае осложнений в виде вторичной бактериальной инфекции или пневмонии, могут быть выявлены участки воспаления в лёгких 
(синеватый оттенок тканей, маленькие гнойные очаги). В таких случаях в лёгких можно наблюдать очаги гнойного воспаления, с участками консолидации тканей и возможными абсцессами 
или эмфиземой.
6. Общее состояние лёгких: лёгкие будут тяжёлыми и влажными, с уплотнённой тканью в некоторых местах. В тяжёлых случаях ткани лёгких могут быть слабо растяжимыми из-за отёка и 
застойных явлений, что затрудняет их полноценную вентиляцию и газообмен. Также, особенно в случаях тяжёлой токсической формы заболевания с токсическим шоком, асфиксией и 
нарушением коагуляции или сосудистыми расстройствами, возможно обнаружение пятен Тардье (мелкие, до 2 мм в диаметре, кровоизлияния тёмно-красного цвета с чёткими краями под 
лёгочной плеврой, чаще на диафрагмальной и междолевых поверхностях).
Таким образом, макроскопические изменения в лёгких при дифтерии, осложнившейся асфиксией, будут включать отёк, гиперемию, застой крови в них 
(легкие увеличены в объёме, плотные, багрово-синюшного цвета, возможны субплевральные точечные кровоизлияния (пятна Тардье), на разрезе ткань лёгких обильно выделяет пенистую 
жидкость с примесью крови), а также признаки обструкции дыхательных путей и возможные изменения, связанные с токсическим воздействием на другие органы.
        </p>
    )
}
export default DifteriaLungs