function saharDiab1Vyvody(){
    return (
        <p id="MsoBodyTextIndent" className="green">При жизни имелся сахарный диабет 1 типа, на что указывают макро- и микро- скопические 
        изменения внутренних органов (сердца, почек, поджелудочной железы, выраженное поражение мелких сосудов (микроангиопатия)), повышенный уровень гликированного 
        гемоглобина, наличие антител к бета-клеткам поджелудочной железы. 
        Сахарный диабет 1 типа — это хроническое аутоиммунное заболевание, характеризующееся разрушением бета-клеток поджелудочной железы, вырабатывающих инсулин, что приводит к 
        абсолютному дефициту инсулина, нарушению углеводного обмена и стойкой гипергликемии. Заболевание возникает чаще в молодом возрасте, имеет быстрый дебют и сопровождается 
        такими симптомами, как жажда (полиурия), учащённое мочеиспускание (полидипсия), потеря массы тела, слабость и склонность к кетоацидозу. Диагноз устанавливается на 
        основании уровня глюкозы в крови, наличия аутоантител (к антигенам бета-клеток) и показателей гликированного гемоглобина. Лечение включает пожизненную инсулинотерапию, 
        контроль уровня глюкозы и соблюдение диетических рекомендаций.
        Указанное заболевание в причинной связи с наступлением смерти - не состоит.
        </p>
    )
}
export default saharDiab1Vyvody