function DifteriaKozha(){
    return (
        <p id="MsoBodyTextIndent" className="green"> При дифтерии возможны:
1. Общие признаки интоксикации и инфекционного процесса: бледность кожи (выраженная интоксикация может привести к анемии); синюшность губ и конечностей (цианоз) возможна при 
тяжелой дыхательной недостаточности, вызванной обструкцией дыхательных путей; отечность лица и шеи (возникает при тяжелой токсической форме дифтерии с поражением регионарных 
лимфатических узлов).
2. Изменения в области зева и шеи: увеличение шейных лимфатических узлов (прощупываются плотные, увеличенные лимфоузлы); возможное покраснение и припухлость в области шеи 
(свидетельствует о распространении воспалительного процесса на мягкие ткани).
3. Признаки асфиксии (при обструкции дыхательных путей): синюшность лица (особенно в области носогубного треугольника); выраженные набухшие шейные вены; мелкие кровоизлияния на 
коже лица и верхней части туловища (петехии) и в соединительных оболочках глаз, возникающие из-за повышения внутрисосудистого давления при удушье.
4. Изменения в области рта и носоглотки: выделения из носа и рта (cлизисто-гнойные или с примесью крови, возможны кусочки серо-белых фибринозных пленок); запах изо рта 
(специфический сладковатый или гнилостный запах, характерный для некротических процессов).
5. Признаки обезвоживания: сухость слизистых оболочек (из-за высокой температуры и интоксикации); впалость глаз (признак выраженной интоксикации и обезвоживания).
6. Признаки гипоксии и системных осложнений: отечность конечностей (может быть связана с сердечной недостаточностью, вызванной токсическим миокардитом); синюшность или мраморность 
кожи (как следствие нарушения кровообращения); трофические изменения кожи (возможны при длительно текущем заболевании).
Дополнительные наблюдения: возможны остаточные следы от медицинских процедур, таких как интубация или трахеостомия, если они проводились при жизни для облегчения дыхания; общий 
вид трупа может указывать на выраженное истощение (в случае длительного течения заболевания).
        </p>
    )
}
export default DifteriaKozha