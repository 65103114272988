function GepatCPechen(){
    return (
        <p id="MsoBodyTextIndent" className="green">При вирусном гепатите C может наблюдаться увеличение печени (гепатомегалия) на ранних стадиях заболевания. Орган может 
        быть плотным на ощупь из-за фиброза. Цвет печени варьирует от красновато-коричневого до сероватого или желтоватого, что связано с жировой дистрофией и воспалительными 
        изменениями. Мелкозернистая структура печени, особенно при длительном течении, что может свидетельствовать о развитии цирроза. В стадии цирроза печень может 
        уменьшаться в размерах, иметь бугристую поверхность и узлы регенерации, асцит (жидкость в брюшной полости). Возможны признаки воспаления или застоя желчи 
        (холестаза) при нарушении функции печени. Желчный пузырь может быть увеличен из-за нарушения выделения желчи. При развитии портальной гипертензии могут наблюдаться 
        варикозное расширение вен пищевода и желудка, что представляет риск для кровотечений. На слизистой оболочке желудка могут быть видны петехии и мелкие кровоизлияния, 
        связанные с гипертензией и нарушением свертываемости. Возможен синдром Бадда-Киари — нарушение оттока крови (в результате тромбоза) из печени через печеночные вены в 
        нижнюю полую вену. Это приводит к застою крови в печени, усиливает гипертензию в портальной системе и повреждение печеночной ткани.
        </p>
    )
}
export default GepatCPechen