function MeningitDiagnoz(){
    return (
        <p id="MsoBodyTextIndent" className="green">
            Серозно-гнойный менингит.    
            </p>
    )
}
export default MeningitDiagnoz

// function MeningitDiagnoz() {
//     return (
//         <p
//             id="MsoBodyTextIndent"
//             className="green"
//             style={{ whiteSpace: 'pre-line' }}
//         >
//             {'\nСерозно-гнойный менингит.'}
//         </p>
//     );
// }

// export default MeningitDiagnoz;