function AstmaKozha(){
    return (
        <p id="MsoBodyTextIndent" className="green">При бронхиальной астме возможны:
-	Цианоз (синюшность губ, ногтевых лож и кожи лица, особенно выраженная в периоды обострения болезни);
-	Пастозность лица (легкая отечность тканей, связанная с хронической гипоксией и возможной сердечно-легочной недостаточностью).
-	Бочкообразная грудная клетка (увеличение переднезаднего размера грудной клетки из-за хронической обструкции дыхательных путей и эмфиземы).
-	Резко выраженная межреберная мускулатура (признак компенсаторной работы дыхательной мускулатуры при хронических приступах удушья), умеренное развитие вспомогательной 
дыхательной мускулатуры (грудино-ключично-сосцевидных и межреберных мышц).
-	Ногти в виде "часовых стекол" и пальцы в виде "барабанных палочек":
-	Наличие следов от инъекций, особенно в области предплечий или бедер (при применении бронхолитиков или кортикостероидов).
-	Следы использования ингаляторов (возможно раздражение кожи или слизистых вокруг рта).
-	Признаки кожных высыпаний (атопический дерматит), сухость и шелушение кожи, связанные с частыми аллергическими реакциями.
-   Признаки острого приступа удушья (если смерть наступила во время приступа): зияние рта, возможное выдвижение языка, расширение ноздрей.
-	Возможность фиксированного положения тела в момент смерти (например, «поза ортопноэ» - положение сидя с упором руками для облегчения дыхания).
        </p>
    )
}
export default AstmaKozha