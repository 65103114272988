function RsDiagnoz(){
    return (
        <p id="MsoBodyTextIndent" className="green">
            Рассеянный склероз, хроническое прогрессирующее течение (очаги демиелинизации в белом веществе головного мозга (перивентрикулярно, в мозолистом теле, стволе мозга), 
            глиоз в старых очагах демиелинизации, атрофия зрительных нервов).
            </p>
    )
}
export default RsDiagnoz

