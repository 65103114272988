function NjakKozha(){
    return (
        <p id="MsoBodyTextIndent" className="green">
При неспецифическом язвенном колите (НЯК), можно выявить следующие возможные особенности, связанные с хроническим воспалительным процессом в кишечнике, системными осложнениями и 
последствиями заболевания:

1. Общие признаки истощения: снижение массы тела (выраженное истощение, особенно при тяжелом или длительном течении НЯК; видимое уменьшение подкожно-жировой клетчатки, особенно в 
области живота, бедер и плеч); бледность кожи часто связана с хронической анемией из-за кровопотери при поражении кишечника.
2. Изменения кожи: мелкие кровоизлияния на коже, чаще на конечностях, связанные с коагулопатиями или анемией (петехии); красноватые, болезненные узлы на коже голеней, иногда на 
руках (узловатая эритема) - это частое внекишечное проявление НЯК; сухость и шелушение кожи характерно для пациентов с длительным истощением и нарушением всасывания питательных 
веществ.
3. Изменения суставов и конечностей: деформация или отечность суставов (особенно коленных и голеностопных), связанная с реактивными артритами при НЯК.
4. Абдоминальные признаки: рубцы на животе возможны после хирургических вмешательств (колэктомия, создание стомы); при наличии стомы, связанной с осложнениями НЯК, можно 
обнаружить отверстие (колостома) с защитной наклейкой или его следы.
5. Внекишечные проявления: язвочки в полости рта (афтозный стоматит); в некоторых случаях видны признаки увеита или конъюнктивита (покраснение глаз).
6. Отеки и системные проявления: отеки могут быть умеренными, связанными с гипоальбуминемией из-за потери белков при поражении кишечника.
7. Следы длительного лечения: возможны кожные растяжки (стрии), тонкая кожа, гематомы и "лунообразное" лицо при длительном применении кортикостероидов; на руках или бедрах могут 
быть следы от внутривенного введения препаратов или инфузий.
8. Осложнения НЯК: возможно наличие перианальных свищей, трещин или язв; признаки тромбоза (связаны с гиперкоагуляцией, часто встречающейся при НЯК), возможны: отечность и цианоз 
конечностей.
        </p>
    )
}
export default NjakKozha