function VichVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="green">
            При жизни имелась ВИЧ-инфекция, стадия клиническая (период) IV (СПИД: ВИЧ-ассоциированная иммунодефицитная энцефалопатия, пневмоцистная пневмония, 
            цитомегаловирусный колит, токсоплазмоз головного мозга), на что указывают макро- и микро- скопические 
        изменения этих внутренних органов, наличие антител к специфическим белкам ВИЧ в образцах крови, выявление возбудителей (Pneumocystis jirovecii, цитомегаловируса и 
        токсоплазмы) в соответствующих органах при их бактериологическом исследовании. 
        Вирус иммунодефицита человека (ВИЧ) — это хроническое вирусное заболевание, вызываемое вирусом иммунодефицита человека (HIV-1 или HIV-2), характеризующееся 
        прогрессирующим поражением иммунной системы за счёт разрушения CD4+ T-лимфоцитов, что приводит к угнетению клеточного иммунитета, развитию вторичных инфекций, опухолей и 
        других осложнений. Основными путями передачи ВИЧ являются половой, парентеральный (через кровь) и вертикальный (от матери к ребёнку). Без лечения заболевание прогрессирует 
        от бессимптомного носительства до стадии синдрома приобретённого иммунодефицита (СПИД), который является конечной стадией инфекции. Современная антиретровирусная терапия 
        (АРТ) позволяет существенно замедлить прогрессирование ВИЧ, улучшить качество и продолжительность жизни пациентов.
        Указанное заболевание в причинной связи с наступлением смерти - не состоит.
        </p>
    )
}
export default VichVyvody