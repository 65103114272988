function PancreatitLungs(){
    return (
        <p id="MsoBodyTextIndent" className="green"> При панкреатите возможны реактивные изменения в легких. Часто выявляется реактивный плеврит (плевральная поверхность легких 
        может быть тёмно-красного цвета из-за расширения сосудов, возможны фибринозные наложения из-за системной интоксикации; в плевральной полости возможно наличие серозной, 
        серозно-геморрагической или гнойной жидкости (гидроторакс, гемоторакс или эмпиема плевры)). Сами легкие могут выглядеть увеличенными, бледно-серыми или багровыми, с 
        признаками отёка и кровоизлияний. При осложнённом течении возможны воспалительные и инфекционные изменения, включая пневмонию, абсцессы или плевральные экссудаты. 
        При тяжёлых формах острого панкреатита возможны изменения, характерные для "шоковых лёгких" (лёгкие плотные, тяжёлые, с диффузным отёком и геморрагиями; нарушение 
        нормальной структуры, исчезновение воздушности).
        </p>
    )
}
export default PancreatitLungs