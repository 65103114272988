function HolecistitPodj(){
    return (
        <>
        <p id="MsoBodyTextIndent" className="green">При остром бескаменном холецистите макроскопические изменения поджелудочной железы минимальны и включают умеренный отек, 
            гиперемию и возможное наличие серозного налета на поверхности железы. Некротические или обструктивные изменения, характерные для холелитиаза, отсутствуют.   
        </p>
        <p id="MsoBodyTextIndent" className="green"> При хроническом холецистите, если он осложнялся панкреатобилиарной дисфункцией, возможны признаки хронического панкреатита: 
        поджелудочная железа часто уменьшена в объеме из-за атрофии ткани и замещения ее фиброзной 
            тканью. Железа плотная на ощупь, ее структура грубая, с участками фиброзных тяжей, которые разделяют ткань железы на узлы. Железа приобретает серо-желтый или 
            серо-белый цвет. Видны рубцовые изменения, местами ткань может быть неравномерно утолщена. На разрезе могут быть обнаружены плотные участки кальцификатов (отложения 
            солей кальция), которые часто располагаются в протоках железы. Протоки поджелудочной железы расширены, в них может содержаться мутная жидкость или плотные, каменистые 
            включения (панкреатические камни). В некоторых случаях вместо нормальной ткани поджелудочной железы наблюдается жировое перерождение (липоматоз). В ткани железы или 
            рядом с ней могут быть обнаружены полости, заполненные жидкостью и окруженные фиброзной стенкой (псевдокисты). Железа часто спаяна с соседними органами (желудком, 
            двенадцатиперстной кишкой) из-за хронического воспалительного процесса.
        </p>
        <p id="MsoBodyTextIndent" className="green"> При желчнокаменной болезни (холелитиазе) возможны	признаки билиарного панкреатита (увеличение поджелудочной железы, отёчность, 
            гиперемия; очаговые кровоизлияния или некроз при осложнении (например, обструкции фатерова сосочка камнем)).
        </p>
        </>
    )
}
export default HolecistitPodj