function GbVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="green"> При жизни имелась гипертоническая болезнь 2-3 стадии, на что указывают макро- и микро- скопические 
        изменения внутренних органов (сосудов, сердца, почек и головного мозга). 
        Гипертоническая болезнь (гипертензия) — это хроническое заболевание сердечно-сосудистой системы, характеризующееся устойчивым повышением артериального давления (АД) выше 
        целевых значений (≥140/90 мм рт. ст.), не связанным с другими причинами вторичной гипертензии. Заболевание обусловлено нарушением регуляции сосудистого тонуса, активности 
        ренин-ангиотензин-альдостероновой системы, водно-электролитного баланса, а также влиянием генетических и внешних факторов, таких как стресс, ожирение, малоподвижный образ 
        жизни и избыточное потребление соли. Основные клинические проявления включают головные боли, шум в ушах, головокружение, нарушение зрения, но в начальных стадиях болезнь 
        может протекать бессимптомно. При отсутствии лечения гипертоническая болезнь приводит к поражению органов-мишеней (сердца, мозга, почек, сосудов) и увеличивает риск 
        осложнений, таких как инсульт, инфаркт миокарда и сердечная недостаточность. Лечение направлено на нормализацию АД, изменение образа жизни и применение антигипертензивных 
        препаратов. Указанное заболевание в причинной связи с наступлением смерти - не состоит.</p>
    )
}
export default GbVyvody