function YazvaPechen(){
    return (
        <p id="MsoBodyTextIndent" className="green">Для язвенной болезни желудка и двенадцатиперстной кишки характерны следующие изменения в печени:
        1. При неосложненной язвенной болезни размер и форма печени обычно не изменены; цвет печени может быть нормальным или слегка бледным при наличии хронической анемии, 
        вызванной длительными кровотечениями из язвы. Возможны жировая дистрофия или признаки хронического гепатита, если имеются сопутствующие метаболические или воспалительные 
        заболевания (например, длительная интоксикация алкоголем или медикаментами).
        2. При осложнении в виде пенетрации язвы в печень характеризуется разрушением стенки желудка или двенадцатиперстной кишки и внедрением язвенного дефекта в ткань печени.
В области контакта язвы с печенью обнаруживается гнойное воспаление и разрушение ткани печени. Дно язвы открыто в ткань печени. Участок пенетрации окружен некротизированной 
тканью печени, желтовато-серого или темно-коричневого цвета. В печени в области пенетрации - участки некроза, окруженные зоной гиперемии или фиброзной капсулой при хроническом 
процессе. Возможны гнойные абсцессы вокруг пенетрирующей язвы, заполненные гнойным содержимым.
На поверхности печени — сращения с желудком или двенадцатиперстной кишкой, покрытые фибринозными налетами.
Локализация язвы чаще всего на малой кривизне желудка или в задней стенке луковицы двенадцатиперстной кишки, непосредственно прилегающей к висцеральной поверхности печени.
При пенетрации язвы желудка - на висцеральной поверхности левой доли печени виден дефект, переходящий из желудка; плотные фиброзные спайки между печенью и желудком, инфильтрация 
тканей вокруг дефекта. При пенетрации язвы двенадцатиперстной кишки - зона пенетрации чаще всего расположена на нижней поверхности правой доли печени, ближе к области луковицы; 
дефект окружен плотной соединительной тканью, возможен гнойный очаг (при инфицировании).
3. Общие изменения печени при тяжелых осложнениях язвы (кровотечение, перитонит, шок):
возможна уменьшенная масса печени из-за ишемии и дистрофических изменений.
Бледность печени (при выраженной анемии). В случаях шока печень может быть дряблой, с признаками ишемического некроза.
Застойные изменения (полнокровие) в венах печени могут наблюдаться при выраженных нарушениях кровообращения, связанных с тяжелой анемией или шоком из-за массивного кровотечения.
Возможны начальные признаки острого некроза гепатоцитов, особенно в случае выраженного гиповолемического шока.
Возможные изменения при разлитом перитоните: наличие гнойного или серозно-фибринозного выпота вокруг печени; воспалительная инфильтрация в поддиафрагмальной области печени.    
        </p>
    )
}
export default YazvaPechen