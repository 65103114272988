function AlcoOsnVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="green"> 
        При жизни имелась алкогольная кардиомиопатия на фоне хронической алкогольной интоксикации сопровождавшейся хроническим алкогольным гепатитом, на что указывают макро- и 
        микро- скопические изменения сердца и печени, маркеры хронического употребления алкоголя (ацетальдегид и жирные кислоты этилового эфира (FAEE)), обнаруженные при 
        токсикологическом исследовании ткани печени и головного мозга, алкогольный анамнез имеющийся в материалах дела.   
        Смерть последовала от алкогольной кардиомиопатии, осложнившегося  острой сердечно-сосудистой недостаточностью, что 
        подтверждается макро- и микро- скопическим изменениями как в самом сердце (увеличение размеров и массы, дряблость миокарда, расширение полостей, истончение стенок сердца, 
        жировая и гидропическая дистрофия кардиомиоцитов, очаговые некрозы миокарда, кровоизлияния под эпикардом), так и в других органах с признаками 
        общего венозного полнокровия, жидкого состояния крови в полостях сердца и крупных сосудах, гипоксии, отека тканей и геморрагий: легкие (увеличение массы, обильное 
        серозно-геморрагическое пропитывание ткани, уплотнение паренхимы, 
        застой крови в капиллярах, заполнение альвеол розоватым транссудатом (кардиогенный отек легких), мелкие кровоизлияния под плеврой и в межальвеолярных перегородках), 
        головной мозг (отек мозга, увеличение объема, уплощение извилин и сужение борозд, мелкие точечные кровоизлияния в белом веществе и мозжечке, дистрофические изменения 
        нейронов (гиперхромия ядер, вакуолизация цитоплазмы)), печень (увеличение размеров, «мускатный» рисунок на разрезе (сочетание темных застойных и светлых дистрофически 
        измененных участков), центролобулярный застой крови, жировая дистрофия гепатоцитов), почки (увеличение размеров, дряблая консистенция, застой крови в 
        корковом и мозговом веществе, острая вакуольная дистрофия эпителия канальцев, отек интерстициальной ткани, мелкие кровоизлияния), селезенка(увеличение размеров, дряблость 
        ткани, полнокровие синусов красной пульпы, микрогеморрагии в пульпе). 
Таким образом, между имевшейся алкогольной кардиомиопатией на фоне хронической алкогольной интоксикации и наступлением смерти имеется прямая причинная связь.
Алкогольная кардиомиопатия — это хроническое заболевание сердца, характеризующееся структурными и функциональными изменениями миокарда, вызванными токсическим воздействием 
этанола и его метаболитов. Оно проявляется прогрессирующей дилатацией камер сердца, гипертрофией и/или атрофией кардиомиоцитов, диффузным или очаговым фиброзом, а также 
нарушением сократительной функции. Клинически алкогольная кардиомиопатия проявляется симптомами сердечной недостаточности, аритмиями, тромбоэмболическими осложнениями и 
повышенным риском внезапной сердечной смерти. Заболевание развивается в результате длительного злоупотребления алкоголем, часто в сочетании с недостатком питательных веществ, 
особенно витаминов группы B.
        </p>
    )
}
export default AlcoOsnVyvody