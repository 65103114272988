function PnevmoniaKrupLungs(){
    return (
        <p id="MsoBodyTextIndent" className="green">
            Крупозная (лобарная) пневмония характеризуется стадийными изменениями в легочной ткани:
1. Стадия прилива (1–2 сутки):
пораженная доля легкого увеличена в объеме.
Легкое становится плотным, но сохраняет воздушность (принадлежит гиперемии).
На разрезе легкое выглядит красным и влажным за счет выраженного сосудистого полнокровия.
В плевральной полости появляется серозная или серозно-геморрагическая жидкость.
2. Стадия красного опеченения (3–4 сутки):
пораженная доля легкого плотная, тяжелая, безвоздушная.
На разрезе легкое имеет вид красного или темно-красного цвета, напоминающего сырую печень (отсюда название).
Имеются фибринозные наложения на плевре (плеврит).
С поверхности разреза может стекать серозно-геморрагический или фибринозный экссудат.
3. Стадия серого опеченения (4–6 сутки):
пораженная доля легкого становится еще более плотной и безвоздушной.
На разрезе легкое приобретает серовато-белый или серовато-желтый оттенок из-за скопления фибрина и большого количества лейкоцитов.
Плевра остается тусклой, покрытой фибринозными наложениями.
4. Стадия разрешения (7–9 сутки и далее):
плотность пораженной доли легкого постепенно снижается.
Легкое становится менее однородным, возвращается воздушность.
На разрезе можно видеть участки рассасывания экссудата.
Фибринозные наложения на плевре могут частично отторгаться или оставлять спайки.
В случае карнификации - пораженный участок ткани легкого (доля) может стать похожим по консистенции на резину.
Наиболее частый возбудитель крупозной пневмонии - Streptococcus pneumoniae, реже - Klebsiella pneumoniae (палочка Фридлендера).
В зависимости от осложнений и присоединения вторичной инфекции, можно наблюдать абсцессы, гангрену легкого, эмпиему плевры или организованную пневмонию.
При массивном некротическом процессе в легких вызванном Klebsiella pneumoniae, Fusobacterium, Bacteroides, Pseudomonas aeruginosa, Peptostreptococcus от них можно ощущать запах 
пригорелого мяса, обугленный запах или запах жасмина.
        </p>
    )
}
export default PnevmoniaKrupLungs