function SifilisVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="green">При жизни имелся cифилис II стадии (поздний латентный) (перенесенный сифилитический аортит (стадия ремиссии), мелкие рубцовые 
        изменения в восходящей части аорты), на что указывают изолированные макро- и микро- скопические изменения в ней, положительный TPHA (тест пассивной гемагглютинации с 
        трепонемами) наряду с отрицательными реакцией микропреципитации (РМП) и полимеразной цепной реакцией (ПЦР) на сифилис. 
        Сифилис — это хроническое системное инфекционное заболевание, вызываемое бактерией Treponema pallidum (бледной трепонемой), характеризующееся стадийным течением, 
        поражением кожи, слизистых оболочек, внутренних органов, опорно-двигательного аппарата, нервной и сердечно-сосудистой систем, а также развитием специфических гуммозных 
        гранулём. Заболевание передаётся преимущественно половым путём, но возможны и другие пути инфицирования: вертикальный (от матери к плоду), трансфузионный (с кровью), 
        бытовой (при тесном контакте с поражёнными участками кожи и слизистых). Течение сифилиса включает первичный, вторичный и третичный периоды, а также может протекать 
        латентно. Указанное заболевание в причинной связи с наступлением смерти - не состоит.
        </p>
    )
}
export default SifilisVyvody