function PnevmoniaOchagLab(){
    return (
        <p id="MsoBodyTextIndent" className="green">
            Для очаговой пневмонии характерно: при <u>гистологическом исследовании:</u> 1. Легкие:
            альвеолы заполнены экссудатом, состоящим из нейтрофилов, лимфоцитов, макрофагов, отечной жидкости и обломков клеток.
В некоторых случаях экссудат может быть гнойным (преобладание нейтрофилов) или серозным (богатым белками и плазмой). Альвеолярные перегородки утолщены из-за отека и инфильтрации 
лимфоцитами и макрофагами. Слизистая оболочка бронхиол инфильтрирована нейтрофилами и лимфоцитами. Эпителий бронхиол может быть дистрофически изменен, с признаками десквамации 
(слущивания). Просветы бронхиол содержат слизисто-гнойный экссудат.	Капилляры и мелкие сосуды легочной ткани переполнены кровью, наблюдаются признаки стаза. В тяжелых случаях 
могут быть обнаружены микротромбы в капиллярах и венулах. В участках, прилежащих к очагам пневмонии, плевра утолщена, на ней видны скопления фибрина.
Микроскопические (гистологические) изменения в легких при очаговой пневмонии с градацией по стадиям:
1.1. Стадия прилива (начальная стадия, 1–2 день):
-	Альвеолы: сохранены, но полнокровные капилляры в стенках альвеол свидетельствуют о гиперемии; в просвете альвеол выявляется серозный экссудат с единичными нейтрофилами и 
эритроцитами; возможно наличие десквамированных альвеолоцитов.
-	Межальвеолярные перегородки: утолщены за счет отека и полнокровия капилляров; преобладают признаки сосудистой гиперемии.
-	Сосуды: полнокровные, местами с явлениями стаза.
-	Интерстициальная ткань: незначительный отек, возможен периваскулярный инфильтрат из лимфоцитов.
Клиническое значение: начальная стадия воспаления, доминирует сосудистая реакция.
1.2. Стадия красного опеченения (2–4 день):
-	Альвеолы: практически полностью заполнены фибринозно-гнойным экссудатом; в составе экссудата: фибрин, нейтрофилы, эритроциты, слущенные эпителиальные клетки; частичная 
обтурация альвеолярных просветов.
-	Межальвеолярные перегородки: выраженная полнокровность; признаки активного воспаления: инфильтрация нейтрофилами и макрофагами.
-	Сосуды: плотно переполнены кровью (гиперемия); возможны признаки микроэмболии.
-	Интерстициальная ткань: выраженный отек и воспалительный инфильтрат, состоящий из нейтрофилов, лимфоцитов и макрофагов.
Клиническое значение: активная фаза воспаления, начало уплотнения легочной ткани.
1.3. Стадия серого опеченения (4–6 день):
-	Альвеолы: полностью заполнены фибринозно-гнойным экссудатом; эритроциты практически отсутствуют, доминируют нейтрофилы и фибрин; структура альвеол сохраняется, но просветы 
полностью обтурированы экссудатом.
-	Межальвеолярные перегородки: утолщены, инфильтрированы лимфоцитами и макрофагами; сосуды частично коллабированы.
-	Интерстициальная ткань: умеренное воспаление, сохраняется отек; преобладают фиброзные изменения в периваскулярной области.
Клиническое значение: максимальная стадия уплотнения легочной ткани, снижение газообмена.
1.4. Стадия разрешения (7–10 день и более):
-	Альвеолы: просветы частично очищены от экссудата за счет фагоцитоза макрофагами; остаточный экссудат содержит деградировавшие нейтрофилы, макрофаги и фибрин; возможно 
появление участков воздушности.
-	Межальвеолярные перегородки: частичное восстановление толщины и структуры; признаки регенерации: активные альвеолоциты II типа (регенерация эпителия).
-	Интерстициальная ткань: уменьшение отека, формирование фиброзных изменений в некоторых участках; остаточные воспалительные инфильтраты.
Клиническое значение: завершающий этап, возможны последствия в виде остаточных фиброзных изменений. 
Каждая стадия очаговой пневмонии характеризуется специфической морфологической картиной, что позволяет определить этап воспалительного процесса.
2. Сердечно-сосудистая система:
Правый желудочек сердца: гипертрофия кардиомиоцитов при длительном хроническом заболевании; признаки дистрофии миокарда (зернистая или жировая дистрофия), связанные с гипоксией.
Капилляры: дистония сосудов, участки стаза, микротромбы.
3. Печень:
Центролобулярный застой: расширение центральных вен и капилляров в доле печени, переполнение их кровью; гепатоциты в зоне застоя подвергаются жировой или зернистой дистрофии; 
возможна некроз отдельных гепатоцитов из-за хронической гипоксии.
Скопление макрофагов: в перисинусоидальных пространствах отмечаются скопления макрофагов, фагоцитирующих эритроциты и остатки разрушенных клеток.
4. Почки:
Острая гипоксия: дистрофия эпителия канальцев, вакуолизация цитоплазмы; расширение капилляров клубочков, признаки стаза; белковые цилиндры в просветах канальцев, что 
указывает на гипоксическое повреждение.
Интерстициальные изменения: слабая лимфоцитарная инфильтрация в интерстициальной ткани.
5. Головной мозг:
Отек мозга: расширение периваскулярных и перицеллюлярных пространств; мелкие кровоизлияния в ткани мозга; признаки гипоксической дистрофии нейронов: пикноз ядер, вакуолизация 
цитоплазмы, нарушение структуры нейрофиламентов.
Микроскопические изменения при очаговой пневмонии отражают сочетание локального воспалительного процесса в легочной ткани и системных изменений, вызванных гипоксией и 
воспалительным ответом. <u>Бактериологическое исследование:</u> посев содержимого из очагов воспаления - определение возбудителя (пневмококк, стафилококк, стрептококк и 
др.). <u>Вирусологическое исследование (при подозрении на вирусную природу):</u> выделение вирусов из тканей, серологические тесты. <u>Токсикологическое исследование:</u> на 
наличие токсических веществ, которые могли повлиять на лёгочную ткань (угарный газ (уровень карбоксигемоглобина в крови), окислы азота, 
хлор, фосген, ацетон, бензол, толуол, аммиак, сероводород, ртуть, кадмий, пестициды, наркотические и психоактивные вещества, метанол и этанол).
        </p>
    )
}
export default PnevmoniaOchagLab