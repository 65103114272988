function SifilisOsnVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="green">
             При жизни имелся сифилис III стадии (по результатам исследования трупа: гуммозный аортит, аневризма восходящей аорты), на что указывают макро- и микро- скопические 
             изменения в восходящей части аорты, положительный TPHA (тест пассивной гемагглютинации с трепонемами), наряду с отрицательными реакцией микропреципитации (РМП) и 
             полимеразной цепной реакцией (ПЦР) на сифилис.  
        Смерть последовала от сифилиса III стадии (гуммозный аортит, аневризма восходящей аорты), который сопровождался хронической сердечной недостаточностью, приведшей к 
        декомпенсации кровообращения, гипоксии органов и тканей и полиорганной недостаточности, что подтверждается: гипертрофией и дилятацией левого желудочка сердца, полнокровием 
        внутренних органов (легких, печени, почек, селезенки), застойными явлениями в малом и большом кругах кровообращения (отек легких, гидроторакс, застойное увеличение печени);
дистрофическими изменениями в печени (застойная печень с очагами некроза в центролобулярных зонах), тубулярной дистрофией в почках, отеком и полнокровием головного мозга.
Таким образом, между имевшимся сифилисом III стадии (гуммозный аортит, аневризма восходящей аорты) и наступлением смерти имеется прямая причинная связь.
        Сифилис — это хроническое системное инфекционное заболевание, вызываемое бактерией Treponema pallidum (бледной трепонемой), характеризующееся стадийным течением, 
        поражением кожи, слизистых оболочек, внутренних органов, опорно-двигательного аппарата, нервной и сердечно-сосудистой систем, а также развитием специфических гуммозных 
        гранулём. Заболевание передаётся преимущественно половым путём, но возможны и другие пути инфицирования: вертикальный (от матери к плоду), трансфузионный (с кровью), 
        бытовой (при тесном контакте с поражёнными участками кожи и слизистых). Течение сифилиса включает первичный, вторичный и третичный периоды, а также может протекать 
        латентно.
        </p>
    )
}
export default SifilisOsnVyvody