function HolecistitVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="green">
            При жизни имелась желчнокаменная болезнь, хронический калькулёзный холецистит (камни смешанного состава в просвете желчного пузыря; перихолецистит, утолщение и склероз 
            стенки желчного пузыря), на что указывают макро- и микро- скопические изменения в брюшной полости и желчном пузыре.
Желчнокаменная болезнь (холелитиаз) — это заболевание, при котором в желчном пузыре или желчевыводящих путях образуются камни (конкременты). Эти камни могут быть холестериновыми, 
пигментными, смешанными или известковыми (кальциевыми) по составу. Образование камней может быть вызвано различными факторами, включая застой желчи, изменения в её составе, воспаление 
и нарушения обмена веществ. Желчнокаменная болезнь может быть бессимптомной (латентной) или проявляться болями в животе, желтухой, расстройствами пищеварения и осложнениями, 
такими как холецистит или желчная обструкция.
Холецистит — это воспаление желчного пузыря, которое может быть острым или хроническим. Острый холецистит часто сопровождается инфекцией, обычно вызванной бактериями, и 
характеризуется болями в правом подреберье, лихорадкой и возможным желтухой. Хронический холецистит возникает вследствие многократных воспалений и может привести к утолщению 
стенки желчного пузыря, его фиброзу и деформации. Часто наблюдается в сочетании с желчнокаменной болезнью.
Указанные заболевания в причинной связи с наступлением смерти - не состоят.
        </p>
    )
}
export default HolecistitVyvody