function VichOsnDiagnoz(){
    return (
        <p id="MsoBodyTextIndent" className="green">
        Основное заболевание: синдром приобретенного иммунодефицита (СПИД) (ВИЧ-инфекция IV клинической стадии, иммунный блот положительный). Вторичные заболевания:
генерализованный кандидоз (поражение пищевода, желудка, кишечника, легких), пневмоцистная пневмония, туберкулез легких и внутригрудных лимфатических узлов, цитомегаловирусный 
энцефалит.
Осложнения: полиорганная недостаточность (острая дыхательная недостаточность, отек головного мозга, фиброз и дистрофические изменения в печени, 
    атрофия нефронов, интерстициальный фиброз, гломерулосклероз, дистрофические изменения эпителия канальцев и сосудистый спазм в почках).
        </p>
    )
}
export default VichOsnDiagnoz