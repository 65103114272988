function KronaOsnVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="green">
            Основной вывод
            При жизни имелась болезнь Крона, хроническое течение (сегментарное поражение терминального отдела подвздошной кишки, 
        трансмуральное воспаление с участками фиброза и язвенными дефектами, наличие неказеозных гранулем в стенке кишечника, лимфоидная гиперплазия мезентериальных лимфатических 
        узлов), на что указывают макро- и микро- скопические изменения в этих органах; 
        отрицательные результаты на сальмонеллы, шигеллы, кампилобактер, Clostridium difficile - при бактериологическом исследовании;
        обнаружение мутаций в гене NOD2/CARD15 (положительный результат в 2 локусах - что подтверждает генетическую предрасположенность) - при молекулярно-генетическом исследовании, 
        а так же данные иммуногистохимического исследования: CD68 - выраженная экспрессия, подтверждающая наличие гранулём из эпителиоидных клеток и макрофагов, TNF-α - высокая 
        экспрессия в гранулёмах и воспалённых участках слизистой, CD3/CD20 - лимфоцитарная инфильтрация с преобладанием CD3+ (Т-клеток).
        Указанное заболевание в причинной связи с наступлением смерти - не состоит.
        Болезнь Крона — это хроническое гранулематозное воспалительное заболевание желудочно-кишечного тракта, характеризующееся сегментарным поражением с трансмуральным (затрагивающим все слои стенки) воспалением. Патология может локализоваться в любом отделе желудочно-кишечного тракта, от ротовой полости до ануса, но чаще всего поражает терминальный отдел тонкой кишки и толстую кишку.
Основными клиническими проявлениями являются хроническая диарея, боли в животе, потеря массы тела и системные симптомы воспаления, такие как лихорадка. Заболевание может сопровождаться осложнениями, включая стенозы, свищи, абсцессы, а также внекишечные проявления, такие как поражение кожи, суставов, глаз и печени.

        </p>
    )
}
export default KronaOsnVyvody