function GbKozha(){
    return (
        <p id="MsoBodyTextIndent" className="green">
            При гипертонической болезни высокой степени и стадии (из-за длительного повышения артериального давления, поражения органов-мишеней, а также осложнений, характерных 
            для этой патологии), можно выявить следующие особенности:
1. Общие признаки: цианоз или бледность кожи (бледность может быть связана с анемией, часто сопутствующей хронической сердечной недостаточности), цианоз 
чаще локализуется на конечностях, губах, и носогубном треугольнике; отеки наблюдаются при хронической сердечной недостаточности, развившейся на фоне гипертонии, отечность 
выражена на нижних конечностях, в области голеней и стоп, а при декомпенсации может быть генерализованной (анасарка).
2. Признаки хронической сердечной недостаточности: вынужденное положение тела в момент смерти (например, полусидя), характерное для людей с тяжелой одышкой из-за сердечной 
недостаточности; увеличение и выбухание шейных вен вследствие застоя крови в большом круге кровообращения.
3. Признаки атеросклеротического поражения сосудов: сухая, истонченная кожа, иногда с очагами трофических язв, особенно на голенях; уменьшение волосистости на нижних конечностях 
из-за хронической ишемии; увеличение рельефа артерий (например, височных) может быть заметно при выраженном атеросклерозе.
4. Изменения в области лица: одутловатое желтовато-бледное лицо с синюшным оттенком (акроцианозом) и выраженным периорбитальным отеком, характерное для хронической сердечной 
недостаточности (лицо Корвизара); мелкие сосудистые звездочки или петехии (на коже лица могут быть мелкие кровоизлияния, связанные с повышенной ломкостью капилляров).
5. Гипертензивные кризы и их последствия: точечные кровоизлияния (петехии) в области шеи, верхней части грудной клетки, лица, вызванные резкими скачками давления; признаки 
инсульта (возможна асимметрия лица, следы травм головы или тела (вследствие падения), характерные для острого нарушения мозгового кровообращения).
6. Признаки длительного лечения: следы инъекций от регулярного применения гипотензивных препаратов (например, внутривенные или подкожные инъекции) могут быть обнаружены на руках 
или бедрах; гематомы или кровоподтеки (часто возникают у пациентов, получающих антикоагулянтную терапию (например, для профилактики тромбозов)).
7. Признаки почечной недостаточности: сероватый оттенок кожи (характерен для хронической почечной недостаточности, вызванной нефросклерозом на фоне гипертонии); отечность лица 
(особенно выражена в области век и периорбитальной зоне).
        </p>
    )
}
export default GbKozha