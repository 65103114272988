function YazvaLab(){
    return (
        <p id="MsoBodyTextIndent" className="green">
            Для язвенной болезни желудка и двенадцатиперстной кишки характерно: при <u>гистологическом исследовании:</u> 
            1. Желудок:
-	Язвенный дефект: деформация слизистой оболочки с разрушением эпителиального слоя; наличие некротизированной ткани в области дна язвы, покрытой фибринозным или гнойным налетом; 
инфильтрация лейкоцитами, лимфоцитами, плазматическими клетками и макрофагами по краям язвы; в хронической язве — выраженная фиброзная ткань в подслизистом и мышечном слоях.
-	Соседние участки слизистой: признаки атрофии и метаплазии эпителия, часто кишечного типа; утолщение и гиалинизация стенок сосудов (признаки хронического воспаления); 
умеренная гиперплазия желез.
2. Двенадцатиперстная кишка:
-	Язвенные изменения: полное разрушение слизистой оболочки в зоне язвы, затрагивающее подслизистый и мышечный слои; признаки активного воспаления в краевой зоне язвы, включая 
выраженную лимфоплазмоцитарную инфильтрацию; фиброз в области заживших язв, рубцовая деформация стенки кишки.
-	Соседние ткани: гиперплазия бокаловидных клеток (признак компенсаторных изменений); сосудистая конгестия в собственной пластинке слизистой.
3. Сосуды в области язвы:
-	Утолщение и склероз стенок мелких артериол (признаки хронического воспаления).
-	Тромбоз или тромбогеморрагические изменения в сосудах, питающих пораженную область.
4. Печень (при сопутствующей патологии):
-	Признаки хронической гипоксии, возможная жировая дистрофия гепатоцитов.
-	Умеренный фиброз в портальных трактах.
5. Брюшина (при осложнениях):
-	При перфорации язвы — фибринозный и/или гнойный экссудат с наличием бактерий и фрагментов пищи.
-	Умеренная гиперемия сосудов с инфильтрацией нейтрофилами.
6. Общие изменения в организме:
-	Возможны признаки анемии (например, гипоплазия костного мозга из-за хронической кровопотери).
-	Реактивные изменения в лимфатических узлах (гиперплазия фолликулов). <u>Микробиологическое исследование: </u> проведение теста на наличие Helicobacter pylori (выявление 
бактерии, которая является частой причиной язвенной болезни). <u>Токсикологическое исследование:</u> проверка на наличие препаратов, которые могли усугубить язву (например, НПВС, 
алкоголь).
        </p>
    )
}
export default YazvaLab