function GrippVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="green">
            При жизни имелась гриппозная вирусная инфекция (грипп типа A) (острый ларингит, острый трахеобронхит, острая интерстициальная вирусная пневмония), на что указывают 
            макро- и микро- скопические изменения в соответствующих органах, а также результат полимеразной цепной реакции (ПЦР) из образцов легочной ткани и трахеобронхиального 
            секрета: выделен вирусный РНК, специфичный для гриппа типа A и результат иммуногистохимического исследования: образцы легких окрашены на наличие вирусных 
            антигенов гриппа типа A. 
            Грипп — это острое инфекционное заболевание, вызываемое вирусами гриппа типов A, B и C из семейства Orthomyxoviridae, характеризующееся преимущественно поражением 
            дыхательных путей, выраженной интоксикацией, лихорадкой и катаральными симптомами. Передача инфекции осуществляется воздушно-капельным путём, реже через загрязнённые 
            предметы. Заболевание протекает с различной степенью тяжести: от лёгких форм до осложнённых, включая вирусную пневмонию, бактериальные суперинфекции, поражения 
            сердечно-сосудистой и нервной систем. Грипп имеет выраженную сезонность и может вызывать эпидемии и пандемии. Профилактика включает вакцинацию и меры по снижению 
            распространения инфекции. Указанное заболевание в причинной связи с наступлением смерти - не состоит.
        </p>
    )
}
export default GrippVyvody