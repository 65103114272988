function AstmaLungs(){
    return (
        <p id="MsoBodyTextIndent" className="green">
        Для бронхиальной астмы характерно: легкие увеличены в объеме, их края закруглены, при пальпации ощущается повышенная воздушность (эмфизема); на разрезе легочная ткань 
        светлая, с участками истончения межальвеолярных перегородок; утолщенные стенки бронхов, наличие слизи, густого секрета или пробок в их просвете; участки спадения легочной 
        ткани, связанные с обструкцией мелких бронхов (ателектазы). В случае смерти во время приступа бронхиальной астмы (астматического статуса) - в бронхах и трахеи много 
        вспененной слизи, сами легкие - эмфизематозно вздуты.
        </p>
    )
}
export default AstmaLungs