function DifteriaSerdceAorta(){
    return (
        <p id="MsoBodyTextIndent" className="green"> При дифтерии в сердце возможны признаки токсического миокардита: увеличение размеров сердца, миокард дряблый, глинистый, 
        тусклый на разрезе, дистрофические изменения миокарда (жировая дистрофия). При дифтерии, осложнившейся асфиксией возможно обнаружение общеасфиксических признаков: правые 
        отделы сердца (предсердие и желудочек) переполнены тёмной жидкой кровью; левый желудочек относительно опустошён (в поздних стадиях асфиксии); точечные кровоизлияния под 
        эпикардом сердца преимущественно на задней стенке сердца (пятна Тардье); кровь темного цвета, жидкая из-за недостаточной оксигенации и повышенного содержания углекислого 
        газа; обильное венозное полнокровие во внутренних органах; цианоз внутренних органов и тканей.
        </p>                
    )
}
export default DifteriaSerdceAorta