function HoblKozha(){
    return (
        <p id="MsoBodyTextIndent" className="green">При хронической обструктивной болезни легких (ХОБЛ) большинство изменений, выявляемых при наружном осмотре, связаны 
        с хронической гипоксией, дыхательной недостаточностью и возможными осложнениями, такими как лёгочное сердце, истощение и венозный застой:
-	Истощение (кахексия): возможна значительная потеря массы тела, истончение подкожного жирового слоя; выраженный бледный или серовато-цианотичный оттенок кожи.
-	Цианоз (синюшный оттенок кожи, особенно выраженный в области пальцев рук и ног, губ, ушей (центральный цианоз), возможен диффузный цианоз, связанный с гипоксией).
-	Сухость кожи и слизистых оболочек, трофические изменения в ногтях и коже (на фоне хронической гипоксии и дыхательной недостаточности кожа может выглядеть обезвоженной).
-	Утолщение дистальных фаланг пальцев рук и ног, деформация ногтевых пластин в виде выпуклости ("барабанные палочки" и "часовые стёкла" ).
-	Деформация грудной клетки (возможна «бочкообразная» форма грудной клетки на фоне хронической обструкции и эмфиземы легких, приводящих к перерастяжению легочной ткани и 
дыхательной недостаточности).
-	Отёк и пастозность нижних конечностей (могут быть вызваны правожелудочковой сердечной недостаточностью (хроническое лёгочное сердце)).
-	Гиперемия и венозный застой (в области нижних конечностей или спины (трупные пятна могут быть интенсивного цианотического оттенка)).
-   Признаки дыхательной недостаточности (внешне может проявляться втяжением вспомогательных мышц в области шеи и межрёберных промежутков (при жизни, зафиксировано в предсмертном 
состоянии), наличие гипертрофии шейных мышц (грудиноключично-сосцевидных) и межреберных, которые активно участвовали в дыхании при жизни).
-	Интенсивные трупные пятна цианотического оттенка, особенно в нижних отделах тела (трупные признаки гипоксии).
-   Признаки хронического курения (при наличии этой привычки): желтовато-коричневый цвет кожи пальцев на руках (особенно указательного и среднего), пигментация зубов (воздействие 
табачного дыма).
        </p>
    )
}
export default HoblKozha