function MeningitGm(){
    return (
        <p id="MsoBodyTextIndent" className="green"> При менингите оболочки головного мозга утолщены, отечны, напряжены. Сосуды мягкой мозговой оболочки полнокровны, расширены, 
        переполнены кровью; видны мелкие кровоизлияния. Мозговое вещество отечное, пастозное; возможна деформация желудочков из-за повышения внутричерепного давления. Аналогичные 
        изменения в оболочках спинного мозга. По характеру воспалительного процесса можно выделить следующие формы менингита:
1.  Серозный менингит — форма, при которой в оболочках мозга преобладает воспалительная реакция с образованием серозного экссудата. Характеризуется умеренным повышением белка в 
ликворе, преимущественно лимфоцитарным составом воспалительного инфильтрата и относительно менее выраженной клинической картиной. Может быть вызван вирусами (энтеровирусы, вирус 
паротита), реже — туберкулезной инфекцией или грибами. Мозговые оболочки умеренно утолщены, полупрозрачны, слегка набухшие, бледно-серого или серо-розового цвета. Сосуды умеренно 
инъецированы, возможны точечные кровоизлияния. Ликвор прозрачный или слегка опалесцирующий, с умеренным увеличением количества лимфоцитов. Мозговая ткань может быть незначительно 
отечной, с расширением субарахноидальных пространств.
2.  Гнойный менингит — форма, связанная с массивным бактериальным воспалением (наиболее часто Neisseria meningitidis, Streptococcus pneumoniae, Haemophilus influenzae). 
Характеризуется образованием обильного гнойного экссудата в подпаутинном пространстве, резко выраженной интоксикацией, нейтрофильным составом воспалительного инфильтрата в ликворе 
и тяжелым клиническим течением. Мозговые оболочки резко утолщены, покрыты плотным слоем мутного гнойного экссудата желто-зеленого цвета. Сосуды резко инъецированы, с множественными 
мелкими кровоизлияниями. Ликвор густой, мутный, с резким увеличением количества нейтрофилов и высокой концентрацией белка. Базальные цистерны заполнены густым гнойным экссудатом, 
который может распространяться в спинномозговой канал. Мозговая ткань отечная, с признаками сдавления в области основания и мозжечка.
3.  Серозно-гнойный менингит — это форма воспаления оболочек головного и спинного мозга, характеризующаяся выделением смешанного экссудата, содержащего как серозный (богатый 
белками и жидкостью), так и гнойный (с преобладанием нейтрофилов) компоненты. Этиология чаще всего бактериальная (например, Streptococcus pneumoniae, Neisseria meningitidis), но 
возможны вирусные, грибковые или смешанные инфекции. Серозно-гнойный менингит занимает промежуточное положение между чисто серозным и гнойным воспалением, сочетая признаки обоих 
типов экссудативных процессов. Мозговые оболочки утолщены, набухшие, пропитаны серозно-гнойным экссудатом с желтовато-белым оттенком (цвет экссудата варьирует от полупрозрачного 
серозного до мутного гнойного). Кровеносные сосуды резко инъецированы, иногда с небольшими точечными кровоизлияниями. Ликвор мутный, с повышенным содержанием белка и клеточных 
элементов (преимущественно нейтрофилов и лимфоцитов). Поверхность мозга может быть покрыта наложениями серозно-гнойного экссудата, особенно в базальных отделах.
4.  Фибринозный менингит - характеризуется образованием фибринозного экссудата. Часто возникает при туберкулезе, грибковых инфекциях, сифилисе. Образуется фибринозная пленка на 
поверхности мягких мозговых оболочек. Экссудат может быть прозрачным, плотным, сероватого или желтоватого цвета.
5.  Геморрагический менингит - характеризуется наличием кровоизлияний в оболочках мозга на фоне воспаления. Может быть вызван вирусами (например, вирусом гриппа, герпеса) или 
бактериями (менингококк). Часто сопровождается выраженной интоксикацией. Обнаруживаются мелкие и крупные кровоизлияния в мягкой мозговой оболочке.
6.  Гранулематозный менингит - возникает при хронических инфекциях, таких как туберкулез, саркоидоз или грибковые заболевания. Воспалительный процесс носит продуктивный характер, 
сопровождается образованием гранулем. Локализуется преимущественно в основании мозга. Мозговые оболочки утолщены, тусклые, с участками плотной грануляционной ткани. Гранулемы: 
мелкие узелки серо-желтого цвета, чаще локализуются на основании мозга. Сосуды часто облитерированы или с признаками продуктивного васкулита. Ликвор мутный или слегка 
опалесцирующий, с повышенным содержанием мононуклеарных клеток. Мозговая ткань может иметь участки перифокального отека вокруг гранулем. При туберкулезном менингите – видны 
серовато-желтые бугорки (туберкуломы) по ходу сосудов.
7.  Асептический менингит - воспаление мозговых оболочек без бактериального возбудителя (отсутствие бактериальных возбудителей при микробиологическом исследовании).
Часто вызван вирусами (энтеровирусы, вирус герпеса, вирус иммунодефицита человека). Может развиваться на фоне системных заболеваний (например, системная красная волчанка). Мозговые 
оболочки незначительно утолщены, полупрозрачны или слегка мутные. Сосуды умеренно инъецированы. Ликвор прозрачный или слегка опалесцирующий, с преобладанием лимфоцитов и 
нормальным или слегка повышенным уровнем белка. Отечность мозга умеренная, без видимых наложений экссудата.
8.  Эозинофильный менингит - характеризуется преобладанием эозинофилов в экссудате. Может быть вызван паразитарными инфекциями (ангиостронгилез, цистицеркоз) или аллергическими 
реакциями (часто сочетается с другими признаками паразитарной инвазии или аллергии). Редкая форма менингита. Мозговые оболочки умеренно утолщены, бледно-желтоватого цвета. 
Сосуды инъецированы, иногда с мелкими геморрагиями. Ликвор мутный или слегка опалесцирующий, с преобладанием эозинофилов (более 10% от общего числа клеток). Мозговая ткань может 
быть отечной, с участками гиперемии.
9.  Липоидный менингит - сопровождается накоплением липидов в оболочках мозга. Чаще всего связан с попаданием липидных веществ в субарахноидальное пространство (например, после 
травмы или введения препаратов). Мозговые оболочки утолщены, с участками маслянистого блеска. Цвет экссудата желтовато-белый, с характерным жирным (липоидным) оттенком. Ликвор 
содержит капли жира, видимые при микроскопии или макроскопически в виде маслянистого налета. Отечность выраженная, может наблюдаться расширение субарахноидальных пространств. 
Сосуды умеренно инъецированы, без выраженных изменений.
        </p>                
    )
}
export default MeningitGm