function saharDiab2Vyvody(){
    return (
        <p id="MsoBodyTextIndent" className="green">При жизни имелся сахарный диабет 2 типа, на что указывают макро- и микро- скопические 
        изменения внутренних органов (сердца, почек, печени, поджелудочной железы, выраженное поражение крупных сосудов (макроангиопатия)), выраженные трофические язвы 
        нижних конечностей, повышенное содержание липидов и гликированного гемоглобина в крови. 
        Сахарный диабет 2 типа — это хроническое метаболическое заболевание, характеризующееся сочетанием инсулинорезистентности (сниженной чувствительности тканей к инсулину) и 
        относительного дефицита инсулина, что приводит к нарушению углеводного обмена и стойкой гипергликемии. Чаще развивается у взрослых, особенно при наличии факторов риска, 
        таких как ожирение, малоподвижный образ жизни, генетическая предрасположенность и метаболический синдром. Заболевание может долгое время протекать бессимптомно, но при 
        прогрессировании приводит к осложнениям, включая поражение сосудов (микро- и макроангиопатии), нервной системы (нейропатия), почек (нефропатия), глаз (ретинопатия) и 
        других органов. Диагноз подтверждается на основании повышенного уровня глюкозы в крови, гликированного гемоглобина и других тестов. Лечение включает изменение образа 
        жизни, контроль массы тела, применение гипогликемических препаратов и, в некоторых случаях, инсулинотерапию.
        Указанное заболевание в причинной связи с наступлением смерти - не состоит.
        </p>
    )
}
export default saharDiab2Vyvody