function YazvaBrushina(){
    return (
        <p id="MsoBodyTextIndent" className="green">
        При хронической язве желудка, серозная оболочка в области язвы утолщена, иногда спаяна с прилижащими органами (поджелудочной железой, поперечной ободочной кишкой, 
        сальником, печенью). 
        В случае перфорации язвы желудка или двенадцатиперстной кишки, возможны следующие варианты макроскопической картины в брюшной полости: 
        1. При разрыве крупного сосуда — кровянистое содержимое в брюшной полости (гемоперитонеум).      
2. Свежая перфорация и локальный перитонит: перфорация приводит к попаданию содержимого желудка или кишечника в брюшную полость, вызывая острый воспалительный процесс.
Отверстие в стенке органа находится в области язвы (чаще на малой кривизне желудка или в луковице двенадцатиперстной кишки); размер отверстия может варьировать от нескольких 
миллиметров до 1 см. Края перфорации могут быть неровными, инфильтрированными, покрытыми фибрином или остатками пищи.
Имеются признаки раздражения брюшины (покраснение, набухание, покрытие фибринозным налетом); локализация воспаления чаще ограничивается областью вокруг перфорации. Оментум и 
соседние петли кишечника могут "припаиваться" к зоне повреждения, создавая локальное воспалительное уплотнение.
В области локального воспаления возможно наличие остатков пищи, желудочного сока, желчи (при поражении двенадцатиперстной кишки), газовые пузыри.
3. Разлитой перитонит (в случае быстрого распространения содержимого по брюшной полости развивается разлитой перитонит):
все отделы брюшной полости содержат мутную, серозно-гнойную или геморрагическую жидкость с примесью пищи, желудочного сока или желчи.
Брюшина тусклая, покрыта обильным фибринозным налетом, иногда смешанным с гноем.
Петли кишечника напряжены, гиперемированы.
Между петлями — вязкий экссудат, часто с примесью пищи или желчи.
Оментум инфильтрирован, покрыт фибринозно-гнойным налетом.
Специфические зоны: меньшая кривизна желудка, верхний этаж брюшной полости, область диафрагмы поражаются чаще.
4. Хронический перитонит (в случаях, когда перфорация существует длительное время или острый процесс становится хроническим, формируются признаки хронического перитонита):
Плотные сращения между петлями кишечника, сальником и другими органами.
Фиброзно-склеротические изменения на поверхности брюшины.
Толстые слои фибрина, организовавшиеся в плотные рубцы.
Капсулированные очаги экссудата (гной или мутная жидкость).
Органы брюшной полости деформированы за счет рубцово-спаечного процесса.
На стенках кишечника и желудка могут быть участки уплотнений и атрофии.
В капсулированных участках — мутная жидкость или гной.
Возможны абсцессы или затеки гноя в глубине брюшной полости, кишечная непроходимость.
</p>
    )
}
export default YazvaBrushina