function PeritonitVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="green">
            При жизни имелся острый локализованный фибринозно-гнойный перитонит, развившийся вследствие перфорации язвы двенадцатиперстной кишки, на что указывают макро- и микро- 
            скопические изменения в брюшной полости и двенадцатиперстной кишке.
            Острый перитонит — это острое воспаление брюшины (серозной оболочки, выстилающей брюшную полость и покрывающей внутренние органы), возникающее в результате 
            инфицирования или асептического раздражения. Характеризуется развитием системной воспалительной реакции организма, ведущей к нарушению работы внутренних органов, 
            интоксикации и, при отсутствии своевременного лечения, высокой вероятности летального исхода.
Основными причинами острого перитонита являются:
-	Перфорация органов желудочно-кишечного тракта (например, язвы желудка, кишечника).
-	Травмы живота с повреждением полых органов.
-	Осложнения воспалительных процессов (аппендицит, холецистит, панкреатит).
-	Послеоперационные осложнения (несостоятельность швов).
Клинически острый перитонит проявляется сильной болью в животе, напряжением передней брюшной стенки («доскообразный живот»), лихорадкой, тахикардией и признаками интоксикации.
Указанное заболевание является сопутствующим заболеванием, которое усугубило общее состояние организма, но не было непосредственной причиной смерти.
        </p>
    )
}
export default PeritonitVyvody