function HoblVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="green">
            При жизни имелась хроническая обструктивная болезнь легких (ХОБЛ): хронический обструктивный бронхит (хронический воспалительный инфильтрат в стенках бронхов 
            (лимфоциты, макрофаги, плазматические клетки), гиперплазия бокаловидных клеток и слизистых желез, утолщение базальной мембраны, метаплазия и десквамация эпителия в 
            бронхах и бронхиолах, угольно-пылевые отложения в макрофагах); эмфизема легких (увеличение объема легких, повышенная воздушность легочной ткани, наличие булл, 
            деструкция межальвеолярных перегородок); легочная гипертензия (гипертрофия правого желудочка (толщина стенки более 5 мм), признаки хронического венозного застоя 
            ("мускатная печень"), утолщение медии и интимы, гипертрофия гладкомышечного слоя легочных артерий), на что указывают макро- и микро- скопические изменения в легких, 
            сердце и печени.
            Хроническая обструктивная болезнь легких (ХОБЛ) — это прогрессирующее воспалительное заболевание дыхательных путей, характеризующееся необратимым или частично 
            обратимым ограничением воздушного потока, связанное с хроническим воспалением, поражающим бронхи, паренхиму легких и сосудистую систему, чаще всего возникающее в 
            результате воздействия вредных факторов, таких как курение, загрязнение воздуха или профессиональные вредности. Основные клинические проявления включают хронический 
            кашель, одышку и продуктивную мокроту, а основным патофизиологическим механизмом является сужение дыхательных путей вследствие воспаления и ремоделирования тканей.
            Указанное заболевание в причинной связи с наступлением смерти - не состоит.
        </p>
    )
}
export default HoblVyvody