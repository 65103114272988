function GepatСOsnVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="green">
             При жизни имелся хронический вирусный гепатит С в активной стадии (крупноузловой цирроз печени), на что указывают макро- и микро- скопические изменения в 
             печени, положительный результат полимеразной цепной реакции (ПЦР) на выявление РНК вируса гепатита C в крови.
        Смерть последовала от хронического вирусного гепатита С сопровождавшегося крупноузловым циррозом печени и осложнившегося полиорганной недостаточностью (печеночной 
        (энцефалопатия (стадия IV, кома), отек головного мозга), почечной (гепаторенальный синдром), дыхательной (гепатопульмональный синдром) и сердечно-сосудистой), что 
        подтверждается данными из медицинских документов (истории болезни), а также наличием:
признаков острого канальцевого некроза, отека и дистрофических изменений эпителия канальцев, выраженного сосудистого спазма в почках;
отека легких, диффузного ателектаза, кровоизлияния в альвеолы; отека головного мозга, ишемических изменений нейронов, очаговых субкортикальных некрозов; признаков хронической 
гипоксии миокарда (мелкоочаговый миофиброз), установленных при исследовании трупа.
Таким образом, между имевшимся хроническим вирусным гепатитом С и наступлением смерти имеется прямая причинная связь.
Вирусный гепатит C (ВГС) — это инфекционное заболевание, вызываемое вирусом гепатита C (HCV), характеризующееся преимущественным поражением печени, развитием 
        воспалительных и деструктивных изменений в гепатоцитах, а также высоким риском хронизации процесса, что может приводить к фиброзу, циррозу и гепатоцеллюлярной карциноме. 
Заболевание передаётся преимущественно парентеральным путём через кровь и её компоненты, реже половым и вертикальным путями. Для ВГС характерно длительное бессимптомное течение, 
часто выявляется на стадии хронического заболевания или его осложнений. Вирус обладает высокой изменчивостью, что затрудняет разработку вакцины, однако существуют современные 
противовирусные препараты, обеспечивающие высокую эффективность лечения.
        </p>
    )
}
export default GepatСOsnVyvody