function RsOsnVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="green"> 
         Основной вывод
        При жизни имелся рассеянный склероз, хроническое прогрессирующее течение (очаги демиелинизации в белом веществе головного 
        мозга (перивентрикулярно, в мозолистом теле, стволе мозга), глиоз в старых очагах демиелинизации, атрофия зрительных нервов), что подтверждается макро- и микро- 
        скопическими изменениями головного мозга и зрительных нервов, результатами иммуногистохимического исследования.
        Рассеянный склероз — это хроническое аутоиммунное заболевание центральной нервной системы неизвестной этиологии, характеризующееся множественными очагами демиелинизации 
        (утраты миелина), воспалением, глиозом и аксональным повреждением. Патологический процесс преимущественно затрагивает белое вещество головного и спинного мозга, что 
        приводит к нарушению проводимости нервных импульсов. Заболевание имеет волнообразное течение с периодами обострений и ремиссий, либо прогрессирующий характер. Рассеянный 
        склероз — одно из ведущих неврологических заболеваний у молодых людей, приводящее к инвалидизации и снижению качества жизни.
        Указанное заболевание в прямой причинной связи с наступлением смерти - не состоит.
        </p>
    )
}
export default RsOsnVyvody