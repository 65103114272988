function PeritonitLab(){
    return (
        <p id="MsoBodyTextIndent" className="green">
            Для острого перитонита характерно: при <u>гистологическом исследовании:</u> 1. Брюшина:
-	Выраженная инфильтрация лейкоцитами, преимущественно нейтрофилами.
-	Фибринозные наложения на серозной оболочке, состоящие из нитей фибрина, нейтрофилов, бактерий и клеточного детрита.
-	Отек ткани брюшины, нарушение структуры сосудов (гиперемия, стаз, иногда тромбоз мелких сосудов).
2. Серозные оболочки органов брюшной полости:
-	Некроз эпителиального слоя серозной оболочки из-за токсического действия бактерий и продуктов воспаления.
-	Наличие мелких кровоизлияний в подлежащие ткани.
3. Кишечник:
-	Отек слизистой оболочки с подслизистым кровоизлиянием.
-	Разрушение эпителия и эрозии в местах сильного воспаления.
-	Наличие инфильтрации нейтрофилами в стенке кишечника, преимущественно в подслизистом и мышечном слоях.
-	Возможные очаги некроза тканей при тяжелых формах.
4. Печень:
-	Жировая дистрофия гепатоцитов в связи с общей интоксикацией.
-	Очаговая инфильтрация лимфоцитами и макрофагами в портальных трактах.
-	Стаз крови в синусоидах и признаки гипоксии гепатоцитов.
5. Селезенка:
-	Увеличение размеров фолликулов за счет гиперплазии лимфоидной ткани.
-	Очаговые зоны некроза или скопления нейтрофилов.
6. Почки:
-	Острый тубулярный некроз вследствие токсического повреждения.
-	Отек стромы почек.
-	Гиперемия и стаз в клубочковых капиллярах.
7. Мезентериальные лимфатические узлы:
-	Гнойное воспаление: инфильтрация нейтрофилами, микробные скопления.
-	Очаги некроза в лимфоидной ткани.
8. Легкие (при сопутствующих осложнениях):
-	Очаговые участки ателектаза и воспаления.
-	Признаки отека легочной ткани и гиперемии капилляров.
Эти микроскопические изменения отражают системное воспаление, развившееся в ответ на острый перитонит, и влияние токсинов на органы и ткани. <u>Бактериологическое исследование: </u> 
посев экссудата на питательные среды для выявления патогенной микрофлоры (возможное обнаружение кишечной палочки, стрептококков, стафилококков, анаэробных бактерий). <u>Химическое 
    исследование экссудата:</u> анализ экссудата на содержание белка, клеточных элементов и ферментов (для дифференциации воспалительного и асептического характера). <u>
        Токсикологическое исследование:</u> исключение отравления, которое могло стать причиной вторичного перитонита.
        </p>
    )
}
export default PeritonitLab