function PancreatitKozha(){
    return (
        <p id="MsoBodyTextIndent" className="green"> При панкреатите возможны:
-	Умеренный или выраженный дефицит массы тела (похудание), особенно если панкреатит был хроническим и сопровождался нарушением пищеварения и усвоения пищи (мальабсорбция, потеря 
массы тела из-за хронической недостаточности ферментов).
-	Желтушность кожных покровов (иктеричность): иногда бывает в случае осложнений панкреатита (например, обструкция общего желчного протока камнями или опухолями), что может 
свидетельствовать о холестазе или механической желтухе.
-	Бледность или землистый оттенок кожи: может быть следствием хронической интоксикации, нарушений обмена веществ, анемии или недостаточности витаминов и микроэлементов, 
вызванной нарушением работы поджелудочной железы.
-	Сухость кожи: признак гиповитаминоза или малабсорбции, характерный для длительных заболеваний ЖКТ, включая панкреатит.
-	Петехии или экхимозы: наличие кровоизлияний в коже может быть связано с нарушением свертываемости крови, что бывает при тяжелых формах панкреатита с развитием системных 
осложнений, например, при остром панкреатите с многократными органными недостаточностями.
-	Отёки: особенно в нижних конечностях, что может быть связано с почечной недостаточностью или циркуляторными расстройствами, развившимися на фоне хронического панкреатита.
-	Увеличение живота (асцит): может быть вызвано перитонитом, хроническими воспалительными процессами или циррозом печени, что иногда встречается при длительном панкреатите, 
особенно алкогольного происхождения.
-   При приступах острого панкреатита человек часто принимает вынужденную позу с наклонённым вперёд туловищем, что может быть зафиксировано на этапе исследования (если осталась 
поза при смерти).
        </p>
    )
}
export default PancreatitKozha