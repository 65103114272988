function CovidVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="green">
            При жизни имелся COVID-19, вызванный вирусом SARS-CoV-2 (Диффузное альвеолярное повреждение в стадии пролиферации. Интерстициальная пневмония с лимфоцитарной 
                инфильтрацией и начальным фиброзом. Микротромбозы в капиллярах лёгочной ткани), на что указывают макро- и микро- скопические 
        изменения в легких, положительный результат на антитела (IgG и IgM одновременно) в крови к вышеуказанному вирусу, положительный ПЦР тест. 
        COVID-19 — это острое инфекционное заболевание, вызываемое коронавирусом SARS-CoV-2, характеризующееся преимущественным поражением дыхательной системы, системной 
        воспалительной реакцией и разнообразными клиническими проявлениями — от бессимптомного носительства до тяжёлой вирусной пневмонии, острого респираторного 
        дистресс-синдрома (ОРДС) и полиорганной недостаточности. Передача инфекции осуществляется преимущественно воздушно-капельным и аэрозольным путями, реже через контакт с 
        заражёнными поверхностями. Заболевание имеет широкий спектр симптомов, включая лихорадку, кашель, одышку, слабость, а в некоторых случаях поражение сердечно-сосудистой, 
        нервной, пищеварительной и других систем. Основными мерами профилактики являются вакцинация, использование средств индивидуальной защиты и ограничение контактов в 
        эпидемический период. Указанное заболевание в причинной связи с наступлением смерти - не состоит.
        </p>
    )
}
export default CovidVyvody