function SaharDiab2Kozha(){
    return (
        <p id="MsoBodyTextIndent" className="green">При СД 2 типа на нижних конечностях могут быть трофические язвы, особенно на стопах, вызванные сочетанием периферической 
        нейропатии и макроангиопатии. Кожа может быть истончённой и сухой, плохо заживающей. Часто наблюдается гиперкератоз и грибковые инфекции, особенно в области ногтей 
        и межпальцевых промежутков. 
        От трупа может исходить гнилостный или неприятный запах связанный с трофическими язвами, характерными для диабетической ангиопатии и нейропатии.
При инфицировании язвенных поверхностей (особенно при присоединении анаэробной инфекции) может возникать резкий зловонный запах, особенно при влажной гангрене с некрозом тканей.
При хронической почечной недостаточности (диабетической нефропатии) кожа может источать запах аммиака или "мочевого" оттенка.
        </p>
    )
}
export default SaharDiab2Kozha