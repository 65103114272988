function YazvaKozha(){
    return (
        <p id="MsoBodyTextIndent" className="green">При язвенной болезни желудка и двенадцатиперстной кишки возможны:
- Исхудание или снижение массы тела, если язвенная болезнь сопровождалась хронической болью, снижением аппетита или повторяющимися рвотами.
- Бледность кожных покровов и слизистых оболочек, указывающая на хроническую анемию, вызванную скрытыми желудочно-кишечными кровотечениями.
- Желтоватый оттенок кожи, если болезнь сопровождалась нарушением функций печени или желчных путей.
- Мраморность кожи, характерная для хронической недостаточности кровообращения или анемии.
- Признаки хронического обезвоживания (сухость кожи и слизистых оболочек, уменьшение тургора кожи).
- Если перед смертью произошло массивное кровотечение, могут быть видны следы рвоты (например, кровь на одежде или вокруг рта).
- Защитная поза (например, склонность к согнутому положению туловища или напряжение мышц передней брюшной стенки при жизни, что может быть зафиксировано после смерти).
- Гипертрофия мышц живота, если больной длительное время удерживал напряжение мышц в области эпигастрия.
- Язвенная болезнь часто связана с никотиновой зависимостью: следы от курения на пальцах (желтоватые пятна на коже) и зубах (налет).
- Признаки злоупотребления алкоголем (сосудистые звездочки, покраснение кожи лица, "алкогольный" ринит), так как алкоголь является провоцирующим фактором для обострения язвенной 
болезни.
- Налет на языке, особенно в задней трети, может свидетельствовать о расстройствах пищеварения.
- Повреждения эмали зубов вследствие частой рвоты.
- Следы послеоперационных рубцов, если ранее проводились хирургические вмешательства по поводу язвенной болезни (например, ваготомия, резекция желудка).
        </p>
    )
}
export default YazvaKozha