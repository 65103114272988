function HolecistitKozha(){
    return (
        <p id="MsoBodyTextIndent" className="green">При холецистите и холелитиазе (желчнокаменной болезни) возможны:
- На коже и слизистых оболочках может быть выраженная желтуха, особенно при наличии обструкции желчных путей камнями. Желтуха может проявляться в виде желтого окрашивания кожи, 
слизистых оболочек, склер.
- В случае хронического холецистита и нарушений в оттоке желчи возможен общий или локализованный отёк, особенно в области живота.
- Ожирение — один из факторов риска для развития холелитиаза. Могут быть выявлены признаки ожирения, особенно в области живота.
- Иногда при обострении холецистита возможны локальные покраснения в области правого подреберья из-за воспаления, особенно если это сопровождается гнойным процессом.
        </p>
    )
}
export default HolecistitKozha