function GbOsnVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="green"> 
         При жизни имелась гипертоническая болезнь 3 стадии с преимущественным поражением сердца, на что указывают макро- и микро- скопические изменения внутренних органов 
         (сердца, сосудов, почек и головного мозга). 
        Смерть наступила вследствие гипертонической болезни 3 стадии с преимущественным поражением сердца, сопровождавшейся хронической сердечной недостаточностью и осложнившейся
        полиорганной недостаточностью, что подтверждается хроническим общим венозным полнокровием и признаками полиорганной недостаточности из-за длительной гипоксии и 
        кровенаполнения (отек и водянка головного мозга, бурая индурация легких, мускатная печень, цианотическая индурация почек и селезенки), двусторонним гидротораксом 
        (по 1800 мл), гидроперикардом (200 мл), асцитом (4500 мл) и анасаркой (тяжёлой, генерализованной формой отёка с отёком подкожной клетчатки по всему телу).
Таким образом, между имевшейся гипертонической болезнью 3 стадии с преимущественным поражением сердца и наступлением смерти имеется прямая причинная связь.
        Гипертоническая болезнь (гипертензия) — это хроническое заболевание сердечно-сосудистой системы, характеризующееся устойчивым повышением артериального давления (АД) выше 
        целевых значений (≥140/90 мм рт. ст.), не связанным с другими причинами вторичной гипертензии. Заболевание обусловлено нарушением регуляции сосудистого тонуса, активности 
        ренин-ангиотензин-альдостероновой системы, водно-электролитного баланса, а также влиянием генетических и внешних факторов, таких как стресс, ожирение, малоподвижный образ 
        жизни и избыточное потребление соли. Основные клинические проявления включают головные боли, шум в ушах, головокружение, нарушение зрения, но в начальных стадиях болезнь 
        может протекать бессимптомно. При отсутствии лечения гипертоническая болезнь приводит к поражению органов-мишеней (сердца, мозга, почек, сосудов) и увеличивает риск 
        осложнений, таких как инсульт, инфаркт миокарда и сердечная недостаточность. Лечение направлено на нормализацию АД, изменение образа жизни и применение антигипертензивных 
        препаратов. 
        </p>
    )
}
export default GbOsnVyvody