function HronPieloOsnVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="green">
            Основной вывод
            При жизни имелся хронический пиелонефрит, двусторонний, в стадии склерозирования (cморщенные почки, бугристая поверхность, 
        умеренная деформация чашечно-лоханочной системы, атрофия канальцев, лимфоцитарная инфильтрация, фиброз интерстиция, склероз клубочков), на что указывают макро- и микро- 
        скопические изменения в почках; выделение микрофлоры (Klebsiella spp.) из чашечно-лоханочной системы почек и мочевого пузыря при бактериологическом исследовании.
        Хронический пиелонефрит — это хроническое неспецифическое инфекционно-воспалительное заболевание почек, преимущественно поражающее интерстициальную ткань, канальцы, а в 
        дальнейшем сосуды и клубочки. Оно характеризуется волнообразным течением с чередованием периодов обострения и ремиссии, постепенной прогрессирующей утратой функциональной 
        способности почек, рубцово-склеротическими изменениями паренхимы и развитием хронической почечной недостаточности в поздних стадиях. Причинами хронического пиелонефрита 
        чаще всего являются инфекции мочевых путей, нарушение оттока мочи (обструкция, рефлюксы) и общие факторы риска, такие как снижение иммунитета, сахарный диабет, 
        артериальная гипертензия и другие состояния, способствующие поддержанию хронического воспаления. Указанное заболевание в причинной связи с наступлением смерти - не состоит.
        </p>
    )
}
export default HronPieloOsnVyvody