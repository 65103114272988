function CovidKozha(){
    return (
        <p id="MsoBodyTextIndent" className="green"> При COVID-19 возможны признаки гипоксии, системного воспаления, коагулопатии, а также изменения, связанные с дыхательной 
недостаточностью и пролонгированной медицинской помощью:
1. Общие признаки гипоксии: при тяжелых нарушениях дыхания и недостатке кислорода - cинюшная окраска кожи, особенно на кончиках пальцев, губах, ушах, и в области носогубного 
треугольника; бледность кожи встречается при длительной гипоксии или шоковых состояниях.
2. Изменения, связанные с тромбоэмболией: трупные пятна могут быть интенсивно синюшного или фиолетового цвета, что связано с нарушениями микроциркуляции и тромбообразованием,
локализация трупных пятен обычно стандартная, но их выраженность может быть повышена; отечность конечностей, особенно нижних, как следствие тромбоза глубоких вен.
3. Признаки нарушения микроциркуляции: точечные кровоизлияния на коже, особенно в области грудной клетки, шеи, лица (петехии), связаны с коагулопатией, характерной для COVID-19; 
мелкие кровоизлияния в коже, часто наблюдаемые при тяжелых формах с развитием ДВС-синдрома (геморрагическая сыпь).
4. Изменения, связанные с дыхательной недостаточностью: при острой дыхательной недостаточности грудная клетка может быть зафиксирована в положении инспираторной гиперэкскурсии;
если пациент проходил искусственную вентиляцию легких (ИВЛ) - назальные катетеры, интубационная трубка или следы трахеостомии; сухость и трещины на губах (последствие длительной 
кислородной терапии).
5. Специфические кожные изменения: покраснение, отек, или пурпурные пятна на пальцах рук и ног, напоминающие обморожение ("COVID-пальцы"), обычно связано с микроангиопатией; 
некрозы кожи могут наблюдаться у пациентов с длительным нахождением на ИВЛ или при тромбозе крупных сосудов.
6. Признаки истощения организма потеря массы тела (выраженное исхудание при затяжном течении заболевания или синдроме длительного постельного режима).
7. Возможные последствия госпитализации: пролежни, особенно в области крестца, лопаток, затылка и пяток, если пациент длительно находился в обездвиженном состоянии; на коже могут 
быть видны следы от внутривенных катетеров, инъекций или центральных венозных катетеров.
8. Признаки системного воспаления: отек мягких тканей лица и шеи (может быть результатом как инфекционного процесса, так и осложнений (например, медиастинита)).
        </p>
    )
}
export default CovidKozha