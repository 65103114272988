function TubercOsnDiagnoz(){
    return (
        <p id="MsoBodyTextIndent" className="green">
        Основное заболевание: туберкулез легких, фиброзно-кавернозная форма, хроническое течение, фаза обострения (двусторонние каверны в верхних долях легких, множественные 
        туберкулезные гранулемы с казеозным некрозом; обширный фиброз легочной ткани).
Осложнения:
1.  Кровоизлияние в каверну правого легкого с развитием геморрагического шока.
2.	Туберкулезный плеврит, спаечный процесс.	
3.	Амилоидоз почек.
        </p>
    )
}
export default TubercOsnDiagnoz