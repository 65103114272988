function YazvaKishki(){
    return (
        <p id="MsoBodyTextIndent" className="green">
       При язвенной болезни желудка и двенадцатиперстной кишки:
       1. В пищеводе: при гастроэзофагеальном рефлюксе, сопровождающем язвенную болезнь, возможно обнаружение изменений в нижней трети пищевода, таких как эрозии или язвы.
       2. В желудке: наличие язвенных дефектов в слизистой оболочке (чаще на малой кривизне желудка в области привратника и тела желудка), обычно округлой или овальной формы. 
       Размеры язв варьируют от нескольких миллиметров до нескольких сантиметров.
Края язвы могут быть валикообразно приподняты и уплотнены, при сильной выраженности этого признака - каллезная (омозолелая) язва. 
Дно язвы чаще всего неровное, грязно-бурого цвета за счет солянокислого гематина, обычно покрыто фибринозным налетом.
При хронической язве может наблюдаться плотное дно с признаками фиброза и склероза; край язвы, обращенный к пищеводу, подрыт, а край обращенный к привратнику - пологий 
(террасовидный - из-за слоев стенки желудка). Рельеф слизистой оболочки желудка обычно изменен - складчатость может быть сглаженой или складки могут сходиться к язве. 
Возможны признаки пенетрации язвы в соседние органы, например, в поджелудочную железу. При перфорации язвы — наличие сквозного отверстия в стенке желудка и следы перитонита 
(наличие гноя или фибринозного экссудата в брюшной полости).
При разрушении крупного сосуда в дне язвы, например, ветвей левой желудочной или правой желудочной артерий, виден дефект сосудистой стенки с возможными следами свежего тромба. 
При продолжительном истечении крови в желудке обнаруживаются остатки пищи, смешанные с темной кровью ("кофейная гуща"); на стенках желудка могут быть следы свернувшейся крови или 
густой, гематиновый налет; также отмечаются общий отек, гиперемия и эрозии слизистой оболочки желудка.
3.	В двенадцатиперстной кишке: язвенные дефекты аналогичного характера, чаще локализующиеся в луковице двенадцатиперстной кишки (на передней или задней стенке, если напротив 
друг-друга - "целующиеся" язвы). Возможна деформация двенадцатиперстной кишки из-за рубцевания при заживлении язвы. При длительном течении болезни появляются признаки стеноза 
выходного отдела желудка или двенадцатиперстной кишки.
При повреждении крупного сосуда, такого как ветви гастродуоденальной артерии - виден разрыв (дефект) сосуда в дне язвы с возможными следами свежего тромба; наличие свернувшейся 
крови или гематиновых масс в просвете кишки; при массивном кровотечении — признаки мелены в дистальных отделах кишки; cлизистая кишки гиперемирована, с участками поверхностных 
эрозий или кровоизлияний; возможен общий отек и воспаление в зоне язвы.
</p>
    )
}
export default YazvaKishki