function PancreatitPodj(){
    return (
        <>
        <p id="MsoBodyTextIndent" className="green">При остром панкреатите поджелудочная железа увеличена в объеме из-за отека, масса может достигать 300 г (при норме 80 - 120 г). 
            Капсула натянута и тугая.
Ткань железы становится ярко-красной или багровой из-за полнокровия сосудов, характерного для острого воспаления. На поверхности и в ткани железы видны многочисленные 
кровоизлияния, которые могут варьироваться от мелких точечных до обширных. Наблюдаются желтовато-белые пятна (участки жирового некроза - стеатонекроз) на фоне воспаленной ткани, 
возникающие в результате воздействия ферментов поджелудочной железы на окружающую жировую ткань. При серозно-геморрагическом панкреатите выявляется пропитывание кровью ткани 
железы. Возможны некротические изменения ткани железы (участки некроза выглядят как серо-коричневые, дряблые зоны, часто окруженные геморрагическим ободком). Ткань железы может 
выглядеть размягченной или разрушенной вследствие действия собственных ферментов (аутолиз).
На поверхности железы и в брюшной полости может быть серозно-геморрагический или гнойный экссудат (серозный перетонит обязательно сопровождает острый панкреатит - это его 
проявление, а гнойный перетонит - это уже его осложнение), связанный с распространением воспаления. В результате некротического процесса и воспаления могут образовываться полости, 
заполненные жидкостью и ферментами (псевдокисты), сама железа может приобрести грязно-серый, бурый или черный цвет (гнойно-некротическая форма панкреатита). Эти изменения зависят 
от тяжести острого панкреатита, который может варьироваться от легкой формы до тяжелой, с тотальным некрозом железы (панкреонекроз) и вовлечением окружающих тканей.
        </p>
        <p id="MsoBodyTextIndent" className="green"> При хроническом панкреатите, поджелудочная железа часто уменьшена в объеме из-за атрофии ткани и замещения ее фиброзной 
            тканью. Железа плотная на ощупь, ее структура грубая, с участками фиброзных тяжей, которые разделяют ткань железы на узлы. Железа приобретает серо-желтый или 
            серо-белый цвет. Видны рубцовые изменения, местами ткань может быть неравномерно утолщена. На разрезе могут быть обнаружены плотные участки кальцификатов (отложения 
            солей кальция), которые часто располагаются в протоках железы. Протоки поджелудочной железы расширены, в них может содержаться мутная жидкость или плотные, каменистые 
            включения (панкреатические камни). В некоторых случаях вместо нормальной ткани поджелудочной железы наблюдается жировое перерождение (липоматоз). В ткани железы или 
            рядом с ней могут быть обнаружены полости, заполненные жидкостью и окруженные фиброзной стенкой (псевдокисты). Железа часто спаяна с соседними органами (желудком, 
            двенадцатиперстной кишкой) из-за хронического воспалительного процесса.
Эти изменения характерны для хронического панкреатита и связаны с длительным воспалением, повторяющимися обострениями и прогрессирующим разрушением железистой ткани.
        </p>
        </>
    )
}
export default PancreatitPodj