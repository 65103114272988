function TubercVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="green">При жизни имелся фиброзно-очаговый туберкулез верхней доли левого легкого в стадии рубцевания, туберкулезные гранулемы с 
        казеозным некрозом в бронхопульмональных лимфатических узлах, на что указывают макро- и микро- скопические изменения в этих органах, наличие в тканях этих органов (при
        бактериологическом исследовании) микобактерий туберкулеза. 
        Туберкулёз — это хроническое инфекционное заболевание, вызываемое микобактериями туберкулёза (Mycobacterium tuberculosis complex), которое характеризуется образованием 
        специфических гранулём (туберкулов) в поражённых тканях и органах, склонностью к прогрессирующему течению, разрушению тканей, а также развитием интоксикации и 
        иммунопатологических реакций. Основным источником инфекции является больной человек или животное, передача возбудителя происходит преимущественно воздушно-капельным путём. 
        Чаще всего поражаются лёгкие, но процесс может затрагивать и другие органы: лимфатические узлы, кости, почки, кожу и мозговые оболочки.
        Указанное заболевание в причинной связи с наступлением смерти - не состоит.
        </p>
    )
}
export default TubercVyvody