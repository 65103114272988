function PnevmoniaInterstOsnVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="green">
             Основной вывод
            При жизни имелась интерстициальная пневмония, хроническое течение (дифузный интерстициальный фиброз лёгких; утолщение альвеолярных перегородок с лимфо-макрофагальной 
            инфильтрацией; очаговая гиперплазия пневмоцитов II типа), на что указывают 
            макро- и микро- скопические изменения в легких; отрицательные результаты посевов на бактерии, грибы и вирусы; отсутствие патогенных микроорганизмов при ПЦР-диагностике.
            Интерстициальная пневмония — это группа диффузных воспалительных заболеваний лёгких, характеризующихся преимущественным поражением интерстициальной ткани 
            (соединительнотканного каркаса) лёгких, включающей альвеолярные перегородки, сосудистое русло и прилегающие структуры.
Патологический процесс включает: утолщение альвеолярных перегородок за счёт воспалительной инфильтрации (лимфоциты, макрофаги, плазматические клетки); повреждение эпителия 
альвеол; развитие фиброза и утрату нормальной архитектоники лёгочной ткани. Клинически интерстициальная пневмония проявляется прогрессирующей одышкой, кашлем (обычно сухим), 
снижением насыщения крови кислородом и нарушением функции лёгких по рестриктивному типу. Интерстициальная пневмония может быть идиопатической (например, идиопатический лёгочный 
фиброз) или развиваться как осложнение системных заболеваний соединительной ткани, инфекции, воздействия токсинов или радиации.
            Указанное заболевание в причинной связи с наступлением смерти - не состоит.
        </p>
    )
}
export default PnevmoniaInterstOsnVyvody