function KrovopodtekVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="blue"> - Кровоподтек в левой щечной области. 
<br></br>Кровоподтек носит травматический характер и образовался в результате взаимодействия (каким могло быть удар, давление, 
        сдавление, растяжение) с твёрдым тупым предметом (или предметами) с ограниченной травмирующей поверхностью, на что указывают вид повреждения (кровоподтек), его 
        локализация и форма, а также размерные характеристики. Местом приложения травмирующей силы при образовании 
        указанного повреждения явилась левая щечная область, что подтверждается локализацией повреждения в указанной анатомической области. 
        Судить о преимущественном направлении действия травмирующей силы, причинившей данное повреждение - не представляется возможным, так как конкретный вид травматического 
        воздействия причинивший его - не установлен (альтернативный вариант: преимущественное направление действия травмирующей силы, причинившей данное повреждение, было: 
        слева - направо, что подтверждается локализацией места приложения травмирующей силы). 
        Синюшно-фиолетовый цвет в центре и наличие зеленой окраски по периферии у кровоподтека, позволяет полагать об ориентировочной давности его образования в интервал 
        времени от 3 до 8 суток до момента судебно-медицинского освидетельствования гражданина N 22.02.2017 года. Имеющийся у N кровоподтек не повлек за собой 
        кратковременного расстройства здоровья или незначительной стойкой утраты общей трудоспособности, и согласно п. 9. «Медицинских критериев определения степени 
        тяжести вреда, причиненного здоровью человека» утвержденных приказом МЗиСР от 24 апреля 2008 года № 194н, расценивается как повреждение, не причинившие вред 
        здоровью человека.
        </p>    
    )
}
export default KrovopodtekVyvody