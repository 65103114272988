function HolecistitPechen(){
    return (
        <> 
        <p id="MsoBodyTextIndent" className="green">
        При остром холецистите желчный пузырь может быть увеличен, напряжен, стенки его утолщены, могут быть гиперемированными (покрасневшими) из-за воспаления или покрыты фибринозными 
        наложениями. В случае осложнения острым гангренозным холециститом стенки желчного пузыря могут иметь участки некроза (чёрного или серо-зелёного цвета). В полости пузыря 
        может содержаться мутная желчь, гной или геморрагический экссудат. В случае осложнённого течения могут быть обнаружены отверстия в стенке желчного пузыря (перфорация) и 
        признаки перитонита. На поверхности печени могут наблюдаться воспаление в прилегающих участках: гиперемия, участки фибринозных наложений или абсцессы, связанные с 
        осложнением холецистита (холангит).
        </p> 
        <p id="MsoBodyTextIndent" className="green">
        При хроническом холецистите желчный пузырь может быть уменьшен (сморщен) или, реже, увеличен в объеме. Стенки желчного пузыря утолщены, могут быть фиброзно изменены, 
        плотные на ощупь, тускло-серые или желтовато-бурые, иногда с участками гиперпигментации. Слизистая оболочка может быть атрофичной, складчатой или с участками гиперплазии, 
        на ней возможно наличие грануляций или полипов. В запущенных случаях может быть обнаружен «фарфоровый» желчный пузырь, обусловленный кальцификацией стенок. В просвете 
        желчного пузыря часто обнаруживаются камни (холелитиаз), от мелких до крупных, различной формы и структуры. В полости желчного пузыря может находиться густая, вязкая, 
        тёмная желчь («желчь, как смола»). В печени могут быть видны застойные изменения в виде желтушного окрашивания тканей (холестаз). Возможны участки фиброзных изменений в 
        правой доле печени, особенно в зоне соприкосновения с желчным пузырем, и признаки хронического холангита (расширение внутрипечёночных желчных протоков, их утолщение или 
        воспалительные изменения).
        </p>
        <p id="MsoBodyTextIndent" className="green">
        При желчнокаменной болезни (холелитиазе) желчный пузырь увеличен или уменьшен в зависимости от стадии и течения болезни. При хроническом холелитиазе желчный пузырь может 
        быть деформирован, с утолщёнными стенками. Содержимое пузыря: наличие камней (конкрементов) различной формы, размера, количества и состава (холестериновые, пигментные, 
        смешанные, известковые); густая, вязкая, иногда мутная желчь с примесью хлопьев и осадка. Происходит утолщение и склероз стенки за счёт фиброзных изменений; возможны язвы, 
        кровоизлияния или некроз слизистой оболочки; наличие признаков воспаления, таких как гиперемия, отёк или фибринозные наложения при осложнённом холелитиазе. При длительном 
        воспалении возможно сращение желчного пузыря с соседними органами (печенью, желудком, двенадцатиперстной кишкой). Камни могут проникать через дефекты в стенке желчного 
        пузыря в брюшную полость, что сопровождается перитонитом.
Камни могут обнаруживаться в общем желчном протоке (холедох) или внутрипечёночных протоках. При полной обструкции наблюдаются расширенные выше обструкции желчные протоки, 
заполненные густой желчью; отмечается утолщение и воспаление стенок желчных протоков (холангит). В печени отмечается желчный застой (холестаз): желтоватый цвет печени на разрезе; 
расширение внутрипечёночных желчных протоков, заполненных желчью. При остром застое желчи происходит увеличение размеров печени (отёк и гиперемия).
При длительном холестазе наблюдаются фиброзные изменения в портальных трактах (фиброз или билиарный цирроз).
При желчнокаменной болезни камни (конкременты) делятся на несколько типов в зависимости от их состава:
1. Холестериновые камни преимущественно состоят из холестерина (70% и более). Желтоватого, беловатого или зеленоватого цвета, круглой или овальной формы, поверхность гладкая,
размеры: от мелких до крупных (иногда достигают нескольких сантиметров). Возникают при нарушении обмена холестерина и снижении его растворимости в желчи.
Предрасполагающие факторы: ожирение, сахарный диабет, избыточное потребление жиров, малоподвижный образ жизни. Чаще встречаются у пациентов с метаболическими нарушениями.
Обычно одиночные или немногочисленные.
2. Пигментные камни содержат билирубин и его производные (билирубинаты), иногда с примесью кальция. Возникают при повышенной концентрации билирубина в желчи, связанной с 
нарушением оттока желчи или гемолизом. Чаще встречаются у людей с хроническими инфекциями или заболеваниями крови.
Подвиды: черные пигментные камни (черного или темно-коричневого цвета, с гладкой или слегка шероховатой поверхностью, состоят из полимеризованного билирубина, кальция, возникают 
при гемолитических состояниях (например, при анемиях)); коричневые пигментные камни (светло-коричневого цвета, с неровной, пористой поверхностью, состоят из неполностью 
конъюгированного билирубина, кальция, остатков липидов, формируются при инфекциях желчевыводящих путей и ферментативной активности бактерий). 
3. Смешанные камни состоят из холестерина, билирубина, кальция и других компонентов. Разнообразного (желтый, серый, коричневый) цвета, неправильной формы, с множественными слоями,
имеют слоистую, часто шероховатую поверхность, размер вариабельный. Начинаются как холестериновые камни, на которые откладываются билирубин и соли кальция. Часто формируются на 
фоне воспаления желчного пузыря (холецистита). Это наиболее распространенный тип камней. Обычно множественные и разноразмерные.
4. Известковые камни (кальциевые) состоят преимущественно из солей кальция. Белого или серого цвета,  округлой или неправильной формы, с гладкой или шероховатой поверхностью, 
небольшого размера. Возникают при высоком содержании кальция в желчи или воспалении стенки желчного пузыря. Встречаются реже, чем другие виды. Часто связаны с кальцификацией 
стенки желчного пузыря (так называемый «фарфоровый желчный пузырь»). Могут сопровождаться хроническим воспалением.
По локализации камней можно выделить несколько вариантов течения холелитиаза с характерными особенностями:
-	Камни в желчном пузыре (холецистолитиаз) (камни обнаруживаются в полости желчного пузыря. В зависимости от количества они могут занимать весь просвет или быть свободно 
лежащими).
-	Камни в желчных протоках (холедохолитиаз) (камни располагаются в общем желчном протоке, могут вызывать обструкцию и застой желчи).
-	Камни в внутрипеченочных протоках (внутрипеченочный холелитиаз) (камни обнаруживаются в мелких протоках печени, часто мелкие, с участками холестаза вокруг).
По размеру камни подразделяются на:
-	Микролиты (мелкие камни размером до 5 мм. Часто встречаются при внутрипеченочном холелитиазе).
-	Макролиты (крупные камни размером от 5 мм до нескольких сантиметров).
-	Гигантские камни (камни, достигающие 5–10 см, могут деформировать желчный пузырь).
По степени закупорки протоков:
-	Неосложнённый холелитиаз (камни не перекрывают протоки, отсутствуют признаки застоя желчи).
-	Обструктивный холелитиаз (камни вызывают закупорку желчных протоков с развитием обтурационной желтухи или острого холецистита).
-	Мигрирующие камни (камни перемещаются из желчного пузыря в общий желчный проток, приводя к периодическим обструкциям).
По осложнениям:
-	Воспалительный холелитиаз (присутствуют признаки хронического или острого холецистита; желчный пузырь может быть утолщённым, гиперемированным, с фибринозными наложениями).
-	Перфоративный холелитиаз (разрыв стенки желчного пузыря с выходом камней и желчи в брюшную полость).
-	Гангренозный холелитиаз (некротические изменения стенки желчного пузыря с гнойным содержимым).
-	Фистуло-формирующий холелитиаз (образование свищей между желчным пузырём и кишечником или другими органами).
-	Билиарный цирроз (длительный холестаз приводит к структурным изменениям в печени): на ранних стадиях печень может быть увеличенной из-за застоя желчи и воспалительного отека;
на поздних стадиях печень уменьшается в размере из-за атрофии и фиброза (цирротическое сморщивание);
печень покрыта множественными мелкими и крупными узлами (характерная особенность цирроза);
узлы окружены плотной соединительной тканью;
цвет поверхности имеет желтоватый или зеленоватый оттенок из-за застоя желчи и пигментации билирубином;
при тяжелых стадиях поверхность становится серо-коричневой из-за фиброза;
отмечается расширение вен на поверхности печени (портальная гипертензия);
печень плотная, твердая на ощупь из-за выраженного фиброза и воспалительных изменений;
на разрезе видна мелко- или крупноузловая структура ткани;
паренхима может быть неоднородной, с желтоватыми или зеленоватыми участками, свидетельствующими о застоявшейся желчи;
желчные протоки расширены, иногда заметны следы закупорки желчных протоков камнями; протоки могут содержать густую, темно-зеленую желчь;
видны грубые соединительнотканные септы, разделяющие узлы регенерации (фиброз).
        </p>
        </>
    )
}
export default HolecistitPechen