function YazvaPodj(){
    return (
        <p id="MsoBodyTextIndent" className="green">
            Для язвенной болезни желудка и двенадцатиперстной кишки характерны следующие изменения в поджелудочной железе:
            1. Изменения поджелудочной железы при неосложненной язве могут быть опосредованными и связаны с нарушением кровообращения в прилегающих сосудах из-за воспалительных 
            изменений, с рефлекторными процессами (например, спазм или дисфункция протоков): обычно железа сохраняет свои размеры, но может быть слегка отечной или уплотненной; в 
            некоторых случаях выявляются участки жировой инфильтрации.
        2. Изменения при пенетрации язвы в поджелудочную железу:
        Чаще всего язва луковицы двенадцатиперстной кишки (задняя стенка) пенетрирует в головку поджелудочной железы.
        Язва на задней стенке желудка может пенетрировать в тело поджелудочной железы.
        Очаг пенетрации: в ткани железы виден участок некроза, обычно темно-серого или грязно-желтого цвета, окруженный зоной воспалительной инфильтрации; края язвы 
        инфильтрированы, покрыты фибрином и гнойным налетом.
        Воспалительные изменения представлены гнойными очагами или абсцессами вокруг зоны пенетрации, отмечаются гиперемия и отек ткани железы, фиброз и плотные сращения железы с 
        прилегающими структурами (наличие грубых сращений с желудком или двенадцатиперстной кишкой), фибринозные налеты на поверхности железы.
        3. Изменения при осложнениях пенетрации:
        Острый панкреатит (очаги жирового некроза в ткани поджелудочной железы (мелкие, бело-желтые); отек и полнокровие ткани железы; возможны кровоизлияния, особенно при 
        повреждении сосудов язвой).
        Гнойное расплавление (образование абсцессов в зоне пенетрации; железа может быть увеличена, дряблой, с гнойным содержимым).
        Фиброз и хронический воспалительный процесс (уплотнение тканей поджелудочной железы; грубые рубцовые изменения в зоне пенетрации).
        4. Дополнительные изменения: при массивном кровотечении из язвы возможно пропитывание ткани поджелудочной железы кровью (геморрагическая инфильтрация).
        При разлитом перитоните гнойно-фибринозный налет может распространяться на поджелудочную железу и окружающие ткани.
        </p>
    )
}
export default YazvaPodj