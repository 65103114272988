function HoblLab(){
    return (
        <p id="MsoBodyTextIndent" className="green">
            Для хронической обструктивной болезни легких (ХОБЛ) характерно: при <u>гистологическом исследовании:</u> 1. Легкие:
1.1.	Хроническое воспаление стенок бронхов и бронхиол (инфильтрация стенок лимфоцитами, макрофагами и плазматическими клетками; утолщение базальной мембраны из-за фиброза; 
гиперплазия бокаловидных клеток и слизистых желез, что приводит к увеличению секреции слизи).
1.2.	Деструкция альвеолярных перегородок (разрыв межальвеолярных перегородок с образованием крупных воздушных пространств (панацинарная и центрилобулярная эмфизема); уменьшение 
капиллярной сети в альвеолах).
1.3.	Фиброз мелких дыхательных путей (утолщение стенок бронхиол за счет склерозирования; сужение просвета дыхательных путей).
1.4.	Скопление макрофагов с угольным пигментом (в альвеолах и межуточной ткани обнаруживаются "пылевые клетки", нагруженные углеродом и продуктами распада ткани).
1.5.	Сосудистые изменения (гипертрофия и утолщение стенок мелких артерий легкого (артериол), характерные для легочной гипертензии).
2. Сердце:
2.1.	Правожелудочковая гипертрофия (увеличение объема миоцитов в правом желудочке; очаги субэндокардиального фиброза из-за хронической перегрузки давлением).
3. Печень:
3.1.	Застой крови в синусоидах (расширение синусоид, заполненных кровью, при хронической сердечной недостаточности).
3.2.	Центролобулярный фиброз (в центральных отделах долек наблюдаются признаки атрофии гепатоцитов и развитие соединительной ткани).
4. Общие изменения:
4.1.	Гипоксия тканей (гипоксические изменения клеток органов: вакуолизация цитоплазмы, пикноз ядер, набухание митохондрий; усиление накопления липидов в клетках (жировая 
дистрофия)).
Эти микроскопические изменения подтверждают клинический диагноз ХОБЛ и помогают установить степень ее влияния на организм. <u>Бактериологическое исследование (исключить или 
    подтвердить сопутствующую инфекцию):</u> посев содержимого бронхов для выявления хронической инфекции (например, Haemophilus influenzae, Streptococcus 
    pneumoniae). <u>Иммуногистохимическое исследование (подтверждение воспалительного характера изменений):</u> выявление маркеров хронического воспаления, таких как CD68 для 
    макрофагов, CD3 для Т-лимфоцитов.
        </p>
    )
}
export default HoblLab