function SepsisOsnDiagnoz(){
    return (
        <p id="MsoBodyTextIndent" className="green">
             Бактериальный эндокардит аортального и митрального клапанов с образованием пристеночных тромботических наложений, деструкцией клапанных створок, перфорацией 
             митрального клапана, распространением на эндокард левого желудочка (при бактериологическом исследовании установлен Corynebacterium).
             Осложнения: 
             Септикопиемия: множественные септические метастазы в головноой мозг с развитием множественных инфарктов мозга и гнойного менингита, в легкие и в селезенку с 
             множественными инфарктами и абсцессами в них. Септический шок.
            </p>
    )
}
export default SepsisOsnDiagnoz