function HolecistitBrushina(){
    return (
        <>
        <p id="MsoBodyTextIndent" className="green">
        При остром холецистите в брюшной полости вокруг желчного пузыря могут быть выявлены плотные спайки с соседними органами (печенью, желудком, кишечником). Жировая ткань 
        вокруг желчного пузыря может быть инфильтрирована (область гиперемии или пропитки серозным/гнойным экссудатом). Локальная гиперемия и отёк брюшины в области правого 
        подреберья. В случаях перфорации желчного пузыря или значительного воспаления — наличие мутной жидкости, гноя или фибринозных наложений на стенках брюшной полости.
</p>
        <p id="MsoBodyTextIndent" className="green">
        При хроническом холецистите желчный пузырь может быть покрыт спайками с соседними органами (печенью, желудком, кишечником), что указывает на хроническое воспаление. 
        Возможно уплотнение жировой клетчатки вокруг желчного пузыря и развитие хронического воспалительного инфильтрата; обнаружение серозных наложений на брюшине в области 
        правого подреберья, вызванных воспалительными процессами.

</p>
        <p id="MsoBodyTextIndent" className="green">
        При желчнокаменной болезни (холелитиазе) возможна дилатация двенадцатиперстной кишки (при обструкции общего желчного протока). Также возможны проявления осложнений 
        холелитиаза: желчный перитонит (выход желчи и камней в брюшную полость при перфорации стенки желчного пузыря); билиодигестивный свищ (сообщение между желчным пузырём и 
        кишечником (например, с двенадцатиперстной кишкой)); обтурационная желтуха (выраженное пожелтение внутренних органов и тканей).
</p>
</>
    )
}
export default HolecistitBrushina