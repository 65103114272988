function SaharDiab1Kozha(){
    return (
        <p id="MsoBodyTextIndent" className="green">При СД 1 типа возможны участки истончения кожи, плохо заживающие раны или язвы на нижних конечностях, вызванные 
        диабетической микроангиопатией и нейропатией. Подкожно-жировая клетчатка часто истончена, особенно у худых лиц. Макроскопические изменения кожи и мягких тканей 
        менее выражены чем при СД 2 типа.
От трупа может исходить ацетоновый (фруктовый, "яблочный") или сладковатый (из-за изменений в составе пота) запах, если человек умер в состоянии диабетического кетоацидоза.
Гнилостный запах развивается при наличии язвенных поражений кожи, диабетической стопы или некротических процессов (может быть выражен сильнее, если к ранам присоединилась 
анаэробная инфекция).
        </p>
    )
}
export default SaharDiab1Kozha