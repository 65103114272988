function SepsisOsnVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="green"> 
        При жизни имелся бактериальный эндокардит аортального и митрального клапанов с образованием пристеночных тромботических наложений, деструкцией клапанных створок, 
        перфорацией митрального клапана, распространением на эндокард левого желудочка, на что указывают макро- и микро- скопические изменения сердца и результаты 
        бактериологического исследования (обнаружение Corynebacterium в вегетациях на створках пораженных клапанов). 
        Смерть наступила вследствие бактериального эндокардита аортального и митрального клапанов, осложнившегося септикопиемией (множественные септические метастазы в головной 
        мозг с развитием множественных инфарктов мозга и гнойного менингита, в легкие и в селезенку с множественными инфарктами и абсцессами в них) и септическим шоком, что 
        подтверждается макро- и микро- скопическими изменениями в соответствующих органах, а также наличием признаков: ДВС-синдрома (диссеминированное внутрисосудистое свертывание) с 
        наличием множественных микротромбов в капиллярах миокарда, легких, печени и почек; резкое полнокровие внутренних органов, наличием жидкой крови в просвете крупных сосудов 
        и полостях сердца, стаз эритроцитов в мелких сосудах; петехиальные кровоизлияния на серозных и слизистых оболочках, в миокарде и эндокарде; дистрофические изменения 
        миокарда (зернистая и вакуольная дистрофия кардиомиоцитов, очаговые некрозы); острого респираторного дистресс-синдрома (ОРДС) ((увеличенная масса легких, обильное 
        серозно-геморрагическое пропитывание и уплотнение ткани легких, мелкие кровоизлияния под плеврой и в глубине паренхимы, выраженная интерстициальная инфильтрация 
        лимфоцитами и макрофагами, гиалиновые мембраны в альвеолах, интерстициальный фиброз, эмфизематозное вздутие участков легких, ателектазы, расширение капилляров и мелких 
        сосудов легких, мелкие геморрагии в межальвеолярных перегородках, отек межальвеолярных перегородок, пропитывание альвеолярных пространств эритроцитами и плазмой)); отека 
        головного мозга; «мускатной печени»; некротического нефроза.
Таким образом, между имевшимся бактериальным эндокардитом аортального и митрального клапанов с образованием пристеночных тромботических наложений, деструкцией клапанных створок, 
перфорацией митрального клапана, распространением на эндокард левого желудочка и наступлением смерти имеется прямая причинная связь.
Бактериальный эндокардит — это инфекционно-воспалительное заболевание внутренней оболочки сердца (эндокарда), преимущественно поражающее клапаны сердца, характеризующееся 
образованием инфекционных вегетаций (тромбоцитарно-фибринозных наложений с колониями микроорганизмов), развитием деструктивных изменений клапанного аппарата, нарушением 
гемодинамики и возможным распространением инфекции с развитием септических осложнений. Бактериальный эндокардит может протекать в острой или хронической форме, сопровождаясь 
клиническими проявлениями интоксикации, эмболическим синдромом, сердечной недостаточностью и полиорганными осложнениями.
        </p>
    )
}
export default SepsisOsnVyvody