function AlcoKishki(){
    return (
        <p id="MsoBodyTextIndent" className="green"> При хроническом алкоголизме возможны:
        -	Гастрит (атрофический, геморрагический или язвенный) - cлизистая желудка гиперемированная, с очагами эрозий или атрофии, острые язвы.
        -   Варикозное расширение вен пищевода и желудка (расширенные, извитые вены на фоне портальной гипертензии).
        -   Признаки синдрома Мэллори-Вейса (патологическое состояние, характеризующееся разрывами слизистой оболочки пищевода и кардиального отдела желудка, вызванными 
        интенсивной рвотой или сильными приступами кашля. Эти разрывы могут привести к кровотечению из верхних отделов желудочно-кишечного тракта):
линейные или щелевидные разрывы слизистой оболочки, обычно расположенные в дистальном отделе пищевода (на границе с желудком) или в кардиальном отделе желудка, преимущественно по 
малой кривизне. Разрывы обычно имеют линейную форму, длиной от нескольких миллиметров до 2–3 см. Края разрывов ровные или слегка неровные, без признаков заживления в случае 
недавнего эпизода. Свежая или свертковая кровь в просвете желудка и пищевода (при недавнем кровотечении). Геморрагические полосы вокруг разрывов. Признаки анемии: бледность 
внутренних органов (особенно при хронической кровопотере).
</p>
    )
}
export default AlcoKishki