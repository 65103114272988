function PnevmoniaOchagLungs(){
    return (
        <p id="MsoBodyTextIndent" className="green">
            При очаговой пневмонии масса пораженного легкого увеличена. В нем видны участки уплотнения (консолидации) серо-красного или серо-желтого цвета. Эти очаги могут быть небольшими (несколько миллиметров) и располагаться в пределах одной или нескольких долей легкого. Уплотненные участки плохо спадаются при разрезе, имеют зернистую или мелкозернистую структуру; кусочки ткани из этих участков тонут в воде. На ощупь такие очаги более плотные (крошковатые), чем окружающая здоровая ткань. Это связано с инфильтрацией альвеол воспалительным экссудатом (с примесью фибрина, лейкоцитов и микробных тел).
Легочная ткань в окружающих очаги зонах гиперемирована (наполнена кровью), иногда отечная. При разрезе легкого может выделяться мутная жидкость.
В области очагов воспаления возможны участки спадения легочной ткани (ателектазы) из-за обструкции бронхов слизисто-гнойными массами.
Воспаление мелких бронхов и бронхиол (катаральный или гнойный эндобронхит) сопровождается покраснением их слизистой оболочки и наличием гнойного или слизисто-гнойного пенистого экссудата.
Наблюдается регионарный лимфаденит (увеличение прикорневых, бифуркационных и паратрахеальных лимфатических узлов).
Возможно вовлечение плевры с формированием реактивного плеврита (наличие фиброзного налета на плевре в зоне поражения, мутный (богатый белком) эксудат в плевральной полости).
При бактериальной инфекции (например, Streptococcus pneumoniae, Haemophilus influenzae):
Очаги воспаления серо-красного или серо-желтого цвета, преимущественно в нижних долях легких. Участки консолидации плотные на ощупь, неровные при разрезе, с выделением мутного гнойного экссудата. Воспаление начинается с бронхов и бронхиол, переходя на прилегающие альвеолы (характерно для бронхопневмонии). При тяжелом течении возможно образование множественных абсцессов. Плевра часто утолщена, покрыта фибринозными наложениями.
При гнойно-септической инфекции (например, Staphylococcus aureus):
Обширные очаги гнойного воспаления, склонные к слиянию. Часто формируются абсцессы (полости, заполненные гноем), окруженные зоной некроза. Плевра может быть вовлечена в процесс с образованием эмпиемы (гнойного плеврита).
При вирусной инфекции (например, грипп, коронавирусы):
Очаги воспаления имеют менее четкие границы, более диффузные. Ткань легких отечная, дряблая, багрово-красного цвета из-за венозного застоя. Выраженный отек межальвеолярной ткани, при разрезе легкого выделяется пенистая, иногда кровянистая жидкость. Возможны кровоизлияния в легочной ткани, особенно при гриппозной пневмонии. Реже, чем при бактериальной пневмонии, встречаются нагноения или абсцессы. В плевре могут отсутствовать заметные изменения или наблюдаться серозный выпот.
При микоплазменной инфекция (Mycoplasma pneumoniae):
Очаги воспаления мелкие, не имеют четких границ. Легочная ткань отечная, мягкая, с участками гиперемии. Плевра обычно не вовлечена в процесс.
При грибковой инфекции (например, Aspergillus, Candida):
Очаги воспаления с участками некроза и грануляционной ткани. Иногда обнаруживаются казеозные массы. При аспергиллезе возможна инвазия сосудов с образованием тромбов и инфарктов.
При аспирационной пневмонии (инфекция смешанного типа):
Очаги воспаления чаще расположены в нижних долях правого легкого. Зона воспаления окружена участками некроза, иногда с гангренозным запахом. На разрезе выделяется грязно-зеленый или бурый гной. Плевра часто вовлекается в процесс, может содержать гной или некротические массы.
        </p>
    )
}
export default PnevmoniaOchagLungs