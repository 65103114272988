function KronaKozha(){
    return (
        <p id="MsoBodyTextIndent" className="green">
При болезни Крона, можно выявить следующие возможные признаки, обусловленные хроническим воспалительным процессом, системными осложнениями и внекишечными проявлениями заболевания:
1. Общие признаки истощения: значительное снижение массы тела, истончение подкожно-жировой клетчатки, особенно в области конечностей, лица и живота. Это связано с хроническим 
воспалением и нарушением всасывания питательных веществ; бледность кожи из-за хронической анемии, вызванной кровопотерями, воспалением или дефицитом железа, витамина B12 и 
фолиевой кислоты.
2. Изменения кожи: болезненные красноватые узлы (чаще на голенях), характерные для внекишечных проявлений болезни Крона (узловатая эритема); гангренозная пиодермия (глубокие 
изъязвления на коже с некрозом тканей, чаще на нижних конечностях); рубцы в области живота могут свидетельствовать о перенесенных операциях (например, резекции кишечника); сухость 
кожи ассоциирована с дефицитом питательных веществ и хроническим воспалением.
3. Признаки абдоминальных операций или осложнений: рубцы и колостомы (следы хирургических вмешательств, таких как резекции или создание стомы); возможное вздутие живота, 
связанное с кишечной непроходимостью или перфорацией, если это привело к смерти.
4. Изменения суставов и конечностей: возможна припухлость крупных суставов (коленные, голеностопные), связанная с реактивным артритом; деформация суставов 
(хронические изменения суставов при длительном течении болезни).
5. Изменения перианальной области: перианальные свищи, трещины, абсцессы или язвы, характерные для болезни Крона; рубцы вокруг ануса указывают на длительное воспаление и 
перенесенные вмешательства.
6. Внекишечные проявления: афты, язвы на слизистой оболочке рта, трещины в уголках губ; признаки увеита, склерита или конъюнктивита (покраснение и отечность век); возможны 
периферические отеки из-за гипоальбуминемии и нарушения обмена веществ.
7. Следы лечения: "лунообразное" лицо, кожные стрии, гематомы, истончение кожи при длительном применении кортикостероидов; следы от катетеров или инфузий.
8. Осложнения: возможно наличие тромбозов глубоких вен, проявляющихся отечностью, цианозом и болезненностью конечностей; желтушность кожи может 
свидетельствовать о поражении печени, вызванном либо внекишечным проявлением болезни Крона, либо лекарственными повреждениями.
        </p>
    )
}
export default KronaKozha