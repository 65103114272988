function GreyvsOsnVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="green"> 
        При жизни имелась болезнь Грейвса (диффузный токсический зоб) (гиперплазия и гипертрофия фолликулярного эпителия щитовидной железы, лимфоцитарная инфильтрация стромы, 
        экзофтальм), на что указывают макро- и микро- скопические изменения органов (щитовидной железы и жировой ткани орбит), низкий уровень ТТГ (тиреотропного гормона) и повышенный уровень 
        гормонов щитовидной железы (Т4 свободного и Т3) при биохимическом исследовании крови, увеличенный уровень антител к рецептору тиреотропного гормона (AT-rTTG) при 
        серологическом исследовании крови. 
        Смерть последовала от болезни Грейвса (диффузного токсического зоба), осложнившейся тиреотоксической кардиомиопатией и последующей острой сердечно-сосудистой 
        недостаточности на фоне неё, что подтверждается макро- и микро- скопическим изменениями как в самом сердце (увеличение размеров, дряблость миокарда, расширение полостей, 
        зернистая и вакуольная дистрофия кардиомиоцитов, очаговые некрозы миокарда, кровоизлияния под эпикардом), так и в других органах с признаками 
        общего венозного полнокровия, гипоксии, отека тканей и геморрагий: легкие (увеличение массы, обильное серозно-геморрагическое пропитывание ткани, уплотнение паренхимы, 
        застой крови в капиллярах, заполнение альвеол розоватым транссудатом (кардиогенный отек легких), мелкие кровоизлияния под плеврой и в межальвеолярных перегородках), 
        головной мозг (отек мозга, увеличение объема, уплощение извилин и сужение борозд, мелкие точечные кровоизлияния в белом веществе и мозжечке, дистрофические изменения 
        нейронов (гиперхромия ядер, вакуолизация цитоплазмы)), печень (увеличение размеров, «мускатный» рисунок на разрезе (сочетание темных застойных и светлых дистрофически 
        измененных участков), центролобулярный застой крови, зернистая и вакуольная дистрофия гепатоцитов), почки (увеличение размеров, дряблая консистенция, застой крови в 
        корковом и мозговом веществе, острая вакуольная дистрофия эпителия канальцев, отек интерстициальной ткани, мелкие кровоизлияния), селезенка(увеличение размеров, дряблость 
        ткани, полнокровие синусов красной пульпы, микрогеморрагии в пульпе). 
Таким образом, между имевшейся болезнью Грейвса (диффузный токсический зоб) и наступлением смерти имеется прямая причинная связь.
Болезнь Грейвса (диффузный токсический зоб) — это аутоиммунное заболевание, характеризующееся гиперфункцией щитовидной железы, диффузным её увеличением и системными проявлениями 
гипертиреоза, такими как тахикардия, потеря веса, тремор и экзофтальм. Возможные осложнения болезни включают тиреотоксическую кардиомиопатию, сердечную недостаточность, 
мерцательную аритмию, остеопороз, миопатию, тиреотоксический криз, а также поражение печени (тиреотоксический гепатоз) и нарушения центральной нервной 
системы (психозы, депрессия).
        </p>
    )
}
export default GreyvsOsnVyvody