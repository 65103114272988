function CovidOsnVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="green">
             При жизни имелся COVID-19, вызванный вирусом SARS-CoV-2 (вирусная интерстициальная пневмония с признаками организации и начального фиброза), на что указывают макро- и 
             микро- скопические изменения в легких, положительный результат на антитела (IgG и IgM одновременно) в крови к вышеуказанному вирусу, положительный ПЦР тест. 
        Смерть последовала от COVID-19, вызванный вирусом SARS-CoV-2 (вирусная интерстициальная пневмония с признаками организации и начального фиброза), осложнившегося  острым 
        респираторным дистресс-синдромом и последующей острой дыхательной недостаточностью, что подтверждается макро- и микро- 
        скопическим изменениями как в самих легких (увеличенная масса легких, обильное серозно-геморрагическое пропитывание и уплотнение ткани легких, мелкие кровоизлияния под 
        плеврой и в глубине паренхимы, выраженная интерстициальная инфильтрация лимфоцитами и макрофагами, гиалиновые мембраны в альвеолах, интерстициальный фиброз, 
        эмфизематозное вздутие участков легких, ателектазы, расширение капилляров и мелких сосудов легких, мелкие геморрагии в межальвеолярных перегородках, отек межальвеолярных 
        перегородок, пропитывание альвеолярных пространств эритроцитами и плазмой), так и в других органах с признаками нарушения газообмена, гипоксии и системных последствий: 
        сердце (зернистая дистрофия миокарда, венозное полнокровие правых отделов), головной мозг (отек мозга, точечные кровоизлияния в белом веществе и мозжечке, дистрофические 
        изменения нейронов), печень (острая (зернистая и вакуольная) центролобулярная дистрофия, полнокровие центральных вен), почки (острая (вакуольная) тубулярная дистрофия, 
        отек интерстиция). 
Таким образом, между имевшимся COVID-19, вызванным вирусом SARS-CoV-2 (вирусная интерстициальная пневмония с признаками организации и начального фиброза) и наступлением смерти 
имеется прямая причинная связь.
COVID-19 — это острое инфекционное заболевание, вызываемое коронавирусом SARS-CoV-2, характеризующееся преимущественным поражением дыхательной системы, системной 
        воспалительной реакцией и разнообразными клиническими проявлениями — от бессимптомного носительства до тяжёлой вирусной пневмонии, острого респираторного 
        дистресс-синдрома (ОРДС) и полиорганной недостаточности. Передача инфекции осуществляется преимущественно воздушно-капельным и аэрозольным путями, реже через контакт с 
        заражёнными поверхностями. Заболевание имеет широкий спектр симптомов, включая лихорадку, кашель, одышку, слабость, а в некоторых случаях поражение сердечно-сосудистой, 
        нервной, пищеварительной и других систем. Основными мерами профилактики являются вакцинация, использование средств индивидуальной защиты и ограничение контактов в 
        эпидемический период.       
        </p>
    )
}
export default CovidOsnVyvody