function DifteriaLab(){
    return (
        <p id="MsoBodyTextIndent" className="green"> Для дифтерии, характерно: при <u>гистологическом исследовании:</u>
1. Слизистые оболочки (глотка, миндалины, дыхательные пути):
-	Фибринозное воспаление: образование плотного слоя фибрина, покрывающего эпителий и проникающего в подлежащие ткани; деструкция эпителия с некрозом тканей, глубоко залегающих 
под фибринозными пленками.
-	Инфильтрация: подслизистый слой инфильтрирован нейтрофилами, лимфоцитами, макрофагами и плазматическими клетками; отмечается отек и вазодилатация.
-	Некроз: зоны коагуляционного некроза под налетом.
-   Возможны общеасфиксические признаки: микрокровоизлияния (точечные экхимозы в слизистой и подслизистой оболочках); застой крови (расширение сосудов и гиперемия).
2. Лимфатические узлы:
-	Реактивная гиперплазия: увеличение количества лимфоидных элементов.
-	Гнойное или серозное воспаление: наличие очагов некроза и скопления нейтрофильных лейкоцитов.
-	Отек капсулы и стромы.
3. Миокард:
-	Дистрофические изменения: жировая дистрофия кардиомиоцитов (мелкие вакуоли в цитоплазме клеток).
-	Миокардит: лимфогистиоцитарная инфильтрация межуточной ткани.
-	Мелкие очаговые некрозы.
-   Возможны общеасфиксические признаки: полнокровие миокарда (застой венозной крови в капиллярах и мелких венах); микрофокальные ишемические изменения (очаговые повреждения 
кардиомиоцитов: вакуолизация цитоплазмы, кариопикноз, фрагментация волокон); дистрофические изменения (гипоксическая зернистая и жировая дистрофия кардиомиоцитов).
4. Печень:
-	Токсическая дистрофия: баллонная и зернистая дегенерация гепатоцитов; некроз отдельных клеток или мелких групп гепатоцитов.
-	Воспалительные изменения: перипортальная инфильтрация лимфоцитами.
-   Возможны общеасфиксические признаки: центролобулярное венозное полнокровие (переполнение центральных вен кровью); гипоксия гепатоцитов (дистрофические изменения (вакуолизация 
цитоплазмы, некроз отдельных клеток)); cтаз в синусоидах (застой крови в синусоидных капиллярах, расширение и переполнение кровью).
5. Почки:
-	Токсическая нефропатия: дистрофия эпителия канальцев (зернистая, гидропическая или вакуольная); некроз эпителия в проксимальных извитых канальцах.
-	Отек и лимфоцитарная инфильтрация интерстициальной ткани.
-   Возможны общеасфиксические признаки: расширение и полнокровие сосудов клубочков; микротромбозы (обнаруживаются в капиллярах клубочков); дистрофия канальцев (гипоксические 
изменения эпителия канальцев: зернистость цитоплазмы, отёк, некроз отдельных клеток).
6. Селезенка:
-	Активизация лимфоидной ткани: увеличение размеров фолликулов.
-	Застой крови в синусах, иногда мелкие кровоизлияния.
7. Головной мозг: 
-   При обработке тканевых срезов осмиевой кислотой возможно выявление  демиелизации черепно-мозговых нервов.
-   Возможны общеасфиксические признаки: отёк мозга (периваскулярные и перицеллюлярные отёчные пространства); полнокровие сосудов (расширение и полнокровие 
капилляров и венул); мелкие кровоизлияния (диапедезные кровоизлияния в веществе мозга); ишемические нейроны (клетки с пикнотичными ядрами, гиперэозинофильной цитоплазмой 
(тени нейронов)).
8. Лёгкие (возможны общеасфиксические признаки):
-	Полнокровие капилляров: значительное расширение капилляров и мелких сосудов, переполненных кровью.
-	Субплевральные кровоизлияния (пятна Тардье): очаговые скопления эритроцитов в альвеолярных перегородках или под плеврой.
-	Отёк альвеол: наличие розоватого или гомогенного жидкого экссудата в просветах альвеол.
-	Эмфизема: разрывы альвеол с образованием участков воздушных пузырей.
9. Общие изменения:
-	Микротромбозы: в мелких сосудах различных органов могут обнаруживаться тромбы из фибрина.
-	Кровоизлияния: мелкие геморрагии в ткани легких, сердца, мозга и других органов.
Эти изменения связаны с воздействием токсинов Corynebacterium diphtheriae, вызывающих некроз тканей, воспалительную реакцию и системные токсические эффекты. <u>Бактериологическое 
    исследование:</u> отбор мазков или кусочков ткани с пораженных участков (миндалины, дыхательные пути) для выделения возбудителя — Corynebacterium diphtheriae
(грамположительная палочка Лефлера); посев материала на селективные питательные среды для идентификации бактерий. <u>Молекулярно-генетическое исследование (ПЦР):</u> выявление 
ДНК Corynebacterium diphtheriae в тканях или выделенном материале; определение токсигенности штамма, что подтверждает наличие дифтерийного токсина. <u>Токсикологическое 
    исследование:</u> определение наличия дифтерийного токсина в крови или тканях; используются методы иммуноферментного анализа (ИФА) или других серологических 
тестов. <u>Серологическое исследование:</u> определение уровня антидифтерийных антител в крови; низкий уровень антител указывает на отсутствие иммунной защиты, что характерно для 
тяжелых форм заболевания.
        </p>                
    )
}
export default DifteriaLab