function SaharDiab2OsnDiagnoz(){
    return (
        <p id="MsoBodyTextIndent" className="green">
            Сахарный диабет 2 типа, длительное течение (диабетическая нефропатия III стадии, атеросклероз крупных (макроангиопатия) 
        и мелких сосудов, амилоидоз и гипертрофия островков Лангерганса, гипертрофия миокарда и атеросклероз коронарных артерий, диабетическая микро- и макро- ангиопатия 
        нижних конечностей, выраженные трофические язвы нижних конечностей, гиперлипидемия).
Осложнения основного заболевания: диабетическая (гиперосмолярная и кетоацидотическая) кома с развитием полиорганной недостаточности.
        </p>
    )
}
export default SaharDiab2OsnDiagnoz