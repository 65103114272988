function GipCardioOsnVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="green"> 
        При жизни имелась гипертрофическая кардиомиопатия (асимметричная форма): гипертрофия межжелудочковой перегородки (толщина МЖП 25 мм), гипертрофия свободной стенки левого 
        желудочка (толщина 18 мм), масса сердца – 520 г, уменьшение объема полости левого желудочка, интерстициальный фиброз миокарда, что подтверждается макро- и микро- 
        скопическими изменениями сердца, наряду с отсутствием других причин гипертрофии сердца (артериальной гипертензии, клапанных пороков, миокардита, ишемической болезни сердца, 
        эндокринных заболеваний, нарушений водно-электролитного баланса) и обнаружением мутаций в генах связанных с гипертрофической кардиомиопатией  MYH7 и MYBPC3.
        Смерть наступила вследствие асимметричной формы гипертрофической кардиомиопатии, осложнившейся острой сердечной недостаточностью, что подтверждается макро- и микро- 
        скопическим изменениями как в самом сердце (зернистая и вакуольная дистрофия кардиомиоцитов, очаговые некрозы миокарда, кровоизлияния под эпикардом), так и в других 
        органах с признаками общего венозного полнокровия, жидкого состояния крови в полостях сердца и крупных сосудах, гипоксии, отека тканей и геморрагий: легкие (увеличение 
        массы, обильное серозно-геморрагическое пропитывание ткани, уплотнение паренхимы, застой крови в капиллярах, заполнение альвеол розоватым транссудатом (кардиогенный отек 
        легких), мелкие кровоизлияния под плеврой и в межальвеолярных перегородках), 
        головной мозг (отек мозга, увеличение объема, уплощение извилин и сужение борозд, мелкие точечные кровоизлияния в белом веществе и мозжечке, дистрофические изменения 
        нейронов (гиперхромия ядер, вакуолизация цитоплазмы)), печень (увеличение размеров, «мускатный» рисунок на разрезе (сочетание темных застойных и светлых дистрофически 
        измененных участков), центролобулярный застой крови, зернистая и вакуольная дистрофия гепатоцитов), почки (увеличение размеров, дряблая консистенция, застой крови в 
        корковом и мозговом веществе, острая вакуольная дистрофия эпителия канальцев, отек интерстициальной ткани, мелкие кровоизлияния), селезенка(увеличение размеров, дряблость 
        ткани, полнокровие синусов красной пульпы, микрогеморрагии в пульпе). 
Таким образом, между имевшейся гипертрофической кардиомиопатией (асимметричная форма) и наступлением смерти имеется прямая причинная связь.
        Гипертрофическая кардиомиопатия (ГКМП) — это генетически обусловленное заболевание миокарда, характеризующееся необъяснимой (не связанной с нагрузкой) гипертрофией стенок 
        левого и/или правого желудочков, чаще всего асимметричной гипертрофией межжелудочковой перегородки. Основными морфологическими особенностями являются гипертрофия 
        кардиомиоцитов, их дезорганизация, интерстициальный фиброз и уменьшение объема полости левого желудочка.
Заболевание приводит к нарушению диастолической функции сердца, обструкции выносящего тракта левого желудочка (в ряде случаев), аритмиям и повышенному риску внезапной сердечной 
смерти.
        </p>
    )
}
export default GipCardioOsnVyvody