function HoblLungs(){
    return (
        <p id="MsoBodyTextIndent" className="green">
               Для хронической обструктивной болезни легких (ХОБЛ) характерно: легкие увеличены в объеме, не спадаются при вскрытии плевральной 
               полости, их края тупые; участки эмфиземы видны в виде полупрозрачных пузырей на поверхности (буллы). Легкие часто серо-черного или серо-коричневого цвета из-за 
               отложений пыли, копоти и продуктов курения. Поверхность легких может быть неоднородной из-за рубцов и воспалительных изменений. В некоторых участках легких может 
               быть заметно спадение тканей из-за обструкции мелких бронхов слизистыми пробками (участки ателектаза). При разрезе обнаруживаются утолщенные стенки бронхов, 
               возможно наличие вязкого слизисто-гнойного содержимого, также в просвете могут обнаруживаться сосочковидные образования, состоящие из соединительной ткани. 
               Слизистая оболочка бронхов имеет усиленную складчатость, отмечается деформация просвета, характеризующееся наличием очаговых сужений и расширений.
            Может выявляться чередование запустевших легочных сосудов с выраженными изменениями в их стенках и гиперемированных сосудов. В просвете некоторых сосудов возможны 
            тромботические массы.
        </p>
    )
}
export default HoblLungs