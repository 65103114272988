function RsLab(){
    return (
        <p id="MsoBodyTextIndent" className="green"> Для рассеянного склероза (РС), характерно: при <u>гистологическом исследовании:</u>
1. Очаги демиелинизации: представляют собой зоны разрушения миелиновой оболочки вокруг аксонов; локализуются преимущественно в белом веществе головного и спинного мозга, включая 
перивентрикулярные области, мозолистое тело, зрительные пути, ствол мозга и мозжечок; на гистологических препаратах хорошо видны при окраске, например, осмиевой кислотой или 
люксольным синим (миелин окрашивается интенсивно, а зоны демиелинизации остаются светлыми).
2. Вокруг очагов демиелинизации часто обнаруживаются воспалительные клетки (воспалительная инфильтрация): Т-лимфоциты (основной тип клеток, указывающий на аутоиммунный характер 
заболевания); макрофаги и микроглия, активно фагоцитирующие разрушенный миелин; иногда встречаются В-лимфоциты и плазматические клетки, что свидетельствует о гуморальной 
активности.
3. Несмотря на разрушение миелина, аксоны часто сохраняются, особенно в ранних стадиях заболевания. Однако: при хроническом течении отмечается утрата аксонов, особенно в старых 
очагах (плакатах), что проявляется истончением или обрывом аксонов; выявляются аксональные «сферы» (вздутия), свидетельствующие о повреждении транспортных процессов внутри 
нейронов.
4. В старых очагах (плакатах) демиелинизации наблюдается выраженный глиоз (замещение разрушенного миелина астроцитами): астроциты гипертрофированы, их отростки образуют плотную 
сеть; глиоз хорошо виден при окраске гематоксилин-эозином или серебром.
5. Изменения в сосудах:
-	Периваскулярное воспаление: вокруг мелких венул в очагах демиелинизации часто обнаруживаются лимфоциты и макрофаги (феномен «мантийного венита»); эти изменения связаны с 
иммунной атакой на мозговую ткань.
-	Иногда наблюдается утолщение стенок сосудов или пролиферация эндотелия.
6. Активированная микроглия обнаруживается в пораженных участках: она имеет амебовидную форму и активно фагоцитирует миелин; в хронических плакатах микроглия остается 
гиперактивной, поддерживая воспаление.
7. Старые и новые очаги (плакаты):
-	Свежие плакаты: содержат активную воспалительную инфильтрацию; частично разрушенный миелин и сохраненные аксоны.
-	Хронические плакаты: воспаление минимально или отсутствует; выражен глиоз, миелин полностью разрушен, аксоны частично утрачены.
8. Сопутствующие изменения:
-	В зрительных нервах при перенесенном оптическом неврите: утрата миелина и аксонов; глиоз и периваскулярная инфильтрация.
-	В спинном мозге: очаговая демиелинизация в белом веществе, особенно в латеральных и задних столбах; потеря нейронов в сером веществе при тяжелом течении заболевания.
При рассеянном склерозе (РС) основным патологическим процессом является демиелинизация, сопровождающаяся воспалением и повреждением аксонов. <u>Молекулярные (ПЦР) и серологические 
    исследования:</u> для исключения инфекционных поражений (например, вирусов, способных вызывать демиелинизацию: EBV (вирус Эпштейна-Барр), 
CMV (цитомегаловирус), HSV-1 и HSV-2 (вирусы простого герпеса 1 и 2 типа), VZV (вирус ветряной оспы и опоясывающего лишая), JC (вирус Джона Каннингема), SARS-CoV-2 (коронавирус, 
вызывающий COVID-19), ВИЧ (вирус иммунодефицита человека), HTLV-1 (вирус Т-клеточного лейкоза человека 1 типа), вирус кори, вирусы клещевого энцефалита, вирус полиомиелита, 
энтеровирус 71, вирусы гриппа, вирус бешенства). <u>Иммуногистохимические (ИГХ) исследования:</u> основные маркеры и цели их применения:
1. Маркеры миелина:
-	Антитела к миелиновому основному белку (MBP, Myelin Basic Protein): позволяют выявить потерю миелина в поражённых областях; очаги демиелинизации будут выглядеть как зоны с 
ослабленным или отсутствующим окрашиванием.
-	Антитела к протеолипидному белку (PLP): похожая функция, используется для подтверждения утраты миелина.
2. Маркеры аксонов:
-	Антитела к нейрофиламентам (NF, Neurofilament): показывают сохранность или повреждение аксонов в очагах демиелинизации; при РС аксональная дегенерация может быть заметна даже 
в хронических очагах.
3. Маркеры воспаления:
-	Антитела к CD3, CD4 и CD8: выявляют Т-лимфоциты в воспалительных инфильтратах; преимущественное присутствие CD8+ цитотоксических Т-лимфоцитов характерно для активных очагов.
-	Антитела к CD20: определяют В-лимфоциты и их роль в формировании воспаления.
-	Антитела к CD68: используются для идентификации активированных макрофагов/микроглии, которые участвуют в фагоцитозе разрушенного миелина.
4. Маркеры глиоза:
-	Антитела к глиальному фибриллярному кислому белку (GFAP, Glial Fibrillary Acidic Protein): указывают на реактивный глиоз, который формируется в хронических очагах 
демиелинизации; выраженный глиоз свидетельствует о длительном течении заболевания.
5. Маркеры олигодендроцитов:
-	Антитела к Olig2 (олигодендроцитарный транскрипционный фактор): показывают олигодендроциты, которые участвуют в ремиелинизации; снижение количества олигодендроцитов 
наблюдается в активных и хронических очагах.
6. Маркеры для определения фаз активности очагов:
-	Антитела к комплементу (C9neo): показывают активацию комплемента в активных очагах демиелинизации.
-	Антитела к Ki-67: применяются для оценки пролиферативной активности клеток, например, микроглии или воспалительных клеток.
7. Гипоксия-ассоциированные маркеры:
-	HIF-1α (гипоксия-индуцируемый фактор 1α): указывает на зоны гипоксии в ЦНС, которые могут быть связаны с активными очагами воспаления.
Диагностические критерии для рассеянного склероза (РС) на основании ИГХ:
1. Активные очаги: выраженная инфильтрация Т-лимфоцитами (CD3+), макрофагами/микроглией (CD68+); потеря миелина (MBP−) при сохранении аксонов (NF+); признаки ремиелинизации 
(Olig2+).
Хронические очаги: выраженный глиоз (GFAP+), уменьшение клеток олигодендроцитарного ряда (Olig2−); отсутствие активного воспаления.
Демаркационные зоны: чёткие границы между нормальной и поражённой тканью при демиелинизации.
        </p>                
    )
}
export default RsLab