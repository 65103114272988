function GrippOsnVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="green">
             При жизни имелась гриппозная вирусная инфекция (грипп типа A)	(острый трахеобронхит, острая вирусная интерстициальная пневмония), на что указывают 
            макро- и микро- скопические изменения в этих органах, а также результат полимеразной цепной реакции (ПЦР) из образцов легочной ткани и трахеобронхиального 
            секрета: выделен вирусный РНК, специфичный для гриппа типа A и результат иммуногистохимического исследования: образцы легких окрашены на наличие вирусных 
            антигенов гриппа типа A.
        Смерть последовала от гриппозной вирусной инфекции (грипп типа A) (острый трахеобронхит, острая вирусная интерстициальная пневмония), осложнившейся присоединением 
        суперинфекции (Streptococcus pneumoniae) с развитием вторичной бактериальной пневмонии и острой дыхательной недостаточности, что подтверждается обнаружением Streptococcus 
        pneumoniae при бактериологическом исследовании биоптатов из легких, макро и микро -скопическим изменениями в них (выраженная интерстициальная инфильтрация лимфоцитами и 
        макрофагами, участки геморрагий, гиалиновые мембраны в альвеолах, участки уплотнения ткани, инфильтрация нейтрофилами, эмфизематозное вздутие участков легких, ателектазы,
        расширение капилляров и мелких сосудов легких, мелкие геморрагии в межальвеолярных перегородках, отек межальвеолярных перегородок, пропитывание альвеолярных пространств 
        эритроцитами и плазмой), а также в других органах с признаками нарушения газообмена, гипоксии и системных последствий: сердце (зернистая дистрофия миокарда, венозное 
        полнокровие правых отделов), головной мозг (отек мозга, точечные кровоизлияния в белом веществе и мозжечке, дистрофические изменения нейронов), печень (острая (зернистая 
        и вакуольная) центролобулярная дистрофия, полнокровие центральных вен), почки (острая (вакуольная) тубулярная дистрофия, отек интерстиция). 
Таким образом, между имевшейся гриппозной вирусной инфекции (грипп типа A) в виде острого трахеобронхита, острой вирусной интерстициальной пневмонии и наступлением смерти имеется 
прямая причинная связь.
Грипп — это острое инфекционное заболевание, вызываемое вирусами гриппа типов A, B и C из семейства Orthomyxoviridae, характеризующееся преимущественно поражением 
            дыхательных путей, выраженной интоксикацией, лихорадкой и катаральными симптомами. Передача инфекции осуществляется воздушно-капельным путём, реже через загрязнённые 
            предметы. Заболевание протекает с различной степенью тяжести: от лёгких форм до осложнённых, включая вирусную пневмонию, бактериальные суперинфекции, поражения 
            сердечно-сосудистой и нервной систем. Грипп имеет выраженную сезонность и может вызывать эпидемии и пандемии. Профилактика включает вакцинацию и меры по снижению 
            распространения инфекции.
        </p>
    )
}
export default GrippOsnVyvody