function PnevmoniaKrupKozha(){
    return (
        <p id="MsoBodyTextIndent" className="green">При крупозной пневмонии можно выявить следующие возможные особенности, которые косвенно или прямо указывают на заболевание:
        1. Признаки общего инфекционно-воспалительного процесса:
-	Цианоз кожи и слизистых оболочек (может наблюдаться на губах, носогубном треугольнике, ушных раковинах и кончиках пальцев из-за гипоксии, характерной для дыхательной 
недостаточности).
-	Повышенная влажность кожи (следствие лихорадочного состояния при жизни).
-	Обезвоживание (сухость слизистых оболочек рта и губ, возможные трещины).
2. Признаки дыхательной недостаточности:
-	Положение тела (может быть фиксировано в полусидячем или вынужденном положении (ортопноэ), если человек умер в момент тяжелой дыхательной недостаточности).
-	Видимые дыхательные мышцы (возможна гипертрофия вспомогательных дыхательных мышц (грудино-ключично-сосцевидной, межреберных) из-за частого напряжения при одышке).
-	Цианотичные ногти и конечности (признак периферической гипоксии).
-   Возможна асимметрия грудной клетки из-за уменьшенной подвижности пораженной стороны.
-	Интенсивные трупные пятна цианотического оттенка, особенно в нижних отделах тела (трупные признаки гипоксии).
3. Признаки интоксикации:
-	Общий вид тела (выраженное истощение или слабовыраженный тургор кожи, свидетельствующий о длительном инфекционном процессе).
-	Желтушность склер (возможный признак токсического поражения печени при тяжелой форме пневмонии или сепсисе).
-	Геморрагическая сыпь (редко) (мелкие кровоизлияния на коже (петехии) из-за токсической сосудистой недостаточности).
4. Признаки повышенной нагрузки на сердечно-сосудистую систему:
-	Отеки на лице (особенно при наличии сопутствующей почечной недостаточности) и нижних конечностей (могут быть обусловлены правожелудочковой недостаточностью вследствие гипоксии).
-	Расширенные шейные вены (возможны при застойных явлениях в большом круге кровообращения).
5. Признаки осложнений пневмонии:
-	Гнойные выделения из носа или рта (могут быть обнаружены при наличии аспирации гнойного экссудата в трахею).
-	Кровянистые выделения (возможны при повреждении сосудов легочной ткани, особенно при аспирации кровянистого экссудата).
        </p>
    )
}
export default PnevmoniaKrupKozha