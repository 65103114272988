function MeningitOsnVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="green"> 
        При жизни имелся менингококковый менингит, что подтверждается макро- и микроскопическими изменениями головного мозга и его оболочек, изменением биохимического и 
        цитологического состава ликвора (повышенное содержание нейтрофилов, наличие лимфоцитов и мононуклеаров, умеренное повышение белка, снижение уровня глюкозы, повышение 
        уровня лактата), а также выявлением Neisseria meningitidis при бактериологическом исследовании (посев с мягких мозговых оболочек и вещества мозга).
        Смерть наступила вследствие менингококкового менингита, осложнившегося отеком-набуханием головного мозга и вклинением миндалин мозжечка и продолговатого мозга в большое 
        затылочное отверстие, что подтверждается макро- и микроскопическими изменениями головного мозга (увеличение массы и объема, сглаженность извилин, уплощение базальных 
        структур, отек мозгового вещества с нарушением границы серого и белого вещества, желудочки сжаты, симметричное вклинение миндалин мозжечка в большое затылочное отверстие 
        с их уплотнением и кровоизлияниями, вдавление продолговатого мозга, множественные петехиальные кровоизлияния в стволе мозга, венозное полнокровие сосудов основания, 
        диапедезные кровоизлияния; микроскопически: отек нейропиля, вакуолизация цитоплазмы нейронов, гиперхромия ядер, реактивный астроцитоз, эозинофилия цитоплазмы нейронов, 
        деструкция аксонов, демиелинизация, геморрагии в области моста и продолговатого мозга (синдром Дюрета)), а также в других органах: легкие (венозное полнокровие, отек, 
        пятна Тардье; микроскопически: интерстициальный и альвеолярный отек, пропитывание плазмой и эритроцитами, фибрин в альвеолах, участки эмфиземы), сердце (застойное 
        полнокровие правых отделов, гипоксическая дистрофия миокарда, мелкие кровоизлияния; микроскопически: отек кардиомиоцитов, очаги гипоксии), печень (застойное полнокровие, 
        мускатная печень; микроскопически: гидропическая и вакуольная дистрофия), почки (кортикальный застой; микроскопически: дистрофия эпителия канальцев, очаги некроза), 
        селезенка (увеличение размеров, венозное полнокровие, гиперплазия лимфоидной ткани).       
Таким образом, между имевшимся менингококковым менингитом и наступлением смерти имеется прямая причинная связь.
Менингококковый менингит — это острая инфекционная болезнь, вызываемая Neisseria meningitidis, характеризующаяся воспалением мягких оболочек головного и спинного мозга, 
протекающая с выраженными симптомами интоксикации, лихорадки, менингеального синдрома (головная боль, ригидность мышц затылка, светобоязнь, гиперестезия), а также возможными 
осложнениями в виде отека-набухания головного мозга и полиорганной недостаточности. Заболевание сопровождается серозно-гнойным воспалением мозговых оболочек с накоплением 
воспалительного экссудата, инфильтрацией лейкоцитами и микроорганизмами, нарушением циркуляции ликвора, развитием внутричерепной гипертензии и возможным вклинением мозговых 
структур.
        </p>
    )
}
export default MeningitOsnVyvody