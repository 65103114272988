function PancreatitLab(){
    return (
        <p id="MsoBodyTextIndent" className="green"> Для панкреатита, характерно: при <u>гистологическом исследовании:</u> 
        1.	Поджелудочная железа:
- Острый панкреатит: некроз ацинарных клеток (ферментативный некроз); интенсивная инфильтрация тканей нейтрофилами; отёк междольковых перегородок; очаговые кровоизлияния; 
жировой некроз (в окружающей жировой ткани).
- Хронический панкреатит: фиброз (замещение функциональной ткани соединительной тканью); атрофия ацинарной ткани; инфильтрация лимфоцитами и плазматическими клетками; 
расширение или деформация протоков, иногда с отложением белковых пробок; очаговый некроз и дистрофия остатков железистой ткани.
2. Печень (при осложнениях панкреатита):
-	Жировая дистрофия гепатоцитов.
-	Воспалительная инфильтрация в портальных трактах.
-	Периферический фиброз (при длительном течении).
3. Желудок и кишечник:
-	Некроз слизистой оболочки, эрозии или язвы.
-	Кровоизлияния в подслизистом слое.
4. Брыжейка и окружающая жировая ткань:
-	Очаги жирового некроза с образованием мыла (кальцификаты).
-	Инфильтрация воспалительными клетками.
5. Лёгкие (при системных осложнениях):
-	Жировая эмболия мелких сосудов.
-	Геморрагический отёк.
-	Гиалиновая мембрана (при развитии острого респираторного дистресс-синдрома).
6. Общие изменения:
-	Системный воспалительный ответ может привести к дистрофическим изменениям в почках (острый канальцевый некроз), сердечной мышце (миокардиодистрофия) и других органах.
Эти гистологические признаки помогают подтвердить диагноз и оценить тяжесть панкреатита, а также определить возможные осложнения или сопутствующие поражения других 
органов. <u>Биохимическое исследование:</u> определение уровня ферментов в крови (амилаза, липаза, трипсин — их повышенное содержание может свидетельствовать о панкреатите),
исследование на наличие ферментов поджелудочной железы в экссудате из брюшной полости, определение электролитного состава и метаболитов (кальций, глюкоза) для оценки общего 
состояния (гипергликемия - при длительной дисфункцией железы, гипокальциемия - при жировом некрозе). <u>Бактериологическое исследование:</u> исключение или подтверждение инфекции 
(например, при инфекционном панкреатите); анализ экссудата или тканей на присутствие патогенной микрофлоры. <u>Молекулярно-генетическое исследование:</u> если подозревается 
генетическая предрасположенность к хроническому панкреатиту или мутации в генах (например, CFTR, PRSS1), можно исследовать образцы тканей.
        </p>                
    )
}
export default PancreatitLab