function PnevmoniaInterstLungs(){
    return (
        <p id="MsoBodyTextIndent" className="green">
                Интерстициальная пневмония характеризуется поражением интерстициальной ткани лёгких: они становятся плотными и менее эластичными. Их консистенция может напоминать 
                каучук или уплотнённую ткань, что связано с развитием фиброза и утолщением интерстициальной ткани.
Лёгкие приобретают серо-белесый или серо-коричневый оттенок, что связано с отложением фиброзной ткани и нарушением кровообращения в поражённых участках.
В результате хронического течения интерстициальной пневмонии и фиброзных изменений лёгкие могут становиться уменьшенными в объёме, что особенно заметно на фоне эмфизематозно 
растянутых участков или соседних, относительно нормальных зон.
При разрезе лёгочной ткани отмечается зернистость или мелкозернистая структура, обусловленная утолщением альвеолярных перегородок.
В длительно текущих случаях в лёгких могут выявляться очаги кальцификации или гиалиноза, что свидетельствует о хронических изменениях.
Признаки нарушения кровообращения: отёчность интерстициальной ткани, признаки венозного застоя (например, темно-красные участки гиперемии) или кровоизлияния в ткань лёгких могут 
быть сопутствующими изменениями.
На поздних стадиях интерстициальной пневмонии можно обнаружить плотные фиброзные тяжи, которые деформируют лёгочную ткань, создавая "сотовую" структуру.
Интерстициальная пневмония может быть как идиопатической, так и морфологическим проявлением (или осложнением) системных заболеваний соединительной ткани, инфекции, воздействия токсинов или радиации.
Возможные системные заболевания соединительной ткани (коллагенозы), как причина интерстициальной пневмонии: ревматоидный артрит, системная склеродермия, системная красная волчанка, синдром Шегрена, дерматомиозит и полимиозит, антифосфолипидный синдром, 
васкулиты (гранулематоз с полиангиитом, микроскопический полиангиит), смешанное заболевание соединительной ткани, идиопатические воспалительные миопатии.
Возможные инфекционные агенты, как причина интерстициальной пневмонии:
вирусные инфекции (вирусы гриппа (A, B), коронавирусы (SARS, MERS, COVID-19), вирус герпеса (HSV, CMV), вирусы кори и краснухи, эпидемический паротит (свинка));
бактериальные инфекции (микоплазма пневмонии (Mycoplasma pneumoniae), хламидийная инфекция (Chlamydia pneumoniae),	коклюш (Bordetella pertussis), туберкулёз (Mycobacterium tuberculosis));
грибковые инфекции (пневмоцистная пневмония (Pneumocystis jirovecii), аспергиллез); 
протозойные и паразитарные инфекции (токсоплазмоз, лёгочная форма шистосомоза).
Возможные токсины, как причина интерстициальной пневмонии:
химические агенты (хлор, аммиак, фосген, курение);
лекарственные препараты (лекарственно-индуцированная интерстициальная пневмония): цитостатики(метотрексат, циклофосфамид), антибиотики (нитрофурантоин или ампицилин), антиаритмики (амиодарон);
промышленные токсины: пыль, содержащая диоксид кремния (вызывает силикоз), асбест.
        </p>
    )
}
export default PnevmoniaInterstLungs