function DifteriaOsnVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="green"> 
         При жизни имелась дифтерия зева и дыхательных путей, токсическая форма (по результатам исследования трупа: фибринозно-некротический ларинготрахеобронхит, реактивная 
         гиперплазия и отек шейных лимфатических узлов, отек мягких тканей шеи), на что указывают макро- и микро- скопические изменения в этих органах, выделение Corynebacterium 
         diphtheriae (палочки Лефлера) при бактериологическом исследовании, установлением наличия дифтерийного токсина в крови методом иммуноферментного анализа.
        Смерть последовала от токсической формы дифтерии зева и дыхательных путей, осложнившейся обтурационной асфиксией, токсическим миокардитом, токсической дистрофией печени и 
        почек с развитием острой сердечно-сосудистой недостаточности, что подтверждается наличием: фибринозных пленок в просвете трахеи и бронхов, признаков поражения сердца 
        (токсический миокардит); признаков застойных явлений в большом и малом кругах кровообращения (выраженный отек легочной ткани, полнокровие сосудов малого круга 
        кровообращения, множественные кровоизлияния в альвеолярные перегородки, застойное увеличение печени (полнокровие центральных зон долек), очаговая дистрофия гепатоцитов, 
        полнокровие сосудов мозгового и коркового вещества почек, признаки острой гипоксической дистрофии эпителия канальцев почек, полнокровие и расширение синусов в селезенке); 
        признаков венозного застоя и гипоксии (полнокровие вен и мелких сосудов внутренних органов, множественные точечные кровоизлияния (петехии) на серозных оболочках (эпикард, 
        плевра, брюшина), слизистых оболочках и коже); общих изменений, связанных с острой недостаточностью кровообращения (бледность и цианотичность внутренних органов, отек 
        головного мозга с полнокровием сосудов и мелкими кровоизлияниями, вакуолизация клеток и очаги некроза в печени и почках (из-за гипоксии тканей)).
Таким образом, между имевшейся токсической формой дифтерии зева и дыхательных путей и наступлением смерти имеется прямая причинная связь.
        Дифтерия — это острое инфекционное заболевание, вызываемое токсигенными штаммами Corynebacterium diphtheriae (палочка Лефлера), характеризующееся развитием фибринозного 
        воспаления на слизистых оболочках верхних дыхательных путей, реже кожи и других органов, а также системным токсическим поражением сердечно-сосудистой, нервной и 
        выделительной систем в результате действия дифтерийного экзотоксина. Заболевание передается преимущественно воздушно-капельным путем, иногда через предметы обихода или 
        контакт с инфицированными поверхностями. Инкубационный период составляет 2–7 дней. Основными проявлениями являются фибринозные пленки на пораженных участках, выраженная 
        интоксикация, возможное развитие асфиксии, миокардита, нефропатии и полинейропатии.
        </p>
    )
}
export default DifteriaOsnVyvody