function AstmaLab(){
    return (
        <p id="MsoBodyTextIndent" className="green">
            Для бронхиальной астмы характерно: при <u>гистологическом исследовании:</u> 1. Легкие:
утолщение базальной мембраны эпителия бронхов. Гиперплазия и гипертрофия гладкомышечных клеток стенки бронхов. Десквамация эпителиальных клеток бронхов и бронхиол, местами — 
участки метаплазии эпителия (переход цилиндрического эпителия в многослойный плоский). Инфильтрация стенки бронхов эозинофилами, лимфоцитами и другими клетками воспаления.
Скопление слизи и мукоидных пробок в просветах бронхов (спирали Кушмана - закрученные слизистые нити, которые формируются из слущенных эпителиальных клеток и слизи; кристаллы 
Шарко-Лейдена - ромбовидные или игольчатые кристаллы, образующиеся из разрушенных гранул эозинофилов).
Расширение альвеол (признаки эмфиземы). Отек и инфильтрация межальвеолярных перегородок эозинофилами и лимфоцитами. Застой в капиллярах и микроочаги ателектазов в легких.
В случае смерти во время приступа астмы (астматического статуса), отмечается лимфойдная инфильтрация стенок бронхов эозинофилами, бронхи на поперечных срезах могут быть либо 
расширенными и заполненными эозинофильной жидкостью со слущенным эпителием, либо  иметь фестончатую форму (из-за бронхоспазма).
2. Сердце (правый желудочек): гипертрофия миокарда (увеличение размеров кардиомиоцитов и ядра, возможны участки гипоксического повреждения).
3. Печень: хронический венозный застой (полнокровие центральных вен и синусоидов, жировая дистрофия гепатоцитов в периферических отделах дольки).
4. Головной мозг: признаки гипоксии (отек межклеточного вещества, микроочаги нейронального повреждения, включая пикноз ядер и кариолизис, полнокровие капилляров с расширением 
периваскулярных пространств).
5. Почки: гипоксические изменения (дистрофия канальцевого эпителия, полнокровие сосудов клубочков, микроочаги некроза в корковом веществе при тяжелой гипоксии). <u>Иммунологическое исследование крови + ОАК: </u>
 определение уровня иммуноглобулина E (общий IgE), свидетельствующего о аллергической природе болезни +	эозинофилия в общем анализе крови. <u>Иммуногистохимическое исследование:</u> выявление свечения IgE на 
поверхности клеток, инфильтрирующих слизистую оболочку бронхов, а также на базальной мембране слизистой оболочки.
        </p>
    )
}
export default AstmaLab