function GemInsultOsnVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="green">
            При жизни имелась врождённая аневризма правой задней соединительной артерии, разрыв которой привел к геморрагическому инсульту (субарахноидальное кровоизлияние 
            (объемом 110 мл), локализующееся в области основания и ствола головного мозга с распространением в базальные цистерны и борозды), что подтверждается макро- и микро- 
        скопическими изменениями головного мозга и его сосудов, локализацией и морфологическими особенностями самой аневризмы: тонкая и эластичная стенка без признаков склероза 
        или кальцификации, что характерно для врожденных аневризм, отсутствие признаков воспаления (указывающих на инфекционный или воспалительный генез), мешковидная форма 
        (наиболее характерна для врожденных аневризм), дефекты эластического каркаса (эластической мембраны) – отсутствие или фрагментация внутренней эластической мембраны 
        указывает на врожденный дефект, гипоплазия гладкомышечных клеток в стенке – типичный признак врожденной аневризмы, отсутствие воспалительных инфильтратов – 
        подтверждает отсутствие инфекционного или воспалительного процесса, изменения в соседних сосудах – фиброзно-мышечная дисплазия (врожденного фактора риска аневризм), 
        типичный состав стенки аневризмы – тонкий слой соединительной ткани без полноценной мышечной оболочки.
        Смерть наступила вследствие геморрагического инсульта (субарахноидальное кровоизлияние (объемом 110 мл), локализующееся в области основания и ствола головного мозга с 
        распространением в базальные цистерны и борозды) из-за разрыва врождённой аневризмы правой задней соединительной артерии, осложнившегося тяжелым отеком головного мозга с 
        выраженной дислокацией ствола (вклинение в тенториальное отверстие), что подтверждается макро- и микроскопическими изменениями головного мозга (увеличение массы и объема, 
        сглаженность извилин, уплощение базальных структур, отек мозгового вещества с нарушением границы серого и белого вещества, желудочки сжаты, вклинение ствола головного 
        мозга в тенториальное отверстие, сопровождающееся его деформацией, уплощением ножек мозга и кровоизлияниями в среднем мозге и мосту, венозное полнокровие сосудов 
        основания, диапедезные кровоизлияния; микроскопически: отек нейропиля, вакуолизация цитоплазмы нейронов, гиперхромия ядер, реактивный 
        астроцитоз, эозинофилия цитоплазмы нейронов, деструкция аксонов, демиелинизация, геморрагии в области моста и ножек мозга (синдром Дюрета)), а также в других 
        органах: легкие (венозное полнокровие, отек, пятна Тардье; микроскопически: интерстициальный и альвеолярный отек, пропитывание плазмой и эритроцитами, фибрин и 
        множественные мелкие кровоизлияния в альвеолах, 
        участки эмфиземы, геморрагическая инфильтрация), сердце (застойное полнокровие правых отделов, гипоксическая дистрофия миокарда, мелкие кровоизлияния; микроскопически: отек кардиомиоцитов, очаги 
        гипоксии), печень (застойное полнокровие, мускатная печень; микроскопически: гидропическая и вакуольная дистрофия), почки (кортикальный застой; микроскопически: дистрофия 
        эпителия канальцев, очаги некроза), селезенка (увеличение размеров, венозное полнокровие, гиперплазия лимфоидной ткани).
Таким образом, между имевшимся геморрагическим инсультом вследствие разрыва врождённой аневризмы правой задней соединительной артерии и наступлением смерти имеется прямая 
причинная связь.
         Геморрагический инсульт – полиэтиологическое заболевание, включающее все формы нетравматического внутричерепного кровоизлияния. Возможными причинами развития 
геморрагического инсульта могут являться: артериальная гипертензия, аневризмы, артериовенозные мальформации, амилоидная ангиопатия, коагулопатии и прием антикоагулянтов, атеросклероз,
системные и инфекционные васкулиты, опухоли, генетические и аутоиммунные заболевания, вторичные факторы (декомпенсация сахарного диабета (повышение проницаемости сосудов), курение, 
алкоголизм (увеличивают сосудистую ломкость), хронический стресс и сильное физическое напряжение, провоцирующие гипертонические кризы).
        </p>
    )
}
export default GemInsultOsnVyvody