function MiocarditOsnVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="green">
    При жизни имелся миокардит вирусного генеза острого течения: диффузная воспалительная инфильтрация миокарда (лимфоциты, макрофаги), деструкция кардиомиоцитов, очаговый некроз, 
    интерстициальный отек, умеренно выраженный фиброз, на что указывают макро- и микро- скопические изменения сердца, экспрессиия воспалительных маркеров (CD3, CD68) при 
    иммуногистохимическом исследовании, выявление ДНК парвовируса B19 в миокарде методом ПЦР, отсутствием признаков бактериальной или иной (неинфекционной) природы миокардита 
    при бактериологическом и токсикологическом исследованиях. 
        Смерть наступила вследствие миокардит вирусного генеза острого течения, осложнившегося острой сердечной недостаточностью, что подтверждается макро- и микро- скопическими 
        изменениями как в самом сердце (зернистая и вакуольная дистрофия кардиомиоцитов, очаговые некрозы миокарда, кровоизлияния под эпикардом), так и в других органах с 
        признаками общего венозного полнокровия, жидкого состояния крови в полостях сердца и крупных сосудах, гипоксии, отека тканей и геморрагий: легкие (увеличение массы, 
        обильное серозно-геморрагическое пропитывание ткани, уплотнение паренхимы, 
        застой крови в капиллярах, заполнение альвеол розоватым транссудатом (кардиогенный отек легких), мелкие кровоизлияния под плеврой и в межальвеолярных перегородках), 
        головной мозг (отек мозга, увеличение объема, уплощение извилин и сужение борозд, мелкие точечные кровоизлияния в белом веществе и мозжечке, дистрофические изменения 
        нейронов (гиперхромия ядер, вакуолизация цитоплазмы)), печень (увеличение размеров, «мускатный» рисунок на разрезе (сочетание темных застойных и светлых дистрофически 
        измененных участков), центролобулярный застой крови, зернистая и вакуольная дистрофия гепатоцитов), почки (увеличение размеров, дряблая консистенция, застой крови в 
        корковом и мозговом веществе, острая вакуольная дистрофия эпителия канальцев, отек интерстициальной ткани, мелкие кровоизлияния), селезенка(увеличение размеров, дряблость 
        ткани, полнокровие синусов красной пульпы, микрогеморрагии в пульпе). 
Таким образом, между имевшимся миокардитом вирусного генеза острого течения и наступлением смерти имеется прямая причинная связь.
        Миокардит — это воспалительное заболевание сердечной мышцы (миокарда), характеризующееся инфильтрацией миокарда воспалительными клетками, повреждением кардиомиоцитов и, в 
        ряде случаев, развитием интерстициального фиброза. Причинами миокардита могут быть инфекции (вирусные, бактериальные, паразитарные, грибковые), аутоиммунные процессы, 
        токсическое воздействие (лекарственные препараты, алкоголь, токсины) или аллергические реакции. Клинические проявления варьируются от бессимптомного течения до тяжелой 
        сердечной недостаточности, аритмий и внезапной сердечной смерти. Диагностика включает клинические данные, инструментальные методы (ЭКГ, эхокардиография, МРТ сердца), 
        лабораторные маркеры (тропонины, СРБ) и подтверждается гистологическим или иммуногистохимическим исследованием биоптатов миокарда.
        </p>
    )
}
export default MiocarditOsnVyvody