function RsKozha(){
    return (
        <p id="MsoBodyTextIndent" className="green"> При рассеянном склерозе возможны:
1. Общие признаки:
-	Истощение (в случае длительного прогрессирующего течения): потеря массы тела, атрофия мышц, что связано с нарушением двигательной активности и длительным постельным режимом.
-	Деформация конечностей: возможны контрактуры суставов из-за длительного паралича или спастичности, деформации стоп и кистей в следствие длительной неподвижности или 
неправильного положения конечностей.
-	Кожные изменения: атрофия кожи или пролежни (особенно в области крестца, лопаток, ягодиц, пяток), возникающие из-за обездвиженности; сухость кожи из-за нарушения трофики 
тканей; гиперпигментация в местах длительного давления.
2. Особенности, связанные с неврологическими нарушениями:
-	Косоглазие или нарушение положения глазных яблок: может быть связано с параличом глазодвигательных мышц.
-	Атрофия лицевых мышц: возможна асимметрия лица из-за поражения центральной нервной системы.
3. Признаки инфекционных осложнений:
-	Воспалительные изменения кожи и мягких тканей: в случае присоединения инфекционных процессов возможны признаки вторичной пиодермии, язв, флегмон, запах гниения 
(в случае гнойных осложнений).
4. Особенности, связанные с системными осложнениями:
-	Сосудистые изменения: варикозное расширение вен нижних конечностей, тромбозы или их следы.
5. Признаки использования вспомогательных устройств:
-	Следы от фиксации: на коже могут быть заметны отпечатки от ортопедических шин, бандажей, или других устройств, использованных для поддержки двигательных функций.
        </p>
    )
}
export default RsKozha