function HibsOsnVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="green"> 
        При жизни имелась хроническая ишемическая болезнь сердца (ХИБС), постинфарктный (крупноочаговый) кардиосклероз, стенозирующий атеросклероз коронарных артерий сердца (3-я 
        степень, III стадия по Г.Г. Автандилову, стеноз до 80% ветвей левой и правой артерий), на что указывают макро- и микро- скопические изменения сердца. 
        Смерть наступила вследствие хронической ишемической болезни сердца (ХИБС) в виде постинфарктного (крупноочагового) кардиосклероза и стенозирующего атеросклероза коронарных 
        артерий сердца, сопровождавшейся хронической сердечной недостаточностью и осложнившейся полиорганной недостаточностью, что подтверждается хроническим общим венозным 
        полнокровием и признаками полиорганной недостаточности из-за длительной гипоксии и кровенаполнения (отек и водянка головного мозга, бурая индурация легких, мускатная 
        печень, цианотическая индурация почек и селезенки), двусторонним гидротораксом (по 1800 мл), гидроперикардом (200 мл), асцитом (4500 мл) и анасаркой (тяжёлой, 
        генерализованной формой отёка с отёком подкожной клетчатки по всему телу).
Таким образом, между имевшейся хронической ишемической болезни сердца (ХИБС) в виде постинфарктного (крупноочагового) кардиосклероза и стенозирующего атеросклероза коронарных 
артерий сердца и наступлением смерти имеется прямая причинная связь.
Хроническая ишемическая болезнь сердца (ХИБС), постинфарктный (крупноочаговый) кардиосклероз — это форма ишемической болезни сердца (ИБС), характеризующаяся формированием участков 
фиброзной ткани в миокарде вследствие перенесенного инфаркта миокарда. В результате развивается стойкое нарушение сократительной функции сердца, что приводит к прогрессирующей 
хронической сердечной недостаточности.
       </p>
    )
}
export default HibsOsnVyvody