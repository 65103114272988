function HolecistitLab(){
    return (
        <>
        <p id="MsoBodyTextIndent" className="green">
            Для острого холецистита при <u>гистологическом исследовании</u> характерно:
1. Желчный пузырь:
-	Слизистая оболочка: инфильтрация лейкоцитами (нейтрофилами), часто с примесью лимфоцитов и плазматических клеток; отёк и деструкция эпителия, местами — изъязвления; возможно 
образование микроскопических абсцессов в слизистой оболочке.
-	Подслизистый слой: выраженный отёк с расширением кровеносных и лимфатических сосудов; инфильтрация нейтрофилами, гистиоцитами, лимфоцитами.
-	Мышечный слой: некротические изменения мышечных волокон; лейкоцитарная инфильтрация в межмышечных пространствах.
-	Серозная оболочка: Гиперемияиперемия, отёк, иногда фибринозные наложения на поверхности; признаки периваскулярного воспаления.
2. Желчевыводящие пути:
-	Инфильтрация стенок протоков нейтрофилами и лимфоцитами.
-	Холестаз в мелких внутрипечёночных протоках.
-	Гиперплазия эпителия желчных протоков при развитии холангита.
3. Печень:
-	Застойные изменения: признаки внутрипечёночного холестаза (накопление желчи в мелких протоках и гепатоцитах (желчные тромбы)).
-	Воспаление: перипортальные зоны инфильтрированы лимфоцитами, нейтрофилами и макрофагами; очаги некроза гепатоцитов вблизи портальных трактов.
-	Сосудистые изменения: гиперемия синусоидов, расширение кровеносных сосудов.
4. Поджелудочная железа (при осложнении в виде билиарного панкреатита):
-	Воспалительная инфильтрация междольковых перегородок.
-	Очаговый некроз ткани железы.
-	Отёк и гиперемия сосудов.
5. Брюшина (при осложнении перитонитом):
-	Слои фибрина на поверхности брюшины.
-	Отёк и инфильтрация нейтрофилами и макрофагами.
Гистологическая картина острого холецистита характеризуется выраженным воспалением стенки желчного пузыря, некротическими изменениями в слизистой оболочке и мышечном слое, а 
также системными проявлениями, связанными с застойными и воспалительными процессами в печени и других органах.
</p>
<p id="MsoBodyTextIndent" className="green">
Для хронического холецистита при <u>гистологическом исследовании</u> характерно:
1. Желчный пузырь:
-	Слизистая оболочка: атрофия эпителия (эпителий слизистой оболочки может быть истончен, местами уплощён); гиперплазия эпителия (возможно образование участков 
железисто-ворсинчатой гиперплазии); крипты Рокитанского-Ашофа (расширенные, заполненные клеточным детритом и слизью железистые крипты, погруженные в подслизистую); хронический 
воспалительный инфильтрат (преобладание лимфоцитов, плазматических клеток и макрофагов в слизистой оболочке).
-	Подслизистая оболочка: разрастание соединительной ткани, что приводит к утолщению стенки (фиброз); отложение коллагена (формирование плотных фиброзных структур).
-	Мышечный слой: гипертрофия гладкомышечных клеток (утолщение мышечного слоя за счет реактивных изменений); разрастание фиброзной ткани (замещение мышечных волокон 
соединительной тканью).
-	Серозная оболочка: наличие мелких кровеносных сосудов и воспалительных клеток в прилегающих тканях (спаечные изменения).
- Прилежащие ткани: признаки хронического воспаления в жировой клетчатке (лимфоцитарный инфильтрат и отложение соединительной ткани).
2. Печень:
-	Периферический холестаз: застой желчи в мелких желчных протоках, возможна пролиферация холангиоцитов.
-	Воспалительный процесс: очаговые инфильтраты из лимфоцитов и макрофагов вокруг мелких желчных протоков и центральных вен (признаки холангита).
-	Фиброз: умеренные фиброзные изменения в зонах портальных трактов, преимущественно в правой доле печени.
3. Поджелудочная железа (при сопутствующем панкреатите):
-	Интерстициальный фиброз: разрастание соединительной ткани в строме железы.
-	Атрофия ацинарных клеток: снижение количества функциональных клеток, местами их замещение жировой тканью.
-	Перидуктальный воспалительный инфильтрат: скопление лимфоцитов вокруг мелких протоков.
4. Общие признаки:
-	Сосудистые изменения: признаки хронического венозного застоя (расширенные капилляры и мелкие вены, полнокровие).
-	Интоксикационные изменения: дистрофия в клетках других органов (сердце, почки), связанные с длительным воспалительным процессом и хронической интоксикацией.
Эти изменения соответствуют длительному воспалительному процессу, сопровождающемуся структурной перестройкой тканей и попытками регенерации.
        </p>
<p id="MsoBodyTextIndent" className="green">
Для желчнокаменной болезни (холелитиаза) при <u>гистологическом исследовании</u> характерно:
1. Желчный пузырь:
-	Слизистая оболочка: очаговая или диффузная атрофия эпителия; метаплазия эпителия (кишечная метаплазия с образованием бокаловидных клеток); хронический воспалительный 
инфильтрат, состоящий из лимфоцитов, плазматических клеток, макрофагов; иногда обнаруживаются микроскопические язвы, покрытые грануляционной тканью.
-	Подслизистый слой: фиброз и склероз с утолщением подслизистой ткани; отложение кристаллов холестерина в ткани.
-	Мышечный слой: гипертрофия мышечных волокон; в отдельных участках возможен разрыв волокон и их замещение соединительной тканью.
-	Серозная оболочка: наличие отёка и очаговой воспалительной инфильтрации.
2. Желчевыводящие пути:
-	Эпителий: пролиферация и гиперплазия эпителия внутрипечёночных и внепечёночных желчных протоков; очаговый десквамационный эпителий в просвете протоков.
-	Воспаление: перидуктальная инфильтрация лимфоцитами и макрофагами; хронический холангит с элементами фиброза стенок желчных протоков.
-	Холестаз: накопление желчи в мелких протоках и внутриклеточно в эпителии протоков.
3. Печень:
-	Холестаз: очаговое накопление желчи в гепатоцитах и в желчных капиллярах (так называемые "желчные тромбы").
-	Фиброз: перипортальный фиброз при длительном застое желчи; возможное формирование билиарного цирроза при прогрессировании.
-	Воспаление: лимфогистиоцитарная инфильтрация в портальных трактов; иногда наблюдаются небольшие очаги некроза гепатоцитов.
4. Поджелудочная железа (при осложнении билиарным панкреатитом):
-	Отёк и воспаление междольковой ткани;
-	Очаговый фиброз и некроз;
-	Периваскулярные инфильтраты из лимфоцитов и макрофагов.
5. Системные изменения (при длительном застое желчи):
-	Почки: обнаружение билирубина в канальцах (желчные цилиндры); признаки тубулоинтерстициального нефрита.
-	Селезёнка: увеличение с признаками хронического венозного застоя при портальной гипертензии.
Гистологическая картина при холелитиазе демонстрирует сочетание изменений, вызванных хроническим воспалением и застоем желчи. Основные изменения затрагивают желчный пузырь, 
желчевыводящие пути, печень и, в случае осложнений, другие органы.
        </p>
<p id="MsoBodyTextIndent" className="green">
Исследования проводимые при остром или хроническом холецистите, а также холелитиазе: <u>Микробиологическое исследование:</u> посев содержимого желчного пузыря для выявления 
патогенных микроорганизмов (кишечная палочка, стафилококки, стрептококки и др.); 
исследование на наличие специфических инфекций (например, Salmonella spp.). <u>Химическое исследование желчи и камней:</u> анализ состава желчных камней (холестериновые, 
пигментные, смешанные); исследование желчи на наличие кристаллов холестерина, билирубината кальция и других компонентов. <u>Биохимическое исследование тканей:</u> изучение 
биохимических параметров в тканях (содержание билирубина и его фракций в печени, желчевыводящих путях, почках; определение активности ферментов, характерных для застоя желчи 
(щелочная фосфатаза, гамма-глутамилтранспептидаза)).
        </p>
        </>
    )
}
export default HolecistitLab