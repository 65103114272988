function PnevmoniaInterstKozha(){
    return (
        <p id="MsoBodyTextIndent" className="green">При интерстициальной пневмонии большинство изменений, выявляемых при наружном осмотре, связаны с хронической гипоксией, 
        дыхательной недостаточностью и возможными осложнениями, такими как лёгочное сердце, истощение и венозный застой:
-	Истощение (кахексия): при хроническом течении интерстициальной пневмонии возможна значительная потеря массы тела, истончение подкожного жирового слоя; выраженный бледный или 
серовато-цианотичный оттенок кожи.
-	Цианоз (синюшный оттенок кожи, особенно выраженный в области пальцев рук и ног, губ, ушей (центральный цианоз), возможен диффузный цианоз, связанный с гипоксией).
-	Сухость кожи и слизистых оболочек (на фоне хронической гипоксии и дыхательной недостаточности кожа может выглядеть обезвоженной).
-	Утолщение дистальных фаланг пальцев рук и ног, деформация ногтевых пластин в виде выпуклости ("барабанные палочки" и "часовые стёкла" ).
-	Деформация грудной клетки (возможна «бочкообразная» форма грудной клетки на фоне хронической дыхательной недостаточности).
-	Отёки нижних конечностей (могут быть вызваны правожелудочковой сердечной недостаточностью (хроническое лёгочное сердце)).
-	Гиперемия и венозный застой (в области нижних конечностей или спины (трупные пятна могут быть интенсивного цианотического оттенка)).
-   Признаки дыхательной недостаточности (внешне может проявляться втяжением вспомогательных мышц в области шеи и межрёберных промежутков (при жизни, зафиксировано в предсмертном 
состоянии)).
-	Следы медицинских манипуляций (инъекции, пункции, возможные следы катетеров в подключичных или других венах; следы кислородной терапии (при использовании масок или канюль)).
-	Явления гиповитаминоза (ломкость ногтей, тусклость волос).
-	Интенсивные трупные пятна цианотического оттенка, особенно в нижних отделах тела (трупные признаки гипоксии).
        </p>
    )
}
export default PnevmoniaInterstKozha