function SaharDiab1OsnDiagnoz(){
    return (
        <p id="MsoBodyTextIndent" className="green">
            Основное заболевание: Сахарный диабет 1 типа, тяжёлое течение с хронической декомпенсацией углеводного обмена.
Осложнения основного заболевания:
-	диабетическая нефропатия (терминальная стадия с выраженным нефросклерозом и хронической почечной недостаточностью);
-   инсулярный панкреатит (атрофия и лимфоцитарной инфильтрация островков Лангерганса)
-	диабетическая ангиопатия (генерализованный микро- и макроангиосклероз, преимущественно сосудов нижних конечностей, коронарных и церебральных артерий);
-	диабетическая кардиомиопатия (кардиосклероз, дилатация полостей сердца, гипертрофия левого желудочка).
        </p>
    )
}
export default SaharDiab1OsnDiagnoz