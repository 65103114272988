function HoshimotoOsnVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="green"> 
        Основной вывод
        При жизни имелся аутоиммунный тиреоидит, хроническая форма (тиреоидит Хашимото): лимфоцитарная инфильтрация и очаговый фиброз 
        щитовидной железы, оксифильная метаплазия тиреоцитов (клетки Гюртле), признаки гипотиреоза (анализ крови: повышение ТТГ, снижение Т4, наличие микседемы: отечность 
        подкожной клетчатки, серозный выпот (асцит)), на что указывают макро- и микро- скопические изменения в соответствующих органах, 
        высокий уровень ТТГ (тиреотропного гормона) и низкий уровень гормона щитовидной железы (Т4) при биохимическом исследовании крови, увеличенный уровень 
        антител к тиреоглобулину (anti-Tg) и тиреоидной пероксидазе (anti-TPO) при серологическом исследовании крови. Аутоиммунный тиреоидит (тиреоидит Хашимото) — это хроническое 
        воспаление щитовидной железы, вызванное атакой иммунной системы на собственные ткани железы, что приводит к её повреждению, снижению функции (гипотиреозу) и развитию зоба 
        или фиброза.
        Указанное заболевание в причинной связи с наступлением смерти - не состоит.
        </p>
    )
}
export default HoshimotoOsnVyvody