function PnevmoniaInterstLab(){
    return (
        <p id="MsoBodyTextIndent" className="green">
            Для интерстициальной пневмонии характерно: при <u>гистологическом исследовании</u> в легких:
-	Значительное утолщение альвеолярных стенок из-за пролиферации фибробластов и накопления соединительной ткани (фиброза).
-	Инфильтрация лимфоцитами, плазматическими клетками и макрофагами, свидетельствующая о хроническом воспалении.
-	Увеличение количества капилляров (васкуляризация) или, напротив, их разрушение.
-	В острой фазе заболевания на внутренней поверхности альвеол могут обнаруживаться гиалиновые мембраны, состоящие из белков и остатков разрушенных клеток. Эти изменения 
характерны для диффузного альвеолярного повреждения, которое может сопровождать интерстициальную пневмонию.
-	Очаговая или диффузная замена нормальной ткани лёгкого фиброзной тканью.
-	Образование участков с "сотовой" структурой лёгкого, где нормальные альвеолы заменяются кистозными полостями, окружёнными фиброзными тяжами.
-	Участки спадения альвеол из-за уменьшения их эластичности и утолщения перегородок.
-	В некоторых зонах альвеолы могут быть полностью облитерированы фиброзной тканью.
-	Утолщение стенок артериол и капилляров за счёт гиперплазии интимы.
-	Склероз сосудов, что может приводить к повышению лёгочного сосудистого сопротивления.
-	Местные тромбозы или признаки хронического венозного застоя.
-	Инфильтрация интерстициальной ткани лимфоцитами, макрофагами, плазматическими клетками и, в меньшей степени, нейтрофилами.
-	Возможна гиперплазия альвеолярных макрофагов, которые скапливаются внутри альвеол.
-	В пролиферативной фазе могут наблюдаться гиперпластические изменения в эпителии альвеол и бронхиол. Это включает гиперплазию пневмоцитов II типа, которые пытаются 
компенсировать повреждение альвеолярной поверхности.
-	В острой фазе интерстициальной пневмонии отмечается отёк и накопление серозного экссудата в альвеолярных перегородках и интерстиции.
-	В участках с нарушенным кровообращением могут встречаться капиллярные тромбозы, кровоизлияния в интерстициальную ткань или альвеолы.
Эти изменения варьируются в зависимости от стадии и тяжести заболевания. На ранних этапах преобладают воспалительные и экссудативные изменения, тогда как в более поздних стадиях 
доминирует фиброзная перестройка. <u>Бактериологическое и вирусологическое исследование:</u> посев биоматериала (из лёгочной ткани, трахеобронхиального содержимого) для выявления 
возможных инфекционных возбудителей (бактерий, грибов); ПЦР-диагностика: определение вирусов (грипп, коронавирусы, аденовирусы), которые могут быть причиной интерстициальной 
пневмонии. <u>Токсикологический анализ:</u> на наличие токсических веществ, которые могли повлиять на лёгочную ткань.
        </p>
    )
}
export default PnevmoniaInterstLab