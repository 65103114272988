function GepatBOsnVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="green">
             При жизни имелся хронический вирусный гепатит B (дифузный макронодулярный цирроз печени, портальная гипертензия: варикозное расширение вен пищевода 
             и желудка, асцит), на что указывают макро- и микро- скопические изменения в этих органах, результат серологического исследования крови: HBsAg (+).
        Смерть последовала от хронического вирусного гепатита B сопровождавшегося крупноузловым циррозом печени и портальной гипертензией (варикозное расширение вен пищевода 
            и желудка), осложнившегося тромбозом воротной вены и разрывом варикозно расширенных вен пищевода с массивным кровотечением, что подтверждается наличием:
- разрыва стенки вены пищевода с эрозией и разрушением эндотелия.
- крови в желудочно-кишечном тракте (1500 мл крови в пищеводе и желудке).
- полного обтурационного тромбоза воротной вены.
- выраженных признаков массивной кровопотери, таких как анемия внутренних органов (светлоокрашенная печень, селезенка и почки, а также малое наполнение сосудов кровью) и
признаки гипоперфузии тканей (субэндокардиальные кровоизлияния в сердце - пятна Минакова, дистрофические изменения в почках, в легких и в головном мозге).
Таким образом, между имевшимся хроническим вирусным гепатитом B и наступлением смерти имеется прямая причинная связь.
        Вирусный гепатит B (ВГВ) — это инфекционное заболевание, вызываемое вирусом гепатита B (HBV), характеризующееся преимущественным поражением печени с развитием 
        воспалительно-деструктивных изменений в гепатоцитах, которые могут приводить к фиброзу, циррозу или гепатоцеллюлярной карциноме.
Заболевание передаётся через кровь и другие биологические жидкости (гемоконтактный, половой и вертикальный пути передачи). Течение может быть острым или хроническим, с различной 
степенью клинических проявлений: от бессимптомного носительства до выраженной желтухи, интоксикации и печёночной недостаточности.
        </p>
    )
}
export default GepatBOsnVyvody