function AstmaOsnVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="green">
             Основной вывод
            При жизни имелась бронхиальная астма, аллергическая форма, хроническое течение: хроническое воспаление бронхов с гиперплазией слизистых желез и инфильтрацией стенок 
            бронхов эозинофилами; участки обструкции мелких бронхов слизистыми пробками; очаговая эмфизема легких, на что указывают макро- и микро- скопические изменения в легких 
            и бронхах, повышенный уровень иммуноглобулина E (общий IgE) в крови, эозинофилия в общем анализе крови.
Бронхиальная астма — это хроническое воспалительное заболевание дыхательных путей, характеризующееся гиперреактивностью бронхов в ответ на различные раздражители, обратимой 
(самопроизвольно или под воздействием терапии) бронхиальной обструкцией и эпизодами удушья. Основу патогенеза составляет хроническое воспаление дыхательных путей, в котором 
участвуют множество клеток, включая тучные клетки, эозинофилы, Т-лимфоциты и макрофаги. Это приводит к структурным изменениям бронхов, гиперсекреции слизи, утолщению базальной 
мембраны, гипертрофии гладкомышечной ткани и инфильтрации стенок бронхов воспалительными клетками. Клинические проявления включают приступы одышки, свистящие хрипы, ощущение 
сжатия в груди и кашель, которые обычно возникают ночью или рано утром и провоцируются аллергенами, инфекциями, физической нагрузкой, стрессом или холодным воздухом. 
        Указанное заболевание в причинной связи с наступлением смерти - не состоит.
        </p>
    )
}
export default AstmaOsnVyvody