function MeningitPochki(){
    return (
        <p id="MsoBodyTextIndent" className="green"> При менингите в почках возможны признаки острой нефропатии: дистрофия эпителия канальцев, отечность коркового слоя
        (почки часто увеличены в размерах, но сохраняют свою типичную форму; контуры органа остаются ровными, без выраженных деформаций;
выраженная бледность коркового слоя из-за отека и нарушения кровообращения; бледный цвет контрастирует с более темным красноватым или синюшным оттенком пирамид мозгового слоя;
на разрезе почка выглядит влажной и блестящей, что связано с отеком; границы между корковым и мозговым слоями могут быть менее четкими из-за набухания коркового слоя;
корковый слой утолщен, его структура размыта, ткань выглядит рыхлой и легко поддается механическому повреждению (например, размягчается при проведении скальпелем), нередко 
наблюдаются мелкие, точечные или петехиальные кровоизлияния, которые образуются вследствие сосудистых нарушений;
капсула почки натянута, но снимается относительно легко; после снятия капсулы на поверхности почки могут быть видны мелкие точечные кровоизлияния, связанные с повреждением сосудов;
ткань почки на ощупь становится более мягкой, особенно в корковом слое, из-за выраженного отека и дистрофических изменений;
моча в лоханке и чашечках может быть мутной, иногда с примесью клеточного детрита или небольшого количества крови).
При длительном течении болезни в надпочечниках возможны признаки истощения коркового слоя.
        </p>                
    )
}
export default MeningitPochki