function DilCardioOsnVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="green"> 
         При жизни имелась дилатационная кардиомиопатия: выраженная дилатация полостей сердца (преимущественно левого желудочка), миокардиальная гипертрофия с истончением стенок 
         левого желудочка до 8 мм, дряблость миокарда, диффузный интерстициальный фиброз, что подтверждается макро- и микро- скопическими изменениями сердца, наряду с отсутствием 
         других причин дилатации сердца (ишемии, воспаления или токсического воздействия) и обнаружением мутаций в генах LMNA и TTN, связанных с миофибриллярными белками и 
         энергетическим метаболизмом кардиомиоцитов.
        Смерть наступила вследствие дилатационной кардиомиопатии, сопровождавшейся хронической сердечной недостаточностью и осложнившейся полиорганной недостаточностью, что 
        подтверждается хроническим общим венозным полнокровием и признаками полиорганной недостаточности из-за длительной гипоксии и кровенаполнения (отек и водянка головного 
        мозга, бурая индурация легких, мускатная печень, цианотическая индурация почек и селезенки), двусторонним гидротораксом 
        (по 1800 мл), гидроперикардом (200 мл), асцитом (4500 мл) и анасаркой (тяжёлой, генерализованной формой отёка с отёком подкожной клетчатки по всему телу).
Таким образом, между имевшейся дилатационной кардиомиопатией и наступлением смерти имеется прямая причинная связь.
        Дилатационная кардиомиопатия (ДКМП) — это заболевание миокарда, характеризующееся патологическим увеличением (дилатацией) полостей сердца, преимущественно левого желудочка, 
        с прогрессирующим снижением сократительной способности сердечной мышцы и развитием хронической сердечной недостаточности. ДКМП может быть первичной (идиопатической), 
        связанной с генетическими нарушениями, или вторичной, развивающейся на фоне инфекционных, токсических (включая алкоголь), аутоиммунных и метаболических факторов. 
        Патогенез включает дегенерацию кардиомиоцитов, интерстициальный фиброз и компенсаторную гипертрофию, что приводит к нарушению насосной функции сердца.
        </p>
    )
}
export default DilCardioOsnVyvody