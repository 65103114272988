function DifteriaOsnDiagnoz(){
    return (
        <p id="MsoBodyTextIndent" className="green">
           Дифтерия зева и дыхательных путей, токсическая форма (фибринозно-некротический ларинготрахеобронхит, реактивная гиперплазия и отек шейных лимфатических узлов, отек 
           мягких тканей шеи).
Осложнения: обтурационная асфиксия (фибринозные пленки в просвете трахеи и бронхов), токсический миокардит, токсическая дистрофия печени и почек, острая сердечно-сосудистая 
недостаточность.
            </p>
    )
}
export default DifteriaOsnDiagnoz