function PeritonitKozha(){
    return (
        <p id="MsoBodyTextIndent" className="green">При остром перитоните возможны:
1. Общие признаки интоксикации и обезвоживания:
-	Кожа бледная, иногда с желтушным или сероватым оттенком; влажная (при жизни могла быть обильная потливость).
-	Черты лица заостренные («лицо Гиппократа»): запавшие глаза, заостренный нос, цианотичные губы.
-	Губы и слизистые оболочки: сухость, трещины (признаки обезвоживания); язык сухой, может быть покрыт налетом (чаще серо-белым).
2. Признаки шока:
-	Мраморный рисунок кожи; цианоз конечностей.
-	Труп может быть в согнутой позе (при жизни больной принимал вынужденное положение, пытаясь уменьшить боль).
3. Признаки нарушения брюшной функции:
-	Вздутие живота, асимметрия брюшной стенки.
-	Следы медицинских вмешательств (инъекционные следы в области живота или конечностей (попытки обезболивания или лечения)).
4. Признаки длительной болезни:
-	Потеря массы тела (общее истощение, заостренные очертания тела).
-	Следы хронических заболеваний (возможны следы старых рубцов на животе (послеоперационные или язвенные)).
5. Признаки возможных осложнений:
-	Гнойные высыпания или свищи (если при жизни произошел разрыв абсцесса через брюшную стенку).
-	Желтушность кожи (признак печеночной недостаточности (при перитоните из-за билиарного происхождения)).
6. Признаки интоксикации и сепсиса:
-	Петехиальные кровоизлияния на коже.
-	Геморрагические элементы на слизистых оболочках.
        </p>
    )
}
export default PeritonitKozha