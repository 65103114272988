function AlcoPodj(){
    return (
        <p id="MsoBodyTextIndent" className="green">При хроническом алкоголизме в поджелудочной железе возможны следующие изменения: 
        - Острый панкреатит: поджелудочная железа увеличена в объеме из-за отека, масса может достигать 300 г (при норме 80 - 120 г). Капсула натянута и тугая.
Ткань железы становится ярко-красной или багровой из-за полнокровия сосудов, характерного для острого воспаления. На поверхности и в ткани железы видны многочисленные 
кровоизлияния, которые могут варьироваться от мелких точечных до обширных. Наблюдаются желтовато-белые пятна (участки жирового некроза - стеатонекроз) на фоне воспаленной ткани, 
возникающие в результате воздействия ферментов поджелудочной железы на окружающую жировую ткань. При серозно-геморрагическом панкреатите выявляется пропитывание кровью ткани 
железы. Возможны некротические изменения ткани железы (участки некроза выглядят как серо-коричневые, дряблые зоны, часто окруженные геморрагическим ободком). Ткань железы может 
выглядеть размягченной или разрушенной вследствие действия собственных ферментов (аутолиз).
На поверхности железы и в брюшной полости может быть серозно-геморрагический или гнойный экссудат (серозный перетонит обязательно сопровождает острый панкреатит - это его 
проявление, а гнойный перетонит - это уже его осложнение), связанный с распространением воспаления. В результате некротического процесса и воспаления могут образовываться полости, 
заполненные жидкостью и ферментами (псевдокисты), сама железа может приобрести грязно-серый, бурый или черный цвет (гнойно-некротическая форма панкреатита). Эти изменения зависят 
от тяжести острого панкреатита, который может варьироваться от легкой формы до тяжелой, с тотальным некрозом железы (панкреонекроз) и вовлечением окружающих тканей.      
      -	Хронический панкреатит (за счет рефлюкса желчи из 12-перстной кишки): уменьшение размеров железы, плотная консистенция; поверхность с рубцами, разрез серо-желтоватый с 
      очагами фиброза.
        - Панкреонекроз (поджелудочная железа мягкая и дряблая, в очагах некроза консистенция может напоминать кашицеобразную массу; часто железа увеличена в объеме (отек), но в 
        некоторых случаях может быть уменьшена за счет массивного некроза тканей. Неровные края железы из-за участков разрушения ткани. Пестрый внешний вид железы: бледно-серые 
        или желтоватые участки (жировой некроз), темно-красные или черноватые зоны (геморрагический некроз). На разрезе: чередование плотных участков воспаления, зон жирового 
        некроза и кровоизлияний, белесоватые или сероватые участки указывают на отложение кальция в местах некроза (кальцификация). Возможны признаки вовлечения окружающих тканей:
реактивный перитонит (наличие фибринозных налетов или воспалительного экссудата в брюшной полости), сращения с соседними органами. В брюшной полости может обнаруживаться мутный, 
геморрагический или серозный экссудат. При тяжелом течении — наличие абсцессов или псевдокист. Жировой некроз: желтовато-белые очаги на поверхности и внутри железы, а также на 
соседних тканях (в брюшной клетчатке, на стенках кишечника)). 
        </p>
    )
}
export default AlcoPodj