function PnevmoniaInterstSerdce(){
    return (
        <p id="MsoBodyTextIndent" className="green">При хроническом течении интерстициальной пневмонии возможны признаки хронического лёгочного сердца:   
        гипертрофия правого желудочка (cтенка правого желудочка утолщена, может достигать толщины 0,5–1 см (в норме — около 0,3–0,4 см), при выраженной гипертрофии правый 
        желудочек внешне напоминает левый); дилатация правого желудочка и правого предсердия (увеличение полости правого желудочка, растяжение миокарда (особенно при декомпенсации), стенки правого предсердия также утолщены, 
его полость увеличена); застой крови в правых отделах сердца (полости переполнены тёмной, вязкой кровью; возможны сгустки).
Также возможны изменения в крупных сосудах (расширение ствола лёгочной артерии (диаметр увеличен), утолщение её стенок, в редких случаях видны тромбы или признаки тромбоэмболии;
повышенное давление в малом круге кровообращения: стенки артерий малого круга утолщены из-за гипертрофии мышечного слоя (реакция на хроническую гипоксию и гипертензию)).
        </p>
    )
}
export default PnevmoniaInterstSerdce