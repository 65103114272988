function RsGm(){
    return (
        <p id="MsoBodyTextIndent" className="green"> При рассеянном склерозе в головном мозге можно обнаружить демиелинизированные участки (сероватые или серовато-розовые очаги, 
        которые отличаются по цвету и плотности от окружающей ткани; наиболее часто такие участки локализуются в белом веществе головного мозга, включая перивентрикулярные 
        области, мозолистое тело, зрительные пути и мозжечок). Также иногда обнаруживают: 
        - Отек и утолщение мягких мозговых оболочек. 
        - Атрофию мозговой ткани: уменьшение объема головного мозга, особенно в лобных и теменных долях, а также в мозжечке. 
        (это может быть заметно при визуальном сравнении размеров извилин и борозд); увеличение размеров желудочков мозга вследствие утраты мозговой ткани. 
        - Уменьшение объема мозжечка и заметное разглаживание складок на поверхности мозжечка (становятся менее выраженными, кажутся более сглаженными и укороченными, расстояние 
        между бороздами увеличено, борозды становятся менее глубокими).
        - Атрофию зрительных нервов (они могут выглядеть утонченными и побледневшими, что связано с перенесенными воспалительными процессами (оптический неврит)).
        - В некоторых случаях наблюдается атрофия других черепных нервов, что связано с поражением центральных нервных путей.
Изменения в спинном мозге:
-	Очаги демиелинизации: плотные, беловатые участки могут быть видны в белом веществе спинного мозга; на разрезе спинного мозга они могут иметь вид четко ограниченных пятен.
-	Атрофия спинного мозга: общая утрата объема спинного мозга, особенно в шейном отделе, что может быть связано с прогрессирующим течением заболевания.
Сопутствующие изменения:
-	Сосудистые изменения: утолщение стенок мелких сосудов в пораженных зонах, что связано с хроническими воспалительными процессами.
-	Отек головного мозга (редко): может наблюдаться в остром периоде обострения болезни.
        </p>                
    )
}
export default RsGm