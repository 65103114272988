function YazvaVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="green">
            При жизни имелась хроническая язвенная болезнь желудка и двенадцатиперстной кишки, фаза ремиссии; язва в области малой кривизны желудка (зажившая, размерами 1,5 × 1,0 
            см, рубцево-склеротические изменения); язва в луковице двенадцатиперстной кишки (зажившая, размерами 0,8 × 0,5 см, с фиброзными изменениями), на что указывают макро- и 
            микро- скопические изменения в этих органах.
            Язвенная болезнь желудка и двенадцатиперстной кишки — это хроническое рецидивирующее заболевание, характеризующееся образованием язв слизистой оболочки под 
            воздействием агрессивных факторов (кислота, пепсин, Helicobacter pylori) на фоне снижения защитных механизмов; проявляется болью в эпигастрии, изжогой, тошнотой, 
            рвотой, а осложнения включают кровотечения, перфорацию, пенетрацию и малигнизацию, чаще встречается у мужчин трудоспособного возраста.
            Указанное заболевание в причинной связи с наступлением смерти - не состоит.
        </p>
    )
}
export default YazvaVyvody