function GepatBVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="green">
            При жизни имелся хронический вирусный гепатит B, низкой активности, без признаков цирроза, на что указывают макро- и микро- скопические 
        изменения в печени, результат серологического исследования крови: HBsAg (+), Anti-HBs (-), IgG Anti-HBc (+), HBeAg (-), Anti-HBe (+).
        Вирусный гепатит B (ВГВ) — это инфекционное заболевание, вызываемое вирусом гепатита B (HBV), характеризующееся преимущественным поражением печени с развитием 
        воспалительно-деструктивных изменений в гепатоцитах, которые могут приводить к фиброзу, циррозу или гепатоцеллюлярной карциноме.
Заболевание передаётся через кровь и другие биологические жидкости (гемоконтактный, половой и вертикальный пути передачи). Течение может быть острым или хроническим, с различной 
степенью клинических проявлений: от бессимптомного носительства до выраженной желтухи, интоксикации и печёночной недостаточности.
        Указанное заболевание в причинной связи с наступлением смерти - не состоит.
        </p>
    )
}
export default GepatBVyvody