function PnevmoniaKrupVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="green">
            При жизни имелась крупозная пневмония нижней доли правого легкого, cтадия серого опеченения, фибринозный плеврит, на что указывают макро- и микро- скопические 
            изменения в легких и плевре, обнаружение в пораженной легочной ткани Streptococcus pneumoniae при бактериологическом исследовании.
            Крупозная пневмония — это острое инфекционно-воспалительное заболевание, при котором поражается одна или несколько долей легкого с образованием 
            фибринозного экссудата в альвеолах. Заболевание характеризуется стадийным течением (прилив, красное и серое опеченение, разрешение) и сопровождается высокой 
            лихорадкой, кашлем с "ржавой" мокротой, болью в грудной клетке и одышкой. Основной возбудитель — Streptococcus pneumoniae, осложнения включают дыхательную 
            недостаточность, плеврит, абсцессы и сепсис.
            Указанное заболевание в причинной связи с наступлением смерти - не состоит.
        </p>
    )
}
export default PnevmoniaKrupVyvody