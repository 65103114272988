function MeningitVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="green"> При жизни имелся серозно-гнойный менингит, что подтверждается макро- и микро- скопическими изменениями головного мозга и 
        его оболочек, изменением биохимических и цитологических показателей состава ликвора (наличие нейтрофилов, лимфоцитов и мононуклеаров; умеренное повышение белка, снижение 
        уровня глюкозы, повышение уровня лактата).
        Менингит — это воспалительное заболевание оболочек головного и/или спинного мозга, характеризующееся поражением мягких мозговых оболочек с развитием экссудативных, 
        продуктивных или геморрагических изменений. Заболевание может быть обусловлено инфекционными (бактериальными, вирусными, грибковыми, паразитарными) или неинфекционными 
        (аутоиммунными, токсическими, аллергическими) причинами. Серозно-гнойный менингит — сочетает признаки серозного и гнойного воспаления, характерен для смешанной этиологии 
        или промежуточных стадий.
        Указанное заболевание в прямой причинной связи с наступлением смерти - не состоит.
        </p>
    )
}
export default MeningitVyvody