function GepatСVyvody(){
    return (
        <p id="MsoBodyTextIndent" className="green">
            При жизни имелся хронический вирусный гепатит C, фиброз печени 2 степени, признаки умеренной жировой дистрофии, на что указывают макро- и микро- скопические 
        изменения в печени, положительный результат полимеразной цепной реакции (ПЦР) на выявление РНК вируса гепатита C в крови. 
        Вирусный гепатит C (ВГС) — это инфекционное заболевание, вызываемое вирусом гепатита C (HCV), характеризующееся преимущественным поражением печени, развитием 
        воспалительных и деструктивных изменений в гепатоцитах, а также высоким риском хронизации процесса, что может приводить к фиброзу, циррозу и гепатоцеллюлярной карциноме. 
Заболевание передаётся преимущественно парентеральным путём через кровь и её компоненты, реже половым и вертикальным путями. Для ВГС характерно длительное бессимптомное течение, 
часто выявляется на стадии хронического заболевания или его осложнений. Вирус обладает высокой изменчивостью, что затрудняет разработку вакцины, однако существуют современные 
противовирусные препараты, обеспечивающие высокую эффективность лечения. Указанное заболевание в причинной связи с наступлением смерти - не состоит.
        </p>
    )
}
export default GepatСVyvody